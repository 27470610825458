import React, { Component } from 'react'
import getDataFromApi from "./../../../DinamicForm/getDataFromApi";
import ShowInvoice from "./ShowInvoice";

export default class ShowInvoiceIdData extends Component {



    constructor(props) {
        super(props)
    
        this.state = {
            //this.props.invoiceId.addInvoice
            selectedId  : this.props.invoiceId.addInvoice ? this.props.invoiceId.addInvoice : false,
            invoiceData : false
        }
    }
    
    sendtoAdmin = (url,controller,objectToSend = {}, stateNameResponse = false) => {

        //console.log('IN');

        //let objectToSend = {hello : '1'}

        let setStateName;

        if(stateNameResponse) {
            setStateName = stateNameResponse;
        } else {
            setStateName = 'data';
        };

        let getData = getDataFromApi(url,objectToSend,controller);
        getData.then(function(response) {return response;}).then(getData =>this.setState({ [setStateName] : getData }));
    }

    componentDidMount() {

        if (this.state.selectedId) {
            this.sendtoAdmin('getInvoiceId','pays', { invoiceId : this.state.selectedId },'invoiceData');
        }

    }

    render() {

        return (

            <div>
                { this.state.invoiceData ? <div>
                    <ShowInvoice invoiceData = {this.state.invoiceData} />
                </div> : <div>
                    - טוען...  -
                </div> }
            </div>
        )

    }




}
