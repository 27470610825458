import React, { useState, useEffect } from 'react';

import { sendtoAdmin } from '../../../DinamicForm/newApi/getDataFromApi';
import loader from "../../../../img/preLoader.gif"

import ReportHome1 from './ReportHome1';
import ReportHome1SaveData from './ReportHome1SaveData';


export default function HomeVisitsPrint(props) {

    const { rowId } = props;


    const [loading, setLoading] = useState(false);
    const [responseData, setResponseData] = useState(false);

    useEffect(() => {

        const send = {
            editRowId: rowId,
            print: true
        };

        sendtoAdmin('getDataEditRow', 'HomeVisits', send, setResponseData, setLoading);

    }, [rowId]);


    return <div>

        <img src={loader} alt="loader" className={!loading ? "loader" : "loader active animate__animated animate__fadeIn"} />

        {(responseData && responseData.statusData) ? <div className='printCont'>

            <ReportHome1
                visitType={responseData.visitType}
                clientId={responseData.clientId}
                visitDate={responseData.visitDate}
                chosensocialWorker={responseData.chosensocialWorker}
                savedData={responseData.statusData} // FOR RETRIEVE DATA
                setNewVisitStep2Data={null} // ???
            />

            <div className='formCont saveData'>

                <ReportHome1SaveData

                    visitDate={responseData.visitDate}
                    responseData={responseData}
                    formSavedData={responseData.formSavedData}
                    idFormData={rowId}

                />

            </div>

        </div> : <h3>אין נתונים להדפסה</h3>}

    </div>
}
