import React, { Component } from 'react'
import { InputGroup, FormControl } from 'react-bootstrap';
import convertDate from './../../../convertDateToDisplay'

export default class HospitalDataHistory extends Component {


    render() {

        let dataHistory = this.props.history;
        //console.log(namefromId);

        return (
            <div className="row">
                <div className='PlacementDataHistory'>
                    <div className="col-md-12">
                        <InputGroup className="customInput">
                            <InputGroup.Prepend className='inputPrependLabel'>
                            <InputGroup.Text id="basic-addon3">היסטוריית אשפוז</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl  as="select" 
                                className='inputPrepend selectPrepend'
                                id="basic-url" aria-describedby="basic-addon3" >
                                    {dataHistory.map(item => { 
                                        
                                        let text = 'תקופת אשפוז: ' + item.data.period + ' - בית חולים: ' + item.data.hospital
                                                   + ' - תאריך שחרור: ' + convertDate(item.data.dateExit);
                                        
                                        let m_text = 'שחרור: ' +  convertDate(item.data.dateExit);

                                        let displayText = this.props.platform === 'mobile' ?  m_text : text;
                                        
                                        if (true) {

                                        return  <option key ={ item.data.id }>{displayText}</option> } else return false}) }
                            </FormControl>
                        </InputGroup>
                    </div>
                </div>
            </div>
        )
    }
}

/* 
<ul  key={item.data.id}>
<li><span>תאריך רישום: </span> {convertDate(item.data.inscriptionDate)}</li>
<li><span>לתאריך: </span> {convertDate(item.data.toDate)}</li>
<li><span>שם: </span> {nameFromId[0].data.name}</li>
</ul>} else return false}) } */