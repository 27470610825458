import React, { Component } from 'react'

/* https://www.npmjs.com/package/react-circular-progressbar */
// Import react-circular-progressbar module and styles
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

import ProgressProvider from "./ProgressProvider";


/* https://github.com/kevinsqi/react-circular-progressbar */

export default class QuadGraph extends Component {

    constructor(props) {

        super(props);
        this.state = {            
           percent : 0
        }

    }

    makeGraphMoves = () => {
        
        this.setState(
            {
                percent : this.props.percent
            }
        )
        //console.log(this.props.dataSummary.graph1.percent);
    }

    componentDidMount = () => {

        setTimeout(() => this.makeGraphMoves(), 300);
        
    }

    

    render() {
        return (
            <div className="QuadGraph">
                <header>
                    <h3>{this.props.title}</h3>
                </header>
                {/* <CircularProgressbar value={this.props.percent} /> */}
                
                <div className='graphCont'>
                    <ProgressProvider valueStart={0} valueEnd={this.state.percent}>
                        {value => <CircularProgressbar 
                    
                                        value={value}
                                        text={`${value}%`}
                                        strokeWidth={10} 
                                        styles={buildStyles({ 
                                            // Rotation of path and trail, in number of turns (0-1)
                                            rotation: 0,
                                            pathTransitionDuration: 0.5,
                                        })}
                            />
                        }
                    </ProgressProvider>
                    {/* <button onClick={() => setValueEnd(90)}>Change valueEnd</button> */}
                </div>

                {/* <p>{this.props.percent}</p> */}
            </div>
        )
    }
}
