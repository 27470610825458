import React, { Component } from 'react'
import TdRecommends from './TdRecommends'
export default class RecommendsWorker extends Component {


    render() {

        //this.state.dataPage.contactsSeniors} contactsDataForm={this.state.dataPage.formContacts}

        let allContacts = this.props.dataPage.recommendsWorker;
        let contactsDataForm =  this.props.dataPage.formRecommendsWorker;

        //console.log(contactsDataForm);

/*         console.log(allContacts.length);
        console.log(contactsDataForm);
        console.log(allContacts[0].data.name); */
        


        return (
            <div className="SeniorContacts WorkersContacts">
                <div className="overFlowDiv">
                    <div className="seniorsTableContact">

                        <div className="trTable titlesTr">
                            <div className="tdTable">שם ממליץ</div>
                            <div className="tdTable">טלפון ממליץ</div>
                            <div className="tdTable">הערה</div>
                            <div className="tdTable">הערה אישיות לשיבוץ</div>
                            <div className="tdTable">#</div>
                        </div>
                        <div className="trTable contentTds">
                            <div className="vSpace"></div>
                        </div>
                        {allContacts.map(itemContact => { if (true) {
                                return <React.Fragment key={itemContact.data.id}>
                                        <TdRecommends platform={this.props.platform} user={this.props.user} submit={this.props.changeArrayRecommends} itemData={itemContact.data} contactsDataForm={contactsDataForm} />
                                    </React.Fragment>
                            } else return false}) }
                        
                    </div>
                </div>
            </div>
        )
    }
}
