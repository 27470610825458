import React, { Component } from 'react'
import { InputGroup, FormControl } from 'react-bootstrap';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from  "react-datepicker";
import he from 'date-fns/locale/he';

registerLocale('he', he)


function convertDate2 (dateValue) {

    let current_datetime = new Date(dateValue.toString().replace(/-/g, '/')); // IPHONE FIX

    /* 2021-03-25 */

    let formatted_date = current_datetime.getFullYear() + '-' +
                        (current_datetime.getMonth() + 1) + "-" +
                        current_datetime.getDate();
                        
    return formatted_date;
}

export default class InputMet extends Component {


       // INPUT
    handleChange = (event) => {

        this.props.submit( {[event.target.name] : event.target.value});
        //console.log(this.state.inputValue);
    }


    //For datePicker
    handleChangeDate = (date,nameInput) => {

        let newDate = convertDate2(date);
        //console.log(newDate);

        this.props.submit( { [nameInput]:newDate} );
    }


    render() {

        if(this.props.as === 'dateInput') {

            let current_datetime = new Date(this.props.value.toString().replace(/-/g, '/')); // IPHONE FIX

            return (
                <InputGroup className={"customInput dateInput "  + this.props.className } >
                                            
                    <div className='dateTimeCont'>

                        <div className="customPrepend">{this.props.text}</div>

                        <DatePicker 
                            showYearDropdown = {true}
                            selected={current_datetime}
                            disabled={this.props.disabled ? this.props.disabled : false}
                            dateFormat='dd/MM/yy'
                            className='inputPrepend form-control'
                            name={this.props.name}
                            locale="he"
                            onChange={(date)=>this.handleChangeDate(date,this.props.name)}
                        />

                    </div>
                </InputGroup>
            );

        }

        else return (
            <InputGroup className={"customInput " + this.props.className }>

                <InputGroup.Prepend className='inputPrependLabel '>
                    <InputGroup.Text>{this.props.text}</InputGroup.Text>
                </InputGroup.Prepend>
                
                <FormControl
                    placeholder={this.props.placeholder}
                    as={this.props.as ? this.props.as : 'input'}
                    disabled={this.props.disabled ? this.props.disabled : false}
                    /* 'inputPrepend customSelect' */
                    className= {'inputPrepend customSelect' }
                    onChange={this.handleChange} 
                    value={this.props.value}
                    name={this.props.name}
                    type={this.props.inputType ? this.props.inputType : 'text' }
                />
                
                
            </InputGroup>
        )
    }
}
