import React, { Component } from 'react';
import { RestUrls } from "../Urls";
import { InputGroup, FormControl, Form } from 'react-bootstrap';

import SearchAutocompleteAndDbFocusAdmin from './SearchAutocompleteAndDbFocusAdmin'

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
/* https://www.npmjs.com/package/react-datepicker */
/* ALL PROPS */
/* https://github.com/Hacker0x01/react-datepicker/blob/master/docs/datepicker.md */

import HistoryLogs from './HistoryLogs/HistoryLogs'

import { registerLocale } from  "react-datepicker";
import he from 'date-fns/locale/he';
registerLocale('he', he)

function convertDate (dateValue) {

    let current_datetime = new Date(dateValue.toString().replace(/-/g, '/')); // IPHONE FIX

    

    /* 2021-03-25 */

    let formatted_date = current_datetime.getFullYear() + '-' +
                        (current_datetime.getMonth() + 1) + "-" +
                        current_datetime.getDate();
                        
    return formatted_date;
}


export default class InputFormAdmin extends Component {

    

    constructor(props) {

        super(props);

        let inputValue = !this.props.dataParam || this.props.dataParam[this.props.data.name] === null ? '' : this.props.dataParam[this.props.data.name];

        this.state = {
            inputValue : inputValue,
            currenId : this.props.dataParam.id ? this.props.dataParam.id : false
        };
        
        //this.handleChange = this.handleChange.bind(this);
        
        //console.log(this.props.dataParam.id);
    }

    

    async uptateInputField (inputName, inputValue) {

        let id = this.state.currenId;
        
        const API =  RestUrls.baseApiUrl + this.props.methodName +  this.props.updateGetAction;
        let user = this.props.user;

        //console.log(user);
        //console.log(id + ' | ' + inputName + ' | ' + inputValue);

        let userPass = { 
            userId : user.id,
            userName : user.username,
            userCredential : user.userCredential,
            token : user.token,
            siteCode :  RestUrls.Code,
            inputId : id,
            inputName : inputName,
            inputValue : inputValue,
            formType : this.props.data.type,
            tableUpdate : this.props.tableUpdate ? this.props.tableUpdate : ''
        };

        const res = await fetch(API, {
            method: 'POST',
            headers: {
              "Content-Type" : 'application/json',
              "Accept" : 'application/json'
            },
            body: JSON.stringify(userPass)
          });


        const dataResponse = await res.json();

        //console.log(dataResponse);

        if(dataResponse === 'unauthorized') {
            localStorage.setItem('user-info','');
            window.location.reload();
        }
        
        //this.props.updateRecords();
  
    }

    //SELECT
    editHandlerSelect = (event) => {
        

        if(this.props.dataParam) {
            if(this.props.onChange) {
                this.props.onChange();
            }
            this.setState({ inputValue: event.target.value});
            this.uptateInputField (event.target.name, event.target.value);
        } else {
            this.setState({ inputValue: event.target.value},
                function(){this.props.submit( {[event.target.name] : event.target.value});
            });
            
        }
        
    }

    // INPUT
    handleChange = (event) => {

        if(this.props.dataParam) {
            this.setState({ inputValue: event.target.value});
            this.uptateInputField (event.target.name, event.target.value);
            if(this.props.onChange) {
                this.props.onChange();
            }
        } else {
            this.setState({ inputValue: event.target.value},
                function(){this.props.submit( {[event.target.name] : this.state.inputValue});
            });
            
        }
        
        //console.log(this.state.inputValue);
    }

    //SEARCH FIELD
    handleSearchSelected = (item) => {
        //console.log(item)
        

        if(this.props.dataParam) {
            this.setState({ inputValue: item.name});
            this.uptateInputField (item.inputName, item.name);
            if(this.props.onChange) {
                this.props.onChange();
            }
        } else {
            this.setState({ inputValue: item.name},function() {
                this.props.submit( {[item.inputName] : item.name});
            });
            
        }

        
    }
  

    //For datePicker
    handleChangeDate = (date,nameInput) => {

        let newDate = convertDate(date);
        //console.log(newDate);

        if(this.props.dataParam) {
            if(this.props.onChange) {
                this.props.onChange();
            }
            this.setState({ inputValue:newDate});
            this.uptateInputField (nameInput, newDate);
        } else {
            this.setState({ inputValue:newDate},function(){
                this.props.submit( {[nameInput] : newDate});
            });
            
        }
        
        
    }

    render() {

        let dataForm = this.props.data;
        let inputValue = this.state.inputValue;
        let rowsOption4Select = this.props.rowsOption4Select;

        let width = dataForm.width.replace('md','xl');
        //let is_mobile = this.props.platform === 'mobile' ? true : false;

        // for history saves
        if(
            
            (dataForm.name === 'visa_validity' && this.props.methodName === 'foreignWorkers' && this.props.updateGetAction === '?update=1')
            || (dataForm.name === 'passport_validity' && this.props.methodName === 'foreignWorkers' && this.props.updateGetAction === '?update=1')
            || (dataForm.name === 'toDate' && this.props.methodName === 'clients' && this.props.updateGetAction === '?updateSectionTable=1')
            || (dataForm.name === 'license_toDate' && this.props.methodName === 'clients' && this.props.updateGetAction === '?update=1') 

            
        ) {

            return <HistoryLogs inputData={dataForm} inputValue= {inputValue} dataParam = { this.props.dataParam }/>

        } else {

            switch (dataForm.type) {

                case "short":
                    return (
                        <div className={ width + ' divBoostrap col-lg-12 divBoostrap col-sm-12 ' + dataForm.name }>

                            <InputGroup className="customInput">
                                {this.props.Prepend ? <InputGroup.Prepend className='inputPrependLabel'>
                                <InputGroup.Text id="basic-addon3">{dataForm.explain}</InputGroup.Text>
                                </InputGroup.Prepend> : false }
                                <FormControl
                                    className='inputPrepend'
                                    onChange={this.handleChange} 
                                    name={dataForm.name} 
                                    value={inputValue}
                                    disabled={this.props.is_disabled || dataForm.explain === 'ותק שהות' ? true : false}
                                />
                            </InputGroup>

                        </div>
                    )

                case "date":
                    
                    //let dateValue = dateValueGet.replace(' ', 'T'); // IPHONE FIX

                    let today = new Date();

                    let current_datetime = inputValue === ''  ? new Date(today.toString().replace(/-/g, '/')) : new Date(inputValue.toString().replace(/-/g, '/'));  

                
                    //console.log(datePickerShow);

                    return (
                        <div className={ width + ' divBoostrap col-lg-12 divBoostrap col-sm-12 ' + dataForm.name }>
                                <InputGroup className="customInput dateInput">
                                    
                                    <div className='dateTimeCont'>
                                        {this.props.Prepend ? <div className="customPrepend">{dataForm.explain}</div> : false }
                                        <DatePicker 
                                            showYearDropdown = {true}
                                            selected={current_datetime}
                                            dateFormat='dd/MM/yy'
                                            className='inputPrepend form-control'
                                            name={dataForm.name}
                                            locale="he"
                                            onChange={(date)=>this.handleChangeDate(date, dataForm.name)}
                                            disabled={this.props.is_disabled ? true : false}
                                        />
                                    </div>
                                </InputGroup>
                        </div> 
                    )

                case "choice":

                    let choises = dataForm.options.choices.split(",");

                    return (
                        <div className={ width + ' divBoostrap col-lg-12 divBoostrap col-sm-12 '  + dataForm.name }>

                            <InputGroup className="customInput">
                                <InputGroup.Prepend className='inputPrependLabel'>
                                <InputGroup.Text id="basic-addon3">{dataForm.explain}</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl  as="select" 
                                    className='inputPrepend selectPrepend'
                                    onChange={this.editHandlerSelect} 
                                    name={dataForm.name} 
                                    value={inputValue}
                                    disabled={this.props.is_disabled ? true : false}
                                    id="basic-url" aria-describedby="basic-addon3" >
                                        {inputValue === '' ? <option >{'בחר' }</option> : <option value={inputValue}>{inputValue }</option> }
                                        {choises.map(item => { if (inputValue !== item) {
                                                /* currentState={this.state} */
                                                return < option value ={item } key ={ item }> 
                                                            { item } 
                                                        </ option >
                                            } else return false}) }
                                    </FormControl>
                            </InputGroup>
                        </div>
                    )


                    case "table":

                    let choisesFromTable = rowsOption4Select[dataForm.name];

                    if(dataForm.name === 'ForeignWorkerName') {
                        choisesFromTable = rowsOption4Select['placementsFreeWorkers'];
                    }


                    let nameFromId = choisesFromTable.filter(function(desc) {
                        return desc.data[dataForm.options.field_value] === inputValue;
                    });

                    if(!nameFromId[0]) {
                        nameFromId = rowsOption4Select[dataForm.name].filter(function(desc) {
                            return desc.data[dataForm.options.field_value] === inputValue;
                        }); 
                    }

                    let nameDisplay = nameFromId[0] ? nameFromId[0].data[dataForm.options.field_text] :  this.state.inputValue;
                    

                    return (
                        <div className={ width + ' divBoostrap col-lg-12 divBoostrap col-sm-12 ' + dataForm.name }>

                            <InputGroup className="customInput">
                                <InputGroup.Prepend className='inputPrependLabel'>
                                <InputGroup.Text id="basic-addon3">{dataForm.explain}</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl  as="select" 
                                    className='inputPrepend selectPrepend'
                                    onChange={this.editHandlerSelect} 
                                    name={dataForm.name} 
                                    value={inputValue}
                                    disabled={this.props.is_disabled ? true : false}
                                    id="basic-url" aria-describedby="basic-addon3" >
                                        {inputValue === '' ? <option >{'בחר' }</option> : <option value={inputValue}>{ nameDisplay }</option> }
                                        {choisesFromTable.map(item => { if (inputValue !== item.data[dataForm.options.field_value]) {
                                                /* currentState={this.state} */
                                                return < option value ={item.data[dataForm.options.field_value] } key ={ item.data.id }> 
                                                            { item.data[dataForm.options.field_text] } 
                                                        </ option >
                                            } else return false}) }
                                    </FormControl>
                            </InputGroup>
                        </div>
                    )


                case "long":
                    return (
                        <div className={ width + ' divBoostrap col-lg-12 divBoostrap col-sm-12 ' + dataForm.name }>

                            <InputGroup className="customInput textArea">

                                <Form.Label>{dataForm.explain}:</Form.Label>

                                <FormControl
                                    as="textarea" rows={3}
                                    className='inputPrepend'
                                    onChange={this.handleChange} 
                                    name={dataForm.name} 
                                    value={inputValue}
                                    disabled={this.props.is_disabled ? true : false}
                                    />
                                    
                            </InputGroup>

                        </div>
                    )


                    case "search":

                    let zIndex = parseInt(dataForm.sort);


                    return (
                        <div style={{zIndex : zIndex }} className={ width + ' divBoostrap col-lg-12 divBoostrap col-sm-12 divAutocompleteCont '  + dataForm.name } >
                            <div className="divAutocomplete">
                                <InputGroup className="customInput">
                                    <InputGroup.Prepend className='inputPrependLabel'>
                                    <InputGroup.Text id="basic-addon3">{dataForm.explain}</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <div className="SearchAutocomplete">
                                        <SearchAutocompleteAndDbFocusAdmin db={dataForm.options.table} name={dataForm.name} keys={["name"]} placeholder={inputValue} submit={this.handleSearchSelected}  />
                                    </div>
                                    
                                </InputGroup>
                            </div>
                        </div>
                    )

                default:
                    return (
                        <div className={dataForm.width} >NO CASE</div>
                    )
            }
        } // for history saves
        
    }
}
