import React, { Component } from 'react'
import { InputGroup, FormControl } from 'react-bootstrap';
import ReportVisit from './ReportVisit';
import LastPayedClient from './LastPayedClient';
import ReportVisa from './ReportVisa';
import ReportLicenseClient from './ReportLicenseClient';
import MoneyTransactions from './MoneyTransactions'
import WorkersData from './WorkersData'
import ClientData from './WorkersData'
import Manot from './Manot'
import TokefVisa from './TokefVisa'
import TokefLicense from './TokefLicense'
import TokefPassport from './TokefPassport'

import {reportsNames} from './reportsNames'

import Insurance from './Insurance'
import TokefInsuranceChanges from './TokefInsuranceChanges'

//https://lodash.com/docs/4.17.15#fill
//import {find} from 'lodash' //npm i --save lodash

export default class Reports extends Component {

   
    constructor(props) {
        super(props)
    
        this.state = {
            selectedAction : '',
            categoryName : '',
        }
    }

    selectedCategory = (event) => {

        this.setState({categoryName :  event.target.value }, () => {

            //console.log('resortArray');

        })
    }

    selectedAction = (event) => {

        this.setState({selectedAction :  event.target.value })
    }

    changeScreen = (screenName = false) => {
        
        this.setState({selectedAction :  screenName })

    }

    

    render() {

        return (
            <div className="ReportsPage animate__animated animate__fadeIn">

                <h2 className="titStyle">דוחות</h2>
                
                <div className="contSelect">

                    <div className="row justify-content-center">

                        <div className="col-lg-4 text-center">
                                
                            {/* <h2 className='blueH2'>בחר</h2> */}
                            <InputGroup className="customInput" >

                                <InputGroup.Prepend className='inputPrependLabel '>
                                    <InputGroup.Text>סוג דו"ח</InputGroup.Text>
                                </InputGroup.Prepend>

                                <FormControl
                                    as='select'
                                    /* 'inputPrepend customSelect' */
                                    className='inputPrepend customSelect'
                                    onChange={this.selectedCategory} 
                                    value={this.state.categoryName}
                                    name="selectedCategory"
                                >

                                    <option value="">בחר...</option>
                                    
                                    <option value="money">כספים</option>
                                    <option value="clients">לקוחות</option>
                                    <option value="workers">עובדים</option>
                                
                                </FormControl>

                            </InputGroup>
                                
                        </div>

                    
                        <div className="col-lg-4 text-center">

                            {/* <h2 className='blueH2'>בחר</h2> */}
                            <InputGroup className="customInput" >

                                <InputGroup.Prepend className='inputPrependLabel '>
                                    <InputGroup.Text>דו"ח</InputGroup.Text>
                                </InputGroup.Prepend>

                                <FormControl
                                    as='select'
                                    /* 'inputPrepend customSelect' */
                                    className='inputPrepend customSelect'
                                    onChange={this.selectedAction} 
                                    value={this.state.selectedAction}
                                    name="selectedAction"
                                    disabled={!this.state.categoryName}
                                >

                                    <option value="">בחר...</option>

                                    {reportsNames.map(item => { if (item.category === this.state.categoryName) {
                                        /* currentState={this.state} */
                                        return < option value ={item.id} key ={ item.id }> 
                                                    { item.name } 
                                                </ option >
                                    } else return false}) }
                                
                                </FormControl>

                            </InputGroup>
                        </div>
                    </div>
                </div>
                    
                {this.getComponentBySelect(this.state.selectedAction)}
                
            </div>
                
        )
    }

    getComponentBySelect = (selection) => {

        let rakazot = this.props.pageData.rakazot;

        switch (selection) {

            case '1':
                return <ReportVisit changeScreen={(screenName) => this.changeScreen(screenName) } />

            case '2':
                return < MoneyTransactions changeScreen={(screenName) => this.changeScreen(screenName)} />

            case '3':
                return <LastPayedClient rakazot = {rakazot} changeScreen={(screenName) => this.changeScreen(screenName) } />

            case '4':
                return < WorkersData changeScreen={(screenName) => this.changeScreen(screenName)} />

            case '5':
                return < Manot changeScreen={(screenName) => this.changeScreen(screenName)} />

            case '6':
                return < ClientData changeScreen={(screenName) => this.changeScreen(screenName)} />

            case '7':
                return < Insurance changeScreen={(screenName) => this.changeScreen(screenName)} />

            case '8':
                return <ReportVisa changeScreen={(screenName) => this.changeScreen(screenName) } />
                
            case '9':
                return <ReportLicenseClient rakazot = {rakazot} changeScreen={(screenName) => this.changeScreen(screenName) } />

            case '10':
                return < TokefVisa changeScreen={(screenName) => this.changeScreen(screenName)} />
            
            case '11':
                return < TokefLicense changeScreen={(screenName) => this.changeScreen(screenName)} />
            
            case '12':
                return < TokefPassport changeScreen={(screenName) => this.changeScreen(screenName)} />

            case '13':
                return < TokefInsuranceChanges changeScreen={(screenName) => this.changeScreen(screenName)} />
            
            default:
                return false;
            
        }

    }

}
