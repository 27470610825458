import React, { Component } from 'react'
import { RestUrls } from "../../../Urls";
import InputFormAdmin from "../../../DinamicForm/InputFormAdmin";
import ReportIt from "./ReportIt"
import Contacts from "./Contacts"
import { Button, Modal, Accordion, Card } from 'react-bootstrap';
import { sendToAdmin } from "./../../../DinamicForm/sendToAdmin"
import { addSection } from "./../../../DinamicForm/addSection"
import { delleteRow } from "./../../../DinamicForm/delleteRow"

import PlacementDataHistory from "./PlacementDataHistory"
import HospitalDataHistory from "./HospitalDataHistory"

import convertDate from './../../../convertDateToDisplay'

import { animateScroll as scroll, } from 'react-scroll' /* https://www.npmjs.com/package/react-scroll */
import OpenTaskModalBody from './../HomePage/Tasks/OpenTaskModalBody'

import SearchAutocomplete4Workers from './SearchAutocomplete4Workers'

import FinanceFirstConector from './../Finance/FinanceFirstConector'

import WsignIndex from './../Wsign/WsignIndex';



export default class clientScreen extends Component {

    constructor(props) {

        super(props);
        this.state = {
            dataPage: [],
            isLoaded: false,
            savedMessage: false,
            newComment: ''
        }

    }

    async componentDidMount() {

        let userJ = JSON.parse(localStorage.getItem('user-info'));
        let user = this.props.user ? this.props.user : userJ.data;

        //console.log(user);

        localStorage.setItem('searchInner', '');
        localStorage.setItem('currentPathSearch', '');

        const API = RestUrls.baseApiUrl + 'clients/' + this.props.clientId;
        //console.log(user);

        let userPass = {
            userId: user.id,
            userName: user.username,
            userCredential: user.userCredential,
            token: user.token,
            siteCode: RestUrls.Code
        };

        const res = await fetch(API, {
            method: 'POST',
            headers: {
                "Content-Type": 'application/json',
                "Accept": 'application/json'
            },
            body: JSON.stringify(userPass)
        });


        const data = await res.json();

        //console.log(data);

        if (data === 'unauthorized') {
            localStorage.setItem('user-info', '');
            window.location.reload();
        } else {
            this.setState(
                {
                    dataPage: data,
                    isLoaded: true
                }
            )
        }

        //console.log(data);


    }


    editHandler = (selectedClientId) => {
        this.props.cancelId(selectedClientId);
    }


    insertContactClient = (url) => {
        sendToAdmin(url);
        setTimeout(() => this.componentDidMount(), 100);

    }

    insertSection = (url) => {
        //console.log(url);
        addSection(url);
        setTimeout(() => this.componentDidMount(), 200);
    }

    changeStatusSection = (url) => {
        //console.log(url);
        addSection(url);
        this.setState({ isLoaded: false, dataPage: [] });
        setTimeout(() => this.componentDidMount(), 200);
        scroll.scrollTo(800);
    }

    changeArrayContacts = () => {
        setTimeout(() => this.componentDidMount(), 500);

    }

    newTask = (value) => {
        //console.log('IN TASK');
        this.setState({ modalShow: value })
    }

    onChangeAdmin = () => {
        //console.log('changed');

        setTimeout(() => this.setState({ savedMessage: true }), 1000);
        setTimeout(() => this.setState({ savedMessage: false }), 6000);
    }

    handlerDelleteRow = (url, rowId) => {

        rowId = this.props.clientId;

        delleteRow(url, rowId);
        setTimeout(() => this.componentDidMount(), 100);

        //this.props.submit(rowId);
        //setTimeout(() => this.props.submit(), 50);
    }

    handleSearchSelected = (item) => {

        //console.log(item);
        //console.log(item.id);
        let url = 'clients?setLinkWorker=' + item.id + '&seniorId=' + this.props.clientId;
        sendToAdmin(url); this.onChangeAdmin();

    }

    addNewComment = () => {

        let url = 'clients?addNewClientComment=' + this.state.newComment + '&seniorId=' + this.props.clientId;

        sendToAdmin(url);

        setTimeout(() => this.setState({ newComment: '' }, function () { this.componentDidMount() }), 500);

    }

    allCommntsModal = (value) => {

        this.setState({ modalComments: value })

    }

    jumpUrl = (url, id) => {

        let urlJump = '/' + url + '?id=' + id;
        window.open(urlJump, "_self");

    }

    loaderOnClick = (stateName) => {

        if (!this.state[stateName]) {
            this.setState({ [stateName]: true })
        }

    }


    render() {


        let userJ = JSON.parse(localStorage.getItem('user-info'));
        let user = this.props.user ? this.props.user : userJ.data;

        //console.log(user);

        if (!this.state.isLoaded) {
            return <div>טוען...</div>
        }

        else {

            /* console.log(this.props); */

            let urlInsertContact = 'clients/' + this.props.clientId + '?insertContact=1';
            let urlInsertsection = 'clients/' + this.props.clientId;

            var ClientData = this.state.dataPage.dataParam;

            let placementData = this.state.dataPage.placementData[0];
            let placementSearch = this.state.dataPage.formPlacementrowsOption4Select.placementsHistory;

            let flagChangePlacement = (this.state.dataPage.placementData[0] && this.state.dataPage.placementData[0].data.ForeignWorkerName) ? true : false;

            //console.log(this.state.dataPage.hospitalization[0]);
            let hospitalizationData = this.state.dataPage.hospitalization[0];
            let flagChangeHospitalization = (this.state.dataPage.hospitalization[0] && this.state.dataPage.hospitalization[0].data.hospital) ? true : false;

            let workerId = (this.state.dataPage.placementData[0] && this.state.dataPage.placementData[0].data.ForeignWorkerName) ? this.state.dataPage.placementData[0].data.ForeignWorkerName : '';

            let counterComments = 0;




            //let flagChangePlacement = (this.state.dataPage.placementData[0] && this.state.dataPage.placementData[0].data.ForeignWorkerName) ? true : false;
            //console.log(placementData);

            return (
                <div className="clientScreen">
                    <header className="newTask">
                        <button style={{ marginLeft: 0 }} className='newTaskBtn' onClick={() => this.newTask(true)} >משימה חדשה</button>
                        {/* <button className='newTaskBtn' onClick ={()=>console.log('NOT SET')} >בדיקה מול משרד הפנים</button> */}
                    </header>

                    {this.state.savedMessage ? <div className="savedWindow animate__animated animate__bounceIn animate__slow">השינוים נשמרו בהצלחה</div> : false}

                    <section className="headerSec">
                        <button className='goBackCustomBtn bounce' onClick={() => this.editHandler(false)} >&laquo; חזור</button>
                        <h2 className="titStyle ">מסך לקוח: {ClientData.name} {ClientData.surname}</h2>
                        <div className="info">
                            <ul>

                                <li className='statusChange'>
                                    <strong>יומן סטטוס: </strong>
                                    {this.state.dataPage.statusLog[0] ? <select className="form-control selectDefault">

                                        {this.state.dataPage.statusLog.map(item => {

                                            let text = convertDate(item.dateChange) + ' - ' + item.name;

                                            if (true) {
                                                /* currentState={this.state} */
                                                return <option key={item.id}>{text}</option>
                                            } else return false
                                        })}
                                    </select> : <select className="form-control selectDefault">
                                        <option>ללא שינוים</option>
                                    </select>}
                                </li>

                                <li>
                                    <strong>תאריך פתיחה תיק:</strong>
                                    <span>{convertDate(ClientData.created_at)}</span>
                                </li>

                                <li className="listSpan"><strong>שם רכז/ת מטפל:</strong>
                                    {this.state.dataPage.coordinatorsData[0] ? <span>

                                        {this.state.dataPage.coordinatorsData.map(item => {
                                            if (true) {
                                                /* currentState={this.state} */
                                                return <span key={item.id}>{item.name}</span>
                                            } else return false
                                        })}
                                    </span> : <span style={{ color: 'red' }}>ללא רכז/ת</span>}
                                </li>

                                <li className="listSpan"><strong>הסכמי מסגרת:</strong>
                                    {this.state.dataPage.lastPayedClient.payed === '0' ? <span style={{ color: 'red' }}>לא בתוקף</span> : <span>בתוקף</span>}
                                    {this.state.dataPage.lastPayedClient.toDay ? <span>עד {this.state.dataPage.lastPayedClient.toDay}</span> : false}
                                </li>


                            </ul>
                        </div>
                    </section>

                    <div className="row formContainerAdmin">
                        {this.state.dataPage.form.map(item => {
                            if (true) {
                                /* currentState={this.state} */
                                return <InputFormAdmin
                                    platform={this.props.platform}
                                    onChange={this.onChangeAdmin}
                                    Prepend={true}
                                    user={user}
                                    methodName='clients'
                                    updateGetAction='?update=1'
                                    data={item}
                                    rowsOption4Select={this.state.dataPage.rowsOption4Select}
                                    dataParam={this.state.dataPage.dataParam}
                                    key={item.name} />
                            } else return false
                        })}
                    </div>



                    <div style={{ clear: 'both' }}></div>

                    <div className="accordionSec">
                        {/* defaultActiveKey="0" */}
                        <Accordion >

                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="3">
                                        <h2 className="titStyle ">הערות</h2>
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="3">
                                    <Card.Body>
                                        <section className="reportClient clear">
                                            <h2 className="titStyle ">הערות</h2>

                                            <div className="insertComment">
                                                <input className="form-control" placeholder="הערה חדשה" value={this.state.newComment} onChange={(e) => this.setState({ newComment: e.target.value })} />
                                                <Button className="addRecordBtn" onClick={this.addNewComment} disabled={this.state.newComment ? false : true} variant={this.state.newComment ? "primary" : "secondary"} >הוספת הערה</Button>
                                            </div>

                                            {this.state.dataPage.commentsLog[0] ? <div className="tableComments SeniorContacts">
                                                <div className="seniorsTableContact">
                                                    <div className="trTable titlesTr">
                                                        <div className="tdTable">תאריך</div>
                                                        <div className="tdTable">שם פותח</div>
                                                        <div className="tdTable">תוכן ההערה</div>
                                                    </div>
                                                    <div className="trTable contentTds">
                                                        <div className="vSpace"></div>
                                                    </div>

                                                    {this.state.dataPage.commentsLog.map(item => {

                                                        //console.log(this.state.dataPage.commentsLog.length)
                                                        counterComments++;

                                                        if (counterComments < 3) {
                                                            /* currentState={this.state} */
                                                            return <div key={item.id} className="trTable contentTds">
                                                                <div className="col-md-2 divBoostrap col-sm-12 name"><p>{convertDate(item.created)}</p></div>
                                                                <div className="col-md-2 divBoostrap col-sm-12 name"><p>{item.open_name}</p></div>
                                                                <div className="col-md-2 divBoostrap col-sm-12 name"><p>{item.name}</p></div>
                                                            </div>
                                                        } else return false
                                                    })}
                                                </div>

                                                {this.state.dataPage.commentsLog.length > 2 ? <Button className="allCommntsBtn" onClick={() => this.allCommntsModal(true)} variant="light" >לכל ההערות</Button> : false}

                                            </div> : false} {/* <h3 style={{fontSize : '18px'}}>אין הערות קודמות</h3> */}



                                        </section>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>

                        <Accordion >
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} onClick={() => this.loaderOnClick('loadReportHistory')} variant="link" eventKey="0">
                                        <h2 className="titStyle ">דיווחים למתש</h2>
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body>
                                        <section className="reportClient">
                                            <h2 className="titStyle ">דיווחים למתש</h2>
                                            <ReportIt loadHistory={this.state.loadReportHistory} platform={this.props.platform} user={user} taskTypes={this.state.dataPage.taskTypes} tasksStatus={this.state.dataPage.tasksStatus} clientId={this.props.clientId} form={this.state.dataPage.formReports} rowsOption4Select={this.state.dataPage.reportsRowsOption4Select} />
                                        </section>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>

                        <Accordion >
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="1">
                                        <h2 className="titStyle ">אנשי קשר</h2>
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="1">
                                    <Card.Body>
                                        <section className="contactsClient clear">
                                            <h2 className="titStyle ">אנשי קשר</h2>
                                            {this.state.dataPage.contactsSeniors.length > 0 ? <Contacts user={user} changeArrayContacts={this.changeArrayContacts} platform={this.props.platform} dataPage={this.state.dataPage} /> : ''}
                                            <Button className={this.state.dataPage.contactsSeniors.length > 0 ? "addBtn" : "addBtn addSection"} onClick={() => this.insertContactClient(urlInsertContact)} variant="secondary" size="sm">הוספת שורה <i className="fas fa-plus" /></Button>
                                        </section>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>

                        <Accordion >
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="2">
                                        <h2 className="titStyle ">ביקורי בית</h2>
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="2">
                                    <Card.Body>
                                        <section className="homeVisitsClient">
                                            <h2 className="titStyle ">ביקורי בית</h2>

                                            <Button className="addBtn addSection" onClick={() => this.jumpUrl('ביקורי_בית', this.props.clientId)} variant="secondary" size="sm">עריכה/הוספת ביקור <i className="fas fa-plus" /></Button>

                                            {this.state.dataPage.homeVisitsLog[0] ? <div className="tableComments SeniorContacts">
                                                <div className="seniorsTableContact">
                                                    <div className="trTable titlesTr">
                                                        <div className="tdTable">תאריך</div>
                                                        <div className="tdTable">סוג ביקור</div>
                                                        <div className="tdTable">שם עובדים סוציאלים</div>
                                                        <div className="tdTable">הערות</div>
                                                        <div className="tdTable">חתימה</div>
                                                    </div>
                                                    <div className="trTable contentTds">
                                                        <div className="vSpace"></div>
                                                    </div>

                                                    {this.state.dataPage.homeVisitsLog.map(item => {
                                                        if (true) {
                                                            /* currentState={this.state} */
                                                            return <div key={item.id} className="trTable contentTds">
                                                                <div className="col-md-2 divBoostrap col-sm-12 name"><p>{convertDate(item.created)}</p></div>
                                                                <div className="col-md-2 divBoostrap col-sm-12 name"><p>{item.name}</p></div>
                                                                <div className="col-md-2 divBoostrap col-sm-12 name"><p>{item.socialWorker_name}</p></div>
                                                                <div className="col-md-2 divBoostrap col-sm-12 name"><p>{item.comments}</p></div>
                                                                <div className="col-md-2 divBoostrap col-sm-12 name"><p>{item.sign ? <img src={item.sign} alt='sign' /> : null}</p></div>
                                                            </div>
                                                        } else return false
                                                    })}
                                                </div>
                                            </div> : false} {/* <h3 style={{fontSize : '18px'}}>אין הערות קודמות</h3> */}

                                        </section>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>

                        <Accordion >

                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="4">
                                        <h2 className="titStyle ">מצב רפואי</h2>
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="4">
                                    <Card.Body>
                                        <section className="medicalStatus clear">

                                            {this.state.dataPage.medicalStatus[0] ?
                                                <div className="row formContainerAdmin">
                                                    {this.state.dataPage.formMedicalStatus.map(item => {
                                                        if (true) {


                                                            return <InputFormAdmin
                                                                platform={this.props.platform}
                                                                onChange={this.onChangeAdmin}
                                                                Prepend={true}
                                                                user={user}
                                                                methodName='clients'
                                                                updateGetAction='?updateSectionTable=1'
                                                                tableUpdate='first_medicalStatus'
                                                                data={item}
                                                                rowsOption4Select={this.state.dataPage.rowsOption4Select}
                                                                dataParam={this.state.dataPage.medicalStatus[0].data}
                                                                key={item.name} />
                                                        } else return false
                                                    })}
                                                </div> : <Button className="addBtn addSection" onClick={(e) => this.insertSection(urlInsertsection + '?insertSection=first_medicalStatus')} variant="secondary" size="sm">הוספת פרטים <i className="fas fa-plus" /></Button>}
                                        </section>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>

                        <Accordion >
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="5">
                                        <h2 className="titStyle ">ביטוח רפואי</h2>
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="5">
                                    <Card.Body>
                                        <section className="medicalStatus">

                                            <h2 className="titStyle ">ביטוח רפואי</h2>

                                            {this.state.dataPage.medicalInsurance[0] ?
                                                <div className="row formContainerAdmin">
                                                    {this.state.dataPage.formMedicalInsurance.map(item => {
                                                        if (true) {


                                                            return <InputFormAdmin
                                                                platform={this.props.platform}
                                                                onChange={this.onChangeAdmin}
                                                                Prepend={true}
                                                                is_disabled={flagChangeHospitalization}
                                                                user={user}
                                                                methodName='clients'
                                                                updateGetAction='?updateSectionTable=1'
                                                                tableUpdate='first_medicalInsurance'
                                                                data={item}
                                                                rowsOption4Select={''}
                                                                dataParam={this.state.dataPage.medicalInsurance[0].data}
                                                                key={item.name} />
                                                        } else return false
                                                    })}
                                                </div> : <Button className="addBtn addSection" onClick={(e) => this.insertSection(urlInsertsection + '?insertSection=first_medicalInsurance')} variant="secondary" size="sm">הוספת פרטים <i className="fas fa-plus" /></Button>}
                                        </section>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>

                        <Accordion >

                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="6">
                                        <h2 className="titStyle ">אשפוז</h2>
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="6">
                                    <Card.Body>
                                        <section className="medicalStatus">

                                            <h2 className="titStyle ">אשפוז</h2>

                                            {(hospitalizationData && hospitalizationData.history) ? <HospitalDataHistory platform={this.props.platform} history={hospitalizationData.history} /> : false}

                                            {this.state.dataPage.hospitalization[0] ?
                                                <div className="row formContainerAdmin">
                                                    {this.state.dataPage.formHospitalization.map(item => {
                                                        if (true) {


                                                            return <InputFormAdmin
                                                                platform={this.props.platform}
                                                                onChange={this.onChangeAdmin}
                                                                Prepend={true}
                                                                is_disabled={flagChangeHospitalization}
                                                                user={user}
                                                                methodName='clients'
                                                                updateGetAction='?updateSectionTable=1'
                                                                tableUpdate='first_hospitalization'
                                                                data={item}
                                                                rowsOption4Select={this.state.dataPage.rowsOption4Select}
                                                                dataParam={this.state.dataPage.hospitalization[0].data}
                                                                key={item.name} />
                                                        } else return false
                                                    })}
                                                </div> : <Button className="addBtn addSection" onClick={(e) => this.insertSection(urlInsertsection + '?insertSection=first_hospitalization')} variant="secondary" size="sm">הוספת פרטים <i className="fas fa-plus" /></Button>}
                                            {flagChangeHospitalization ? <Button style={{ marginRight: '20px' }} className="addBtn changeBtn" onClick={(e) => this.changeStatusSection(urlInsertsection + '?changeStatus=first_hospitalization')} variant="secondary" size="sm">הוסף אשפוז <i className="fas fa-plus" /></Button> : false}

                                            {(hospitalizationData &&
                                                !hospitalizationData.history) &&
                                                this.state.dataPage.hospitalization[0] ?
                                                <Button style={{ margin: '0px 0 0 -10px' }} className="addBtn deletteBtn" onClick={() => this.handlerDelleteRow('clients?delette=hospital', '')} variant="secondary" size="sm"><i className="far fa-trash-alt" /></Button> : false}
                                        </section>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>

                        <Accordion >

                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="7">
                                        <h2 className="titStyle ">פרטי שיבוץ</h2>
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="7">
                                    <Card.Body>
                                        <section className="medicalStatus clear" name="medicalStatus">

                                            <h2 className="titStyle ">פרטי שיבוץ</h2>

                                            {(placementData && placementData.history) ? <PlacementDataHistory platform={this.props.platform} placementSearch={placementSearch} history={placementData.history} /> : false}

                                            {this.state.dataPage.placementData[0] ?
                                                <div className="row formContainerAdmin">

                                                    {this.state.dataPage.formPlacement.map(item => {

                                                        let selectedWorkerItem = this.state.dataPage.formPlacementrowsOption4Select.ForeignWorkerName[0] ? this.state.dataPage.formPlacementrowsOption4Select.ForeignWorkerName[0].data.name
                                                            + ' ' + this.state.dataPage.formPlacementrowsOption4Select.ForeignWorkerName[0].data.surname
                                                            + ' דרכון: ' + this.state.dataPage.formPlacementrowsOption4Select.ForeignWorkerName[0].data.passport : '';

                                                        if (item.name !== 'ForeignWorkerName') {

                                                            let placeholderSearch = "חיפוש עובדים לשיבוץ";

                                                            return <div className="row searchOnClients" key={item.name}>
                                                                <InputFormAdmin
                                                                    platform={this.props.platform}
                                                                    onChange={this.onChangeAdmin}
                                                                    Prepend={true}
                                                                    //is_disabled={flagChangePlacement}
                                                                    is_disabled={false}
                                                                    user={user}
                                                                    methodName='clients'
                                                                    updateGetAction='?updateSectionTable=1'
                                                                    tableUpdate='first_placement'
                                                                    data={item}
                                                                    rowsOption4Select=''
                                                                    //{this.state.dataPage.formPlacementrowsOption4Select}
                                                                    dataParam={this.state.dataPage.placementData[0].data}
                                                                    key={item.name} />

                                                                <div className="col-9">
                                                                    <div className="SearchAutocomplete" style={{ width: '100%' }} >
                                                                        <SearchAutocomplete4Workers disabled={flagChangePlacement ? true : false} keys={["name"]} placeholder={selectedWorkerItem ? selectedWorkerItem : placeholderSearch} submit={this.handleSearchSelected} DBItems='clients?searchDataWorkers=1' />
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        } else return false
                                                    })}
                                                </div> : <Button className="addBtn addSection" onClick={(e) => this.insertSection(urlInsertsection + '?insertSection=first_placement')} variant="secondary" size="sm">הוספת פרטים <i className="fas fa-plus" /></Button>}

                                            {flagChangePlacement ? <div>

                                                <Button className="addBtn changeBtn" onClick={(e) => this.changeStatusSection(urlInsertsection + '?changeStatus=first_placement')} variant="secondary" size="sm">החלף/הסר שיבוץ<i className="fas fa-exchange-alt" /></Button>

                                                <Button style={{ margin: '0 0 0 20px' }} className="addBtn changeBtn" href={'/עובדים?id=' + this.state.dataPage.placementData[0].data.ForeignWorkerName} target="_blank" rel="noopener noreferrer" variant="primary" size="sm">למסך עובד<i className="fas fa-people-arrows" /></Button>

                                            </div> : false}
                                        </section>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>

                        <Accordion >
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="8">
                                        <h2 className="titStyle ">דרישת לקוח</h2>
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="8">
                                    <Card.Body>
                                        <section className="medicalStatus">

                                            <h2 className="titStyle ">דרישת לקוח</h2>
                                            {this.state.dataPage.clientRequestsData[0] ?
                                                <div className="row formContainerAdmin">
                                                    {this.state.dataPage.formClientRequests.map(item => {
                                                        if (true) {


                                                            return <InputFormAdmin
                                                                platform={this.props.platform}
                                                                onChange={this.onChangeAdmin}
                                                                Prepend={true}
                                                                user={user}
                                                                methodName='clients'
                                                                updateGetAction='?updateSectionTable=1'
                                                                tableUpdate='first_clientRequests' // BACKEND ALLOW!!
                                                                data={item}
                                                                rowsOption4Select={this.state.dataPage.formPClientRequestsRowsOption4Select}
                                                                dataParam={this.state.dataPage.clientRequestsData[0].data}
                                                                key={item.name} />
                                                        } else return false
                                                    })}
                                                </div> : <Button className="addBtn addSection" onClick={(e) => this.insertSection(urlInsertsection + '?insertSection=first_clientRequests')} variant="secondary" size="sm">הוספת פרטים <i className="fas fa-plus" /></Button>}
                                        </section>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>

                        <Accordion >
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="9">
                                        <h2 className="titStyle ">מימון</h2>
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="9">
                                    <Card.Body>
                                        <section className="medicalStatus">

                                            <h2 className="titStyle ">מימון</h2>
                                            {this.state.dataPage.first_financingData[0] ?
                                                <div className="row formContainerAdmin">
                                                    {this.state.dataPage.formFinancing.map(item => {
                                                        if (true) {


                                                            return <InputFormAdmin
                                                                platform={this.props.platform}
                                                                onChange={this.onChangeAdmin}
                                                                Prepend={true}
                                                                user={user}
                                                                methodName='clients'
                                                                updateGetAction='?updateSectionTable=1'
                                                                tableUpdate='first_financing' // BACKEND ALLOW!!
                                                                data={item}
                                                                rowsOption4Select={this.state.dataPage.formFinancingRowsOption4Select}
                                                                dataParam={this.state.dataPage.first_financingData[0].data}
                                                                key={item.name} />
                                                        } else return false
                                                    })}
                                                </div> : <Button className="addBtn addSection" onClick={(e) => this.insertSection(urlInsertsection + '?insertSection=first_financing')} variant="secondary" size="sm">הוספת פרטים <i className="fas fa-plus" /></Button>}
                                        </section>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>

                        {/* defaultActiveKey="10" */}
                        <Accordion   >
                            <Card>
                                <Card.Header>

                                    <Accordion.Toggle as={Button} variant="link" eventKey="10">
                                        <h2 className="titStyle ">הנהלת חשבונות</h2>
                                    </Accordion.Toggle>

                                </Card.Header>
                                <Accordion.Collapse eventKey="10">

                                    <Card.Body>
                                        <FinanceFirstConector clientId={this.props.clientId} dataClient={this.state.dataPage.dataParam} />
                                    </Card.Body>

                                </Accordion.Collapse>
                            </Card>
                        </Accordion>


                        {/* defaultActiveKey="10" */}
                        <Accordion   >
                            <Card>
                                <Card.Header>

                                    <Accordion.Toggle as={Button} variant="link" eventKey="10">
                                        <h2 className="titStyle ">חתימה דיגיטלית של מסמכים</h2>
                                    </Accordion.Toggle>

                                </Card.Header>
                                <Accordion.Collapse eventKey="10">

                                    <Card.Body>
                                        <WsignIndex clientId={this.props.clientId} />
                                    </Card.Body>

                                </Accordion.Collapse>
                            </Card>
                        </Accordion>

                    </div>

                    {/* https://react-bootstrap.netlify.app/components/modal/ */}
                    <Modal
                        className='animate__animated animate__fadeInDown animate__faster newTaskModal'
                        animation={false}
                        onHide={() => this.newTask(false)}
                        /* size="sm" // בגודל קטן!!! */
                        /* size="lg" // גדול!! */
                        // backdrop="static" // חייב ללחוץ על כפתור!
                        /* centered // VERTICA CENTER */
                        show={this.state.modalShow}
                        dialogClassName="modal-90w"
                        aria-labelledby="example-custom-modal-styling-title"
                    >
                        <Modal.Header>
                            <Modal.Title id="example-custom-modal-styling-title">
                                <span>הוספת משימה חדשה</span>
                            </Modal.Title>
                            <Button onClick={() => this.newTask(false)} variant="secondary" >X</Button>
                        </Modal.Header>
                        <Modal.Body>
                            <OpenTaskModalBody currenId={this.props.clientId} workerId={workerId} optionsTaskTypes={this.state.dataPage.taskTypes} optionsStatusTasks={this.state.dataPage.tasksStatus} />
                        </Modal.Body>
                    </Modal>


                    {/* https://react-bootstrap.netlify.app/components/modal/ */}
                    <Modal
                        className='animate__animated animate__fadeInDown animate__faster modalAllComments'
                        animation={false}
                        onHide={() => this.allCommntsModal(false)}
                        /* size="sm" // בגודל קטן!!! */
                        /* size="lg" // גדול!! */
                        // backdrop="static" // חייב ללחוץ על כפתור!
                        /* centered // VERTICA CENTER */
                        show={this.state.modalComments}
                        dialogClassName="modal-90w"
                        aria-labelledby="example-custom-modal-styling-title"
                    >
                        <Modal.Header>
                            <Modal.Title id="example-custom-modal-styling-title">
                                <h2 className="titStyle">הערות לקוח</h2>
                            </Modal.Title>
                            <Button onClick={() => this.allCommntsModal(false)} variant="secondary" >X</Button>
                        </Modal.Header>
                        {this.state.dataPage.commentsLog[0] ? <Modal.Body>
                            <ul>

                                {this.state.dataPage.commentsLog.map(item => {
                                    if (true) {
                                        /* currentState={this.state} */
                                        return <li key={item.id} className="trTable contentTds">
                                            <p><strong>תאריך: </strong>{convertDate(item.created)} <strong>שם פותח: </strong>{item.open_name}</p>
                                            <p><strong>הערה: </strong>{item.name}</p>
                                        </li>
                                    } else return false
                                })}

                            </ul>
                        </Modal.Body> : 'ERROR'}
                    </Modal>

                </div>
            )
        }
    }
}
