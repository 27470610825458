import React, { Component } from 'react'
import { Button } from 'react-bootstrap';


export default class UsersDataTD extends Component {


    render() {

        let item = this.props.item;
        let nameFromId = this.props.nameFromId;


        return (
            <React.Fragment>
                <div className="trTable contentTds" >
                <div>{item.name}</div>
                <div>{item.username}</div>
                <div>{nameFromId[0] ? nameFromId[0].name_hebrew : false}</div>
                <div>{item.phone}</div>
                <div>{item.email}</div>
                <div>{item.id !== '1' ? <div>
                        {nameFromId[0].id === '4' || nameFromId[0].id === '5' ? <Button className="addBtn addSection deletteBtn" onClick={() => this.props.editCoordinatorClients(item.id)} variant="secondary" size="sm">ערוך לקוחות</Button> : false}
                        <Button className="addBtn addSection deletteBtn" onClick={() => this.props.editUser(item.id)} variant="secondary" size="sm"><i className='fas fa-user-edit' /></Button>
                        <Button className="addBtn addSection deletteBtn" onClick={() => this.props.deletteUser(item.id)} variant="secondary" size="sm"><i className='far fa-trash-alt' /></Button>
                    </div> : '' }</div>
                </div>

            </React.Fragment>
        )
    }
}
