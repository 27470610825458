import React, { useState, useEffect } from 'react';
import { FormControl, Button } from 'react-bootstrap';
import loader from "../../../../img/preLoader.gif"

import { toast } from 'react-toastify' //npm i react-toastify
import getDataFromApi from '../../../DinamicForm/newApi/getDataFromApi';
import { animateScroll as scroll } from 'react-scroll' /* https://www.npmjs.com/package/react-scroll */

import SignatureCanvas from 'react-signature-canvas';

import convertDate from './../../../convertDateToDisplay'

export default function ReportHome1SaveData(props) {

    const { responseData, formSavedData, idFormData, visitDate } = props;
    /* visitType, clientId, chosensocialWorker, visitDate, */

    //console.log(responseData);

    const fieldsForm = responseData.formRows;

    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState(defaultValueForm(fieldsForm, formSavedData));

    const [isSaved, setIsSaved] = useState(false);

    const [trimmedDataURL, setTrimmedDataURL] = useState(null);


    const submit = () => {
        //console.log(formData);
        //setStep(step + 1);

        const send = {

            idFormData: idFormData,
            formData: formData,
            sign: trimmedDataURL

        }


        sendtoApi('updateFormData', 'HomeVisits', send, setLoading, setIsSaved);
    };

    const onChange = (e) => {

        //console.log(e.target.name, e.target.value);

        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        })
    }

    useEffect(() => {

        scroll.scrollTo(0, { smooth: false, delay: 0, duration: 0, spyThrottle: 0 });

    }, []);


    const endSign = () => {

        setTrimmedDataURL(sigPad.getTrimmedCanvas().toDataURL('image/png'));
        //console.log('end');

    }

    const onBegin = () => {

        //console.log('onBegin');
    }

    let sigPad = trimmedDataURL;

    const clear = () => {
        setTrimmedDataURL(null);
        sigPad.clear(sigPad);
    }

    /* const trim = () => {

        setTrimmedDataURL(
            sigPad.getTrimmedCanvas().toDataURL('image/png')
        );
    } */

    /* useEffect(() => {

        console.log(trimmedDataURL);

    }, [trimmedDataURL]); */






    return <div className='ReportHomeSaveData'>

        <img src={loader} alt="loader" className={!loading ? "loader" : "loader active animate__animated animate__fadeIn"} />

        {/* <h1>{idFormData}</h1> */}

        <section className='sectionForm'>

            <h2>המעסיק</h2>

            {fieldsForm.map(item => {
                if (parseInt(item.sort) < 910 && parseInt(item.sort) > 790) {
                    /* currentState={this.state} */
                    return <div className='line' key={item.sort} >
                        <header>
                            <h3>{item.explain}</h3>
                            <p>{item.options.choices}</p>
                        </header>

                        <FormControl as="textarea" onChange={onChange}
                            name={item.name}
                            value={formData[item.name]}
                        />
                    </div>
                } else return false
            })}



        </section>

        <section className='sectionForm'>

            <h2>דיווח על העובד הזר</h2>

            {fieldsForm.map(item => {
                if (parseInt(item.sort) < 790 && parseInt(item.sort) > 710) {
                    /* currentState={this.state} */
                    return <div className='line' key={item.sort} >
                        <header>
                            <h3>{item.explain}</h3>
                            <p>{item.options.choices}</p>
                        </header>

                        <FormControl as="textarea" onChange={onChange}
                            name={item.name}
                            value={formData[item.name]}
                        />
                    </div>
                } else return false
            })}



        </section>

        <section className='sectionForm' style={{ marginBottom: '5px' }}>

            <h2>תנאי העסקת העו"ז <small>(על העו"ס לבדוק את תנאי העסקת העו"ז)</small></h2>

            {fieldsForm.map(item => {
                if (parseInt(item.sort) < 720 && parseInt(item.sort) > 600) {
                    /* currentState={this.state} */
                    return <div className='line' key={item.sort} >
                        <header>
                            <h3>{item.explain}</h3>
                            <p>{item.options.choices}</p>
                        </header>

                        <FormControl as="textarea" onChange={onChange}
                            name={item.name}
                            value={formData[item.name]}
                        />
                    </div>
                } else return false
            })}



        </section>

        <p className='smallText'>* יש להסביר למעסיק או לנציג מטעמו כי עליו לשלם את שכר העובד לחשבון בנק שנפתח על שמו של העובד בלבד.</p>

        <section className='sectionForm' style={{ marginTop: '30px' }}>

            <h2>תכנית טיפול <small>(על העובד הסוציאלי לשקול תכנית טיפולית תואמת לאור הנתונים אשר נצפו במהלך המפגש.)</small></h2>

            {fieldsForm.map(item => {
                if (parseInt(item.sort) < 610 && parseInt(item.sort) > 490) {
                    /* currentState={this.state} */
                    return <div className='line' key={item.sort} >
                        <header>
                            <h3>{item.explain}</h3>
                            <p>{item.options.choices}</p>
                        </header>

                        <FormControl as="textarea" onChange={onChange}
                            name={item.name}
                            value={formData[item.name]}
                        />
                    </div>
                } else return false
            })}



        </section>


        <section className='lastSec'>

            <h2>הנני לאשר כי קראתי את הדו"ח הנ”ל והנני לאשר כי הביקור התבצע בהתאם לנהלים ולסטנדרטים המקצועיים המקובלים</h2>

            <div className='contLast clear'>

                <div className='line' >
                    <header>
                        <h3 style={{ fontSize: '20px' }}>תאריך הביקור</h3>
                        <p></p>
                    </header>

                    {/*  */}

                    <FormControl
                        /* as="textarea"  */
                        disabled={true}
                        value={convertDate(visitDate)}
                    />
                </div>

                <div className='signCont'>

                    <h3>חתימה</h3>

                    {!responseData?.signData ? <>
                        <SignatureCanvas
                            ref={(ref) => { sigPad = ref }}
                            // Pre fill sign:
                            //ref={(ref) => { ref.fromDataURL(trimmedDataURL) }}
                            onEnd={endSign}
                            onBegin={onBegin}
                            backgroundColor='white'
                            penColor='black'
                            canvasProps={{ width: 320, height: 200, className: 'sigCanvas' }}
                        />
                        <div>
                            <button onClick={clear}>מחיקת חתימה</button>
                        </div>
                    </> : <img src={responseData.signData} alt='חתימה' />}

                </div>
            </div>

            {(isSaved || formSavedData) && <div className='goBackLink'>
                <a className="goBackCustomBtn bounce" href={'/ביקורי_בית?id=' + responseData.clientId} target='_self' >&laquo; חזרה לכל הביקורים</a>
            </div>}


        </section>




        {/* {!formSavedData && */}
        <Button className='bounce1 sendBtn' onClick={submit} size="" variant="success" >שמירה</Button>



    </div>;
}


function defaultValueForm(fieldsForm, formSavedData) {

    let fields = {};

    //console.log('fieldsForm', fieldsForm);
    //console.log('formSavedData', formSavedData);

    fieldsForm.map(item => (

        fields[item.name] = formSavedData[item.name] ? formSavedData[item.name] : ''

    ));


    //console.log(fields);

    return fields;

}


function sendtoApi(url, controller, objectToSend, setLoading, setIsSaved) {

    setLoading(true)
    const getData = getDataFromApi(url, objectToSend, controller, 'all')

    getData.then((getData) => {

        if (getData.ok) {

            toast.success(getData.ok);
            setIsSaved(true);

        } else if (getData.warning) {


            toast.warning(getData.warning);

        } else {
            toast.error('שגיאה');
        }

    })

    getData.catch((error) => {
        console.log(error)
    })

    getData.finally(() => {
        //scroll.scrollToBottom();
        setLoading(false)
    })
}