import React, { Component } from 'react'
import TasksHome from "./../HomePage/Tasks/TasksIndex";
import NewTaskModalBody from './NewTaskModalBody'
import { Button, Modal } from 'react-bootstrap';

export default class Tasks extends Component {

    constructor(props) {

        super(props);
        this.state = {            
            modalShow : false,
            dataPage : this.props.pageData,
        }

    }

    newTask = (value) => {
        //console.log('IN TASK');
        this.setState({ modalShow : value })
    }


    render() {


        return (
            <div className="HomePage TasksPage">
                <header className="newTask">
                    <button style={{marginLeft: 0}} className='newTaskBtn' onClick ={()=>this.newTask(true)} >משימה חדשה</button>
                </header>

                <section className="Tasks animate__animated animate__fadeInRightDown ">
                    <TasksHome media ={this.props.infoSite.media} noLimit={true} user = {this.props.infoSite.user.data} />
                </section>

                {/* https://react-bootstrap.netlify.app/components/modal/ */}
                <Modal
                    className='animate__animated animate__fadeInDown animate__faster newTaskModal'
                    animation={false}
                    onHide={() => this.newTask(false)}
                    /* size="sm" // בגודל קטן!!! */
                    /* size="lg" // גדול!! */
                    backdrop="static" // חייב ללחוץ על כפתור!
                    /* centered // VERTICA CENTER */
                    show={this.state.modalShow}
                    dialogClassName="modal-90w"
                    aria-labelledby="example-custom-modal-styling-title"
                >
                    <Modal.Header>
                        <Modal.Title id="example-custom-modal-styling-title">
                            <span>הוספת משימה חדשה</span>
                        </Modal.Title>
                        <Button onClick={() => window.open("/משימות","_self")} variant="secondary" >X</Button>
                        {/* <Button onClick={() => this.newTask(false)} variant="secondary" >X</Button> */}

                        
                    </Modal.Header>
                    <Modal.Body>
                        <NewTaskModalBody optionsTaskTypes={this.state.dataPage.taskTypes} optionsStatusTasks={this.state.dataPage.tasksStatus} />
                    </Modal.Body>
                </Modal>
            </div>
        )
    }
}
