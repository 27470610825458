import React, { Component } from 'react'
import { FormControl } from 'react-bootstrap';
import getDataFromApi from "./../../../DinamicForm/getDataFromApi";

export default class ReportChangeMana extends Component {

    constructor(props) {
        super(props)
    
        this.state = {
            
            manaCode : this.props.item.manaCode ? this.props.item.manaCode : '',

            statusCode : this.props.item.statusCode ? this.props.item.statusCode : '',

            rowId : this.props.item.id,
            disabled : this.props.disabled
        }
    }

    
    // INPUT
    handleChange = (event) => {

        this.sendtoAdmin('changeManaCode','Reports',{ rowId : this.state.rowId, manaCode : event.target.value, statusCode : '' } );

        this.setState({ [event.target.name] : event.target.value},
            function(){
                this.props.savedMessage();
                //this.props.submit( {[event.target.name] : this.state.inputValue});
        });
    
        
    }


    sendtoAdmin = (url,controller,objectToSend = {}, stateNameResponse = false) => {

        let setStateName;

        if(stateNameResponse) {
            setStateName = stateNameResponse;
        } else {
            setStateName = 'data';
        };

        let getData = getDataFromApi(url,objectToSend,controller);
        getData.then(function(response) {return response;}).then(getData =>this.setState({ [setStateName] : getData,  loading : false }, function(){

            console.log(getData);
            /* if(url === 'reportBuilder') {
                
            } */

        }));
    }

    //SELECT
    editHandlerSelect = (event) => {

        this.sendtoAdmin('changeManaCode','Reports',{ rowId : this.state.rowId, manaCode : '', statusCode : event.target.value } );


        this.setState({ [event.target.name] : event.target.value},function () {
            
            console.log(this.state);
            this.props.savedMessage();

        });
    }
    
    render() {

        //let item = this.props.item;

        return (
            <React.Fragment>

                {this.props.display === 'select' ? <span>

                    <FormControl
                        as="select" 
                        className='inputPrepend selectPrepend selectDefault'
                        onChange={this.editHandlerSelect} 
                        name='statusCode'
                        value={this.state.statusCode}
                        //disabled={this.props.is_disabled ? true : false}
                        //id="basic-url"
                        //aria-describedby="basic-addon3"
                        >

                        <option value = 'מאושר' >מאושר</option>
                        <option value='לא מאושר'>לא מאושר</option>
                        <option value='ממתין לטיפול'>ממתין לטיפול</option>
                        
                    </FormControl>
                    
                </span> : false }


                {this.props.display === 'input' ? <span>
                    <FormControl
                        className='inputPrepend changeMana'
                        onChange={this.handleChange} 
                        name='manaCode' 
                        value={this.state.manaCode}
                        placeholder='מספר מנה'
                        disabled = {this.state.disabled}
                    >
                    
                    </FormControl>
                    {this.state.disabled ? <button className="smallBtnBlack" onClick={()=>this.setState({disabled : false})}>עריכה</button> : false }
                </span> : false }

            </React.Fragment>
        )
    }
}
