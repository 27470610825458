import React, { Component } from 'react'
import { InputGroup, FormControl, ProgressBar, Alert, Button } from 'react-bootstrap';

import { FormValidator } from "../../../forms/FormValidator";
import { ValidationMessage } from "../../../forms/ValidationMessage";
import { ValidateForm } from "../../../forms/wholeFormValidation";
import { RestUrls } from "../../../Urls";

import axios from 'axios';

export default class WsignAddsocuments extends Component {


    constructor(props) {

        super(props);

        this.state = {
            show : true,
            sentForm : false, // show / hide form
            inputFile: '',
            uploadError: '',
            name : '',   //File Upload
            uploadProgress : null, //File Upload
            selectedFile : null,  //File Upload
            responseFile : false,
            signUrl : false
        };

        this.rules = {
            name: { required: true, minlength: 3 },
            inputFile: { required: true },
        }
        
    }



    //Input Text 
    updateFormValue = (event) => {
        this.setState({ [event.target.name]: event.target.value });
        //console.log(event.target.value);
    }

    //File Upload
    fileSelectedHandler = event => {
        if(event.target.files[0] && event.target.files[0].name && event.target.files[0] ) {
            this.setState({
                selectedFile : event.target.files[0],
                inputFile : event.target.files[0].name
            })
        }
        
    }
    

    //File Upload
    fileUploadHandler = () => {

        // let userJ = JSON.parse(localStorage.getItem('user-info'));
        // let user = userJ.data;

        const fd = new FormData();

        // fd.append('userId',  user.id);
        // fd.append('userCredential',  user.userCredential);
        // fd.append('token',  user.token);
        fd.append('siteCode',  RestUrls.Code);

        fd.append('name',  this.state.name);
        fd.append('filename', this.state.selectedFile, this.state.selectedFile.name);

        //console.log(fd);

        let url = RestUrls.baseApiUrl1 + 'Wsign/uploadFiles'

        axios.post( url, fd, {
            onUploadProgress: progressEvent => {
                this.setState({ uploadProgress : Math.round(progressEvent.loaded / progressEvent.total * 100 ) });
                //console.log('Upload Progress: ' + Math.round(progressEvent.loaded / progressEvent.total * 100 ) + '%')
            }
        })
            .then( res => {

                if(res.data === 'unauthorized') {
                    console.log('NO NO');
                    //localStorage.setItem('user-info','');
                    //window.location.reload();

                } else {

                    this.setState({
                        sentForm : true, //SENT,
                        uploadError : res.data.uploadError ? res.data.uploadError : '',
                        responseFile : res.data,
                        inputFile: '',
                        name : '',   //File Upload
                        uploadProgress : null, //File Upload
                        selectedFile: null,  //File Upload

                    });

                    this.showResults();
                }
            });
    }

    showResults = () => {

        //https://apinathan.bitcode.co.il/wsign/workersFlow.php?file=

        if(this.state.responseFile.insert_id) {


            let url = RestUrls.baseApiUrl1 + 'wsign/workersFlow.php?file=' + this.state.responseFile.insert_id;

            this.setState({
                signUrl : url
            });

            this.props.reload();
        }
        

        

        
    }
    


    render() {
        return (
            <div>
                <div className="AllFilesPage animate__animated animate__fadeIn">

                    <section className="FilesWrapper animate__animated animate__fadeIn">

                        { this.state.show ?
                            <div>
                            <div className="filesWorker" style={{marginTop : '40px'}}>
                                <div className="formFileSection">
                                {/* !this.state.sentForm */}
                                { !this.state.sentForm || this.state.uploadError ? <div>
                                    
                                    <h3>העלאת קובץ חדש:</h3>
                                
                                <p className="fileTypesText">קבצים מסוג: PDF עד 5 מגה.</p>

                                    <FormValidator data={ this.state } rules={ this.rules } btnText='שלחו' buttonSendAction={this.fileUploadHandler} validateForm={ ValidateForm }>
                                    
                                    <ValidationMessage field="form" />

                                    <div className="row">
                                        <div className="col-lg-5 informDiv">

                                            <input
                                                style={{display: 'none'}}
                                                type="file"
                                                onChange={this.fileSelectedHandler}
                                                ref={fileInput => this.fileInput = fileInput} //Para que no se Vea el Input y usar un boton.
                                                />
                                            <InputGroup>

                                                    <InputGroup.Prepend>
                                                        <InputGroup.Text id="basic-addon1"><i style={{fontSize : '14px'}} className="fas fa-file-signature boostrapFaIcons"/></InputGroup.Text>
                                                    </InputGroup.Prepend>
                                                    <FormControl
                                                    className='boostrapInvertcornersLeft' //Sebas
                                                    placeholder="שם"
                                                    name="name" // FOR VALIDATOR
                                                    aria-label="שם"
                                                    onChange={(e)=>this.updateFormValue(e)}
                                                    aria-describedby=""
                                                    />
                                            </InputGroup>
                                            <p className='validatorInputError'><ValidationMessage style={{margin: '0'}} field="name" /></p>
                                        </div>

                                        <div className="col-lg-2 informDiv">
                                            <input
                                                style={{display: 'none'}}
                                                type="file"
                                                name='inputFile' //For Validation
                                                onChange={this.fileSelectedHandler}
                                                ref={fileInput => this.fileInput = fileInput} //Para que no se Vea el Input y usar un boton.
                                                />
                                            <button className='btn btn-primary filePickUpBtn' style={{width : '100%', margin: '0px 0 0px 0' }} onClick={() => this.fileInput.click()} >בחר קובץ</button>
                                            <p className='validatorInputError'><ValidationMessage field="inputFile" /></p>
                                        </div>
                                    </div>
                                    </FormValidator></div> : <div>
                                                                <p className="okUpload animate__animated animate__bounce">נשלח בהצלחה.</p>
                                                            </div>}

                                    { this.state.inputFile ? this.state.inputFile : false }
                                    {this.state.uploadError ? <p className="errorUpload"> {this.state.uploadError} </p>: ''}
                                    
                                    { this.state.uploadProgress ? <ProgressBar now={this.state.uploadProgress} /> : false }

                                </div>
                            </div>
                        </div> : false }

                        {this.state.signUrl ? <div><Alert className="alert" variant="success">
                                    <a href={ this.state.signUrl } target="_blank" rel="noopener noreferrer">למיקום החתימות &raquo;</a>
                            </Alert>
                        </div> : false }

                        {this.state.sentForm ? <div style={{textAlign : 'left' }} >
                            <Button className=""  onClick={ () => this.setState({signUrl : false, sentForm : false,uploadError : '',}) } variant='success' size="" >לעלות קובץ נוסף</Button>
                        </div> : false }

                    </section>

                </div>
            </div>
        )
    }
}
