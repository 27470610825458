import React, { Component } from 'react'
import { Button, Modal } from 'react-bootstrap';
import getDataFromApi from "./../../../DinamicForm/getDataFromApi";
import loader from "../../../../img/preLoader.gif"
import convertDate from './../../../convertDateToDisplay'
import ReturnMoneyCreditCardValues from './ReturnMoneyCreditCardValues'

export default class BtnMoneyReturnCard extends Component {

    constructor(props) {
        super(props)
    
        this.state = {
            modalShow : false,
            loading: false,
            paysFromAdmin : false,
            returnMoneyTranzila : false,
            rowIdPays : false
        }
    }
    
    sendtoAdmin = (url,controller,objectToSend = {}, stateNameResponse = false) => {

        let setStateName;

        if(stateNameResponse) {
            setStateName = stateNameResponse;
        } else {
            setStateName = 'data';
        };

        let getData = getDataFromApi(url,objectToSend,controller);
        getData.then(function(response) {return response;}).then(getData =>this.setState({ [setStateName] : getData,  loading : false },function () {

            if(stateNameResponse === 'paysFromAdmin') {
                this.modalShow(true);
                //console.log(this.state.paysFromAdmin);
            }
            
        }));
    }

    modalShow = (value) => {

        this.setState({ modalShow : value })
        
        if(!value) {
            this.props.reloadInvoices();
        }
    }

    getPaysFromAdmin = (invoiceId) => {

        //console.log(invoiceId);
        

        this.setState({ loading : true })
            
        this.sendtoAdmin('getPays','Pays', 
            {
                invoiceId : invoiceId,
                clientId : this.props.item.clientId

            },'paysFromAdmin');
        
    }
    
    
    
    

    render() {

        

        return (
            <React.Fragment>
                
                <Button className='BtnMoneyReturnCard' onClick={()=>this.getPaysFromAdmin(this.props.item.id)} size="sm" variant="secondary" >זיכוי כרטיס אשראי</Button>

                {/* https://react-bootstrap.netlify.app/components/modal/ */}
                <Modal
                    className='animate__animated animate__fadeInDown animate__faster modalAllComments modalInvoices modalMoneyBack'
                    animation={false}
                    onHide={() => this.modalShow(false)}
                    /* size="sm" // בגודל קטן!!! */
                    size="lg" // גדול!! */
                    // backdrop="static" // חייב ללחוץ על כפתור!
                    /* centered // VERTICA CENTER */
                    show={this.state.modalShow}
                    dialogClassName="modal-90w"
                    aria-labelledby="example-custom-modal-styling-title"
                >
                    <Modal.Header>
                        <Modal.Title id="example-custom-modal-styling-title">
                            <h2 className="titStyle">רשימת תשלומים באשראי</h2>
                        </Modal.Title>
                        <Button onClick={() => this.modalShow(false)} variant="secondary" >X</Button>
                    </Modal.Header>

                    {this.state.returnMoneyTranzila ? <Modal.Body>

                        <h2 className='h2Ok animate__animated animate__bounce' >הזיכוי התקבל בהצלחה</h2>

                        <div style={{textAlign : 'center', marginTop : '20px'}}>
                            <Button onClick={() => this.modalShow(false)} variant="secondary" >סגירה</Button>
                        </div>
                    </Modal.Body> : false}

                    {this.state.paysFromAdmin && this.state.paysFromAdmin.pays[0] && !this.state.returnMoneyTranzila ? <Modal.Body>

                        <img src={loader} alt="loader" className={!this.state.loading ? "loader" : "loader active animate__animated animate__fadeIn" } />

                        <ul>

                        {this.state.paysFromAdmin.pays.map(item => { 
                            
                            if ( item.typePay === "כרטיס אשראי" )   {

                                let cardData = JSON.parse(item.CardDataResponse);
                                let invoiceData = this.props.item;

                                //console.log(cardData);
                                

                                return <li key = {item.id} >
                                    <span><strong>תאריך העסקה: </strong> {convertDate(invoiceData.invoiceDate)}</span> | 
                                    <span><strong>סכום בכרטיס: </strong> ₪{cardData.sum}</span> | 
                                    <span><strong>מספרים אחרונים: </strong> {cardData.ccno}</span>
                                    <div className="buttonsCont">
                                        <Button className='' onClick={()=>this.setState({ rowIdPays : item.id })} size="sm" variant="secondary" >בחירה</Button>
                                    </div> 
                                    
                                </li>

                            } else return false})
                        }

                        </ul>

                        {this.state.rowIdPays ? <div className="creditCard">
                             <ReturnMoneyCreditCardValues idPay = {this.state.rowIdPays} clientId={this.props.item.clientId} returnMoneyTranzila={()=>this.setState({ returnMoneyTranzila : true, rowIdPays : false  })} />
                        </div> : false }

                    </Modal.Body> : false }
                    
                </Modal>
            </React.Fragment>
        )
    }
}
