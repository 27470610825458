import React, { Component } from 'react'
import InputFormAdmin from "../../../DinamicForm/InputFormAdmin";
import { Accordion, Card, Button  } from 'react-bootstrap';

import WsignAddsocuments from "../Wsign/WsignAddsocuments";

import WsignShowDocs4Sign from "../Wsign/WsignShowDocs4Sign";

import UploadSignPics from "./UploadSignPics";

//import TestDragDrop from './TestDragDrop'

export default class Config extends Component {

    constructor(props) {

        super(props);
        this.state = {
            savedMessage: false,
            reload : false
        }

    }
    
    onChangeAdmin = () => {
        console.log('changed');
        
        setTimeout(() => this.setState({savedMessage: true}), 1000);
        setTimeout(() => this.setState({savedMessage:false}), 6000);
    }



    render() {

        let pageData = this.props.pageData;
        //console.log(pageData);

        return (
            <div className="configPage">
                <h2 className="titStyle ">הגדרות</h2>

                {this.state.savedMessage ? <div className="savedWindow animate__animated animate__fadeIn animate__slow">השינוים נשמרו בהצלחה</div> : false }

                <div className="accordionSec">

                    {/* defaultActiveKey="0" */}
                    <Accordion >

                        <Card>
                            <Card.Header>
                                <Accordion.Toggle as={Button} variant="link" eventKey="3">
                                <h2 className="titStyle ">פרטי חברה</h2>
                                </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="3">
                            <Card.Body>
                            

                            <div className="row formContainerAdmin">
                                {pageData.firmData.map(item => { if (true) {
                                    /* currentState={this.state} */
                                    return <InputFormAdmin
                                                platform={this.props.platform}
                                                onChange={this.onChangeAdmin}
                                                Prepend={true}
                                                user={this.props.infoSite.user.data}
                                                methodName='configPage'
                                                updateGetAction='?update=1'
                                                data={item}
                                                rowsOption4Select = {''}
                                                dataParam={pageData.form[0].data}
                                                key={item.name} />
                                } else return false}) }
                            </div>
                            
                            </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>


                    <Accordion >

                        <Card>
                            <Card.Header>
                                <Accordion.Toggle as={Button} variant="link" eventKey="3">
                                <h2 className="titStyle ">העלאת חתימות לדו"ח EXCEL</h2>
                                </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="3">
                            <Card.Body>
                                
                                <UploadSignPics />
                            
                            </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>


                    <Accordion >

                        <Card>

                            <Card.Header>
                                <Accordion.Toggle as={Button} variant="link" eventKey="3">
                                <h2 className="titStyle ">הוספת מסמכים לחתימה</h2>
                                </Accordion.Toggle>
                            </Card.Header>

                            <Accordion.Collapse eventKey="3">
                                <Card.Body>

                                    <WsignShowDocs4Sign reload={this.state.reload} reloaded={()=>this.setState({reload : false})} />

                                    <WsignAddsocuments reload={()=>this.setState({reload : true})}/>
                                
                                </Card.Body>
                            </Accordion.Collapse>

                        </Card>
                    </Accordion>







                </div>

                

            </div>
        )
    }
}
