import React, { Component } from 'react'
import { Button, Modal } from 'react-bootstrap';
import FinanceIndex from './FinanceIndex';
import getDataFromApi from "./../../../DinamicForm/getDataFromApi";
import ShowLinksInvoices from "./ShowLinksInvoices";
import { animateScroll as scroll, } from 'react-scroll' /* https://www.npmjs.com/package/react-scroll */


export default class FinanceFirstConector extends Component {

    constructor(props) {
        super(props)
    
        this.state = {
            invoicesData : false,
            screen: false,
            invoiceId : false,
            editInvoice : false,
            superTotalGet : false
        }
    }
    

    sendtoAdmin = (url,controller,objectToSend = {}, stateNameResponse = false) => {

        let setStateName;

        if(stateNameResponse) {
            setStateName = stateNameResponse;
        } else {
            setStateName = 'data';
        };

        let getData = getDataFromApi(url,objectToSend,controller);
        getData.then(function(response) {return response;}).then(getData =>this.setState({ [setStateName] : getData }));
    }

    componentDidMount() {

        this.sendtoAdmin('getInvoices','pays', { clientId : this.props.clientId },'invoicesData');

    }

    editInvoice  = (invoiceId,superTotal) => {

        this.setState({
            screen : 'invoiceBuild',
            invoiceId : { addInvoice : invoiceId },
            editInvoice : true,
            superTotalGet : superTotal,
            modalShow : false
        });

        //console.log(superTotal);

        scroll.scrollMore(500);

    }

    goBack = () => {

        this.setState({ 
            screen : false,
            invoiceId : false,
            editInvoice : false,
            superTotalGet : false
        }, function () {
            this.componentDidMount();    
        });
        
    }

    modalShow = (value) => {
        this.setState({ modalShow : value })
    }
    
    reloadInvoices = () => {
        this.componentDidMount(); 
    }
    

    render() {

        let counter = 0;

        return (
            <section className="FinanceFirstConector">
                
                {!this.state.screen ? <div>
                    
                    <div className="row justify-content-center firsButton">
                        <div className="col-lg-4 text-center">
                            <div className="col-md-12 divBoostrap col-sm-12 buttonCont">
                                <Button style={{width : '100%'}} className="" onClick={()=>this.setState({ screen : 'invoiceBuild', invoiceId : false }, function(){scroll.scrollMore(200);})} size="" variant="success" >יצירת תשלום</Button>
                            </div>
                        </div>
                    </div>

                    {this.state.invoicesData.invoices ? <div className="InvoiceList">

                        <h2 className="blueH2">רשימת חשבוניות אחרונות: </h2>

                        <ul className="invoicesList">
                            {this.state.invoicesData.invoices.map(item => { 
                                
                                if ( counter < 1 && item.pdfTranzilaFile )  {

                                    counter++;
                                    return <ShowLinksInvoices reloadInvoices = { this.reloadInvoices } key = {item.id} item={item} editInvoiceId={this.editInvoice} />

                                } else return false})
                                
                            }
                            { counter >=1 ? <li className="moreInvoices">
                                <Button onClick={() => this.modalShow(true)} variant="primary" >צפייה בכל החשבוניות &raquo;</Button>
                            </li> : false }
                        </ul>

                    </div> : false}

                </div> : false }

                {this.state.screen === 'invoiceBuild' ? <div className="screens">

                    <button className='goBackCustomBtn bounce' onClick ={ this.goBack } >&laquo; חזרה לרשימת החשבוניות</button>

                    <FinanceIndex  superTotal={this.state.superTotalGet} editInvoice={this.state.editInvoice} invoiceId={this.state.invoiceId} clientId={this.props.clientId} dataClient={this.props.dataClient} goBack={this.goBack}  />

                </div> : false }


                {/* https://react-bootstrap.netlify.app/components/modal/ */}
                <Modal
                    className='animate__animated animate__fadeInDown animate__faster modalAllComments modalInvoices'
                    animation={false}
                    onHide={() => this.modalShow(false)}
                    /* size="sm" // בגודל קטן!!! */
                    size="lg" // גדול!! */
                    // backdrop="static" // חייב ללחוץ על כפתור!
                    /* centered // VERTICA CENTER */
                    show={this.state.modalShow}
                    dialogClassName="modal-90w"
                    aria-labelledby="example-custom-modal-styling-title"
                >
                    <Modal.Header>
                        <Modal.Title id="example-custom-modal-styling-title">
                            <h2 className="titStyle">רשימת חשבוניות</h2>
                        </Modal.Title>
                        <Button onClick={() => this.modalShow(false)} variant="secondary" >X</Button>
                    </Modal.Header>
                    {this.state.invoicesData.invoices && this.state.invoicesData.invoices[0] ? <Modal.Body>
                            <ul>

                            {this.state.invoicesData.invoices.map(item => { 
                                
                                if ( item.pdfTranzilaFile )   {
                                    return <ShowLinksInvoices key = {item.id} item={item} editInvoiceId={this.editInvoice} />
                                } else return false})
                            }

                            </ul>
                    </Modal.Body> : 'ERROR' }
                </Modal>

            </section>
        )
    }
}
