import React, { Component } from 'react'
import WorkerScreen from './WorkerScreen';
import WorkerScreenNewWorker from './WorkerScreenNewWorker';
import WorkersList from './WorkersList';
import {getAllUrlParams} from "./../../../UrlParameters"
import { RestUrls } from "./../../../Urls"


export default class Workers extends Component {

    constructor(props) {

        // ID IN SEARCH BAR
        let searchJ = localStorage.getItem('searchInner') ? JSON.parse(localStorage.getItem('searchInner')) : '';

        //ID IN URL
        let urlParams = localStorage.getItem('currentPathSearch') ? localStorage.getItem('currentPathSearch') : false;
        let urlQuery = urlParams ? getAllUrlParams(urlParams) : false;

        super(props);
        this.state = {            
            selectedWorkerId : searchJ.id ? searchJ.id : urlQuery.id ? urlQuery.id : false,
            pageData : this.props.pageData,      
            newWorker : false,
            showList : true,
            listPageSate : false
        }
    }


    async refreshData () {

        let urlAction = 'foreignWorkers';

        //console.log('IN');
        let userJ = JSON.parse(localStorage.getItem('user-info'));
        let user = userJ.data;

        let item = {
        userId : user.id,
        userName : user.username,
        userCredential : user.userCredential,
        token : user.token,
        siteCode :  RestUrls.Code
        };
    
        let fletchUrl = RestUrls.baseApiUrl + urlAction;

        let result = await fetch(fletchUrl, {
            method: 'POST',
            headers: {
                "Content-Type" : 'application/json',
                "Accept" : 'application/json'
            },
            body: JSON.stringify(item)
            });
    
            let data = await result.json();
            //console.log(data);
    
            if(data === 'unauthorized') {
                localStorage.setItem('user-info','');
                window.location.reload();
            } else {
                //deleteMe(data.insertId,urlDelleteTwice);
                this.setState( {pageData: data, showList : true} );

            }
    }

    // selectedId SELECTED
    handleChange = (selectedWorkerId,currentState = false) => {
        this.setState({
            selectedWorkerId: selectedWorkerId,
            newWorker : false,
            showList : false},
            function() {this.refreshData();});

            if (currentState) {
                this.setState({
                    listPageSate : currentState});
            }

    }

    insertWorker = () => {
        this.setState({ newWorker : true});
    }

    render() {

        let pageData = this.state.pageData;
        
        return (
             <div className="WorkersPage animate__animated animate__fadeIn">
                
                {this.state.selectedWorkerId || this.props.paramWorker ? <section className="workerScreenSec">
                        <WorkerScreen platform ={this.props.infoSite.media} user = {this.props.infoSite.user.data} workerId={this.state.selectedWorkerId ? this.state.selectedWorkerId : this.props.paramWorker } pageData={pageData} cancelId={(selectedWorkerId) => this.handleChange(selectedWorkerId) } />
                    </section> :
                this.state.newWorker ? <section className="workerScreenSec">
                        <WorkerScreenNewWorker platform ={this.props.infoSite.media} user = {this.props.infoSite.user.data} workerId={this.state.selectedWorkerId ? this.state.selectedWorkerId : this.props.paramWorker } pageData={pageData} cancelId={(selectedWorkerId) => this.handleChange(selectedWorkerId) } />
                    </section> :
                this.state.showList ? <section className="workerScreenSec">
                        <WorkersList previousSearch={this.state.listPageSate} pageData={pageData} insertWorker={this.insertWorker} submit={(selectedClientId,currentState) => this.handleChange(selectedClientId,currentState) } />
                    </section> : false }

            </div>
        )
    }
}
