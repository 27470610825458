//category = clients - workers - money

export const reportsNames = [
    {
        id: 1,
        category: 'clients',
        name: 'נספח ביקורי בית'
    },
    {
        id: 2,
        category: 'money',
        name: 'פירוט עסקאות לפי תקופה'
    },
    {
        id: 3,
        category: 'clients',
        name: 'פקיעת הסכמי מסגרת לפי לקוחות'
    },
    {
        id: 4,
        category: 'workers',
        name: 'מטפלים'
    },
    {
        id: 5,
        category: 'clients',
        name: 'דו"ח מנות'
    },
    {
        id: 6,
        category: 'clients',
        name: 'מטופלים'
    },
    {
        id: 7,
        category: 'clients',
        name: 'סיומי ביטוח מטופלים'
    },
    {
        id: 8,
        category: 'workers',
        name: 'פקיעת תוקף ויזה'
    },
    {
        id: 9,
        category: 'clients',
        name: 'פקיעת תוקף היתר העסקה'
    },
    {
        id: 10,
        category: 'workers',
        name: 'שינוים בתוקף ויזה'
    },
    {
        id: 11,
        category: 'clients',
        name: 'שינוים בתוקף היתר'
    },
    {
        id: 12,
        category: 'workers',
        name: 'שינוים בתוקף דרכון'
    },
    {
        id: 13,
        category: 'clients',
        name: 'שינוים בתוקף ביטוח'
    }
]