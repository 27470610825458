import React from 'react';

import { InputGroup, FormControl } from 'react-bootstrap';

export default function InputShowData(props) {

    const { name, value } = props;
    

  return    <InputGroup className="customInput customSelect">
                <InputGroup.Prepend className='inputPrependLabel '>
                    <InputGroup.Text>{name}</InputGroup.Text>
                </InputGroup.Prepend>
                
                <FormControl
                    /* as="select" */
                    className='inputPrepend customSelect'
                    defaultValue={value}
                    disabled={true}
                    /* value={this.state.visitType} */
                >

                </FormControl>
            </InputGroup>;
}
