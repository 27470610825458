import React, { Component } from 'react'
import GetAllFiles from './GetAllFiles'

export default class FilesHandle extends Component {

    constructor(props) {

        super(props);

        //let currentState = this.props.currentState;

        this.state = {
            clientId : this.props.clientId,
            workerId : this.props.workerId,
            filesOwner : this.props.clientId ? 'client' : 'worker'
        };
        
    }

    
    render() {

        return (
            <div className="FilesWrapperAll">

                {this.props.dataFiles.allFilesWorker ?
                    <div>{this.props.dataFiles.files_categories.map(item => { if (true) {
                        return <div key={item.data.id}>
                                    <h2 className="titStyle">{item.data.name}</h2>
                                    <GetAllFiles categoryId={item.data.id} files = {this.props.dataFiles.allFilesWorker} />
                                </div> } else return false}) }</div> : <h4>ללא קבצים בארכיון</h4> }
                
            </div>
        )
    }
}
