import React, { Component } from 'react'
import getDataFromApi from "./../../../DinamicForm/getDataFromApi";

import convertDate from './../../../convertDateToDisplay'
import FiltersVisitReport from './FiltersVisitReport';
import { Button } from 'react-bootstrap';
import { ConstantsNames, RestUrls } from "./../../../Urls";

export default class ReportVisit extends Component {

    constructor(props) {
        super(props)
    
        this.state = {
            columnName : 'ביקור אחרון',
            dataResults : false,
            
            visitType : '',
            socialWorker : '',
            date1 : '',
            date2 : ''
        }

    }
    
    updateState = (state) => {
        
        this.setState(state,function() {

            //console.log(state);

        })


    }
    
    sendtoAdmin = (url,controller,objectToSend = {}, stateNameResponse = false) => {

        let setStateName;
    
        if(stateNameResponse) {
            setStateName = stateNameResponse;
        } else {
            setStateName = 'data';
        };
    
        let getData = getDataFromApi(url,objectToSend,controller);
        getData.then(function(response) {return response;}).then(getData =>this.setState({ [setStateName] : getData,  loading : false }, function(){
    
            //console.log(getData);

            if(url === 'visitsReportRows') {
                //console.log(getData.rows);   
            }
    
        }));
    }
    
    
    loadData = () => {

        this.setState({columnName : this.state.visitType}, function () {
        
            this.sendtoAdmin('visitsReportRows','ReportsExcel',this.state,'dataResults');

        });
        
    }

    /* componentDidMount() {
        this.loadData();
    } */
    
    getCsv = () => {
        

        var md5 = require('md5');
        let query = '&visitType=' + this.state.visitType;
        query = query + '&socialWorker=' + this.state.socialWorker;
        query = query + '&date1=' + this.state.date1;
        query = query + '&date2=' + this.state.date2;

        
        let link = ConstantsNames.base_url + '/ReportsExcel/excelBuilder?token=' + md5(RestUrls.Code) + query;
        //let link = ConstantsNames.base_url + '/ReportsPdf/makeCsvVisit?token=' + md5(RestUrls.Code) + query;

        window.open(link, '_blank');


    }
    

    render() {

        return (
            <section className="ReportsSection animate__animated animate__fadeIn">

                <FiltersVisitReport submit={(state)=>this.updateState(state)} />

                <div style={{textAlign : 'left', margin : '10px 0 0 0'}}>
                    <Button onClick={()=>this.loadData()} size="" variant="success" >שליחה</Button>
                </div>

                {this.state.dataResults && this.state.dataResults.rows[0] ? <div>
                    
                    <button className="excelBtn" onClick={()=>this.getCsv()}>
                        <i className="fas fa-file-excel"></i>
                    </button>
                    
                    <div className="tableResults">
                    
                    <div className="overFlow">
                        <table>
                            <thead>
                                <tr>
                                    <th>תעודת זהות</th>
                                    <th>שם  משפחה</th>
                                    <th>שם  פרטי</th>
                                    <th>יישוב</th>
                                    <th>רחוב</th>
                                    <th>טלפון</th>
                                    <th>שם משפחה</th>
                                    <th>שם פרטי</th>
                                    <th>מספר דרכון</th>
                                    <th>נייד</th>
                                    <th>ארץ מוצא</th>
                                    <th>תאריך ביקור</th>
                                    <th>סטטוס ביקור{/* {this.state.columnName ? this.state.columnName : 'ביקור אחרון' } */}</th>
                                    <th>סוג השמה</th>
                                    <th>תאריך השמה</th>
                                    

                                    <th>מבצע הביקור</th>
                                    <th>מבצע הביקור</th>


                                    {/* <th>כתובת מקום לינת העובד</th> */}
                                    
                                    
                                    {/* <th>שם איש קשר וטלפון נייד</th>
                                    <th>תוקף היתר העסקה</th>
                                    <th>תוקף אשרת עבודה</th>
                                    <th>שם חברת הסיעוד</th>
                                    <th>הערות</th> */}
                                </tr>
                            </thead>
                            
                            <tbody>
                                {this.state.dataResults.rows.map(item => { 
                                    
                                    if (item.worker.passport //CHECK WORKER EXIST
                                        
                                        && item.visits.defaultVisit //CHECK VISIT EXIST
                                        
                                       ) { 

                                    //console.log(item);

                                    let client = item.client;
                                    let worker = item.worker;
                                    let visits = item.visits.defaultVisit;

                                /* currentState={this.state} */
                            return  <tr key={client.id}>
                                        <td>{client.tz}</td>
                                        <td>{client.name}</td>
                                        <td>{client.surname}</td>
                                        <td>{client.city}</td>
                                        <td>{client.address}</td>
                                        <td>{client.phone}</td>
                                        <td>{worker.surname}</td>
                                        <td>{worker.name}</td>
                                        <td>{worker.passport}</td>
                                        <td>{worker.phone1}</td>
                                        <td>{worker.passport_from}</td>

                                        <td>{convertDate(visits.created_at)}</td>
                                        <td>{visits.visitType}</td>
                                        <td>{client.place_classification}</td>

                                        <td>{convertDate(worker.inscriptionDate)}</td>

                                        <td>{visits.socialData.name}</td>
                                        
                                        <td>עובד/ת סוציאלי/ת</td>

                                        {/*
                                            <td>{worker.address}</td>
                                            <td>{client.contactPerson}</td>
                                            <td>{convertDate(client.license_toDate)}</td>
                                            <td>{convertDate(worker.visa_validity)}</td>
                                            <td>{client.financingFirm}</td>
                                            <td>{client.comments}</td>
                                        */}

                                </tr> } else return false}) }
                            </tbody>

                        </table>
                    </div>

                </div></div> : <div>
                        {this.state.dataResults ? <h2 style={{color : 'red', fontSize : '26px', textAlign : 'center' }}>אין נתונים לפי הסינון</h2> : false }
                    </div>}


            </section>

        )
    }


}
