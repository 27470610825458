import React, { Component } from 'react'
import { ConstantsNames } from "../../../Urls";

export default class GetAllFiles extends Component {
    render() {
        return (
            <div className='filesWorker noReka'>
                {this.props.files ? <div><ul className='showData' style={{margin: '0 0 40px 0'}}>
                    {this.props.files.map(item => { if (this.props.categoryId === item.data.category ) {
                        return <li key={item.data.id} >
                            <a className="fileA" href={ConstantsNames.files + item.data.fileName} rel="noreferrer" target='_blank' >
                                <i className="far fa-file"></i>
                                <span>{item.data.documentName}</span>
                            </a>
                        </li>
                    } else return false}) }
                </ul></div> : false }
            </div>
        )
    }
}
