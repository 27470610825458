import React, { Component } from 'react'
import { Button, InputGroup, FormControl, Accordion, Card, Modal } from 'react-bootstrap';
import { RestUrls } from "./../../../Urls"
import addClientPic from "./../../../../img/icons/addUser.svg";
import convertDate from './../../../convertDateToDisplay'

import addSmsPic from "./../../../../img/icons/addSms.svg";
import SendSMSModalBody from "./../Clients/SendSMSModalBody";


export default class WorkersList extends Component {

    constructor(props) {

        super(props);

        let previousSearch = this.props.previousSearch ? this.props.previousSearch : false;

        this.state = {            
            workersList : [],
            hiddenWorkers : false,
            searchData : previousSearch ? previousSearch.searchData : '',
            statusClientSearch : previousSearch ? previousSearch.statusClientSearch : '',
            countryAreasSearch :  previousSearch ? previousSearch.countryAreasSearch : '',
            licenseSearch : previousSearch ? previousSearch.licenseSearch : '',
            rowsSelected : previousSearch ? previousSearch.rowsSelected : [],
            selectAllRows : false
        }

    }

    async OffWorkerDb(id,urlDelleteTwice) {

        let idDelette = id;
    
        //console.log('IN2');
    
        let userJ = JSON.parse(localStorage.getItem('user-info'));
        let user = userJ.data;
    
        let item = {
          userId : user.id,
          userName : user.username,
          userCredential : user.userCredential,
          token : user.token,
          siteCode :  RestUrls.Code,
          idDelette : idDelette
        };
      
        let fletchUrl = RestUrls.baseApiUrl + urlDelleteTwice;
    
        //console.log(fletchUrl);
    
        let result = await fetch(fletchUrl, {
              method: 'POST',
              headers: {
                "Content-Type" : 'application/json',
                "Accept" : 'application/json'
              },
              body: JSON.stringify(item)
            });
      
            let data = await result.json();
            //console.log(data);
      
            if(data === 'unauthorized') {
                localStorage.setItem('user-info','');
                window.location.reload();
            } else {
    
                //console.log('Delette: ' + idDelette);      
                this.loadData(this.state.hiddenWorkers);
            }
    
        
      }


    

    editHandler = (selectedClientId) => {
            window.history.pushState('עריכת עובד','פרטי עובדים','?id=' + selectedClientId);
            this.props.submit(selectedClientId,this.state);
    }

    reload = () => {
        window.location.reload();
    }


    OffWorker = (idWorker,btnAction) => {

        let action = !btnAction ? '&restoreWorker=1' : '';
        let url = 'foreignWorkers?offWorkers=1' + action;
        this.OffWorkerDb(idWorker,url);
        
    }

    componentDidMount() {
        if(this.props.previousSearch) {
            if(this.props.previousSearch.searchData) {
                this.loadData(this.props.previousSearch.searchData);
            }
            else {
                this.loadData();
            }
        }
    }

    async loadData (qsearch = '') {

        let url = 'foreignWorkers?search=1&q=' +  qsearch;

        if (this.state.statusClientSearch !== '') {
            url  = url + '&statusClientSearch=' + this.state.statusClientSearch;
        }

        if (this.state.countryAreasSearch !== '')  {
            url  = url + '&countryAreasSearch=' + this.state.countryAreasSearch;
        }

        if (this.state.licenseSearch !== '' ) {
            
            url  = url + '&licenseSearch=' + this.state.licenseSearch;

        }

        //console.log(url);     

        const API =  RestUrls.baseApiUrl + url;

        let userJ = JSON.parse(localStorage.getItem('user-info'));
        let user = userJ.data;
        
        //console.log(user);

        let userPass = { 
                userId : user.id,
                userName : user.username,
                userCredential : user.userCredential,
                token : user.token,
                siteCode :  RestUrls.Code
            };

        const res = await fetch(API, {
            method: 'POST',
            headers: {
              "Content-Type" : 'application/json',
              "Accept" : 'application/json'
            },
            body: JSON.stringify(userPass)
          });


        const data = await res.json();

        if(data === 'unauthorized') {
            localStorage.setItem('user-info','');
            window.location.reload();
        }
  
        //console.log(data);
  
        this.setState(
            {
              workersList: data.foreignWorkersList
            }
        )
        
    }

    
    //Input Text SEARCH
    updateFormValue = (event) => {
        this.setState({ [event.target.name]: event.target.value }, function() {
            //console.log(event.target.value);
            this.loadData(this.state.searchData);
        });
        //console.log(event.target.value);
    }

    //SELECT SEARCH
    editHandlerSelect = (event) => {

        this.setState({ [event.target.name] : event.target.value}, function() {
            //console.log(event.target.value);
            this.loadData(this.state.searchData);
        });

    }

   
    selectRow = (id) => {

        //let newArray = [...this.state.workDays, event.target.id];

        

        if (this.state.rowsSelected.includes(id)) {

            let arr = this.state.rowsSelected;
            
            for( var i = 0; i < arr.length; i++){ 
                if ( arr[i] === id) { 
            
                    arr.splice(i, 1); 
                }
            }

            this.setState( { rowsSelected : arr }, function() {
                //console.log(this.state.rowsSelected);
            });

           
        }

        else {
            this.setState({ rowsSelected :[...this.state.rowsSelected, id]}, function() {
                //console.log(this.state.rowsSelected);
            });
        }
        
    }

    
    selectAllRows = () => {

        if(this.state.selectAllRows) {
            this.setState({ selectAllRows : false, rowsSelected : []}, function() {
                //console.log(this.state.rowsSelected);
            });
            
        } else {

            let newArray = [];
            //console.log(this.state.clientsList);

            this.state.workersList.forEach(currentItem => {
                if(currentItem.phoneSMS) {
                    newArray.push(currentItem.id);
                }
            })

            this.setState({ selectAllRows : true, rowsSelected : newArray}, function() {
                //console.log(this.state.rowsSelected);
            });
        }
        
    }


    sendSms = () => {
        this.showHideModal(true);
    }
    
    
    showHideModal = (value) => {
        //console.log('IN TASK');
        this.setState({ modalShow : value })
    }

    render() {

        //console.log(this.props.previousSearch);

        //let pageData = this.props.pageData;
        //console.log(pageData);
        
        let btnAction = this.state.hiddenWorkers ? false : true;

        let countryAreas = this.props.pageData.countryAreas;

        let choisesStatus = [
            {data : {id : 1,name : "פעיל"}},
            {data : {id : 2,name : "מוקפא"}},
            {data : {id : 3,name : "מוסתר"}},
            {data : {id : 4,name : "ועדה"}}
        ];

        let licenseOptions = [
            {data : {id : 1,name : "בתוקף"}},
            {data : {id : 2,name : "פג תוקף"}}
        ];

        return (
            <div>
                <button className='goBackCustomBtn' onClick ={()=> this.loadData(!btnAction)} ><i className="fas fa-sync-alt"></i></button> <h2 style={{display: 'inline-block'}} className="titStyle ">רשימת עובדים</h2>
                <Button className="addBtnGreen" onClick={() => this.props.insertWorker()} variant="secondary" size="sm"><img src={addClientPic} alt="הוספת עובד"/>הוספת עובד</Button>

                <div className="pannelSearch">

                    <div className="row">
                        <div className="col-lg-8 text-center ">
                            <InputGroup className="inputSearchCont">

                                <svg width="25" height="25" focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"></path></svg>

                                <FormControl
                                className='searchInput'
                                placeholder="חיפוש עובדים לפי שם, דרכון, כתובת, טלפון, ארץ לידה"
                                aria-label="חיפוש עובד"
                                name="searchData"
                                onChange={this.updateFormValue}
                                aria-describedby=""
                                value={this.state.searchData}
                                />
                            </InputGroup>  
                        </div>
                    </div>

                    <div className="accordionSearch">
                        <Accordion defaultActiveKey={this.props.previousSearch ? '0' : false}>
                            <Card>
                                <Card.Header>
                                <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                
                                    <span>חיפוש מתקדם</span> &raquo;

                                </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="0">
                                <Card.Body>
                                    <div className="row">
                                        <div className=' col-md-4 divBoostrap col-sm-12 ' >

                                            <InputGroup className="customInput">
                                                <InputGroup.Prepend className='inputPrependLabel'>
                                                <InputGroup.Text id="basic-addon3">סטטוס עובד</InputGroup.Text>
                                                </InputGroup.Prepend>
                                                <FormControl  as="select" 
                                                    className='inputPrepend selectPrepend'
                                                    onChange={this.editHandlerSelect} 
                                                    name='statusClientSearch' 
                                                    value={this.state.statusClientSearch}
                                                >
                                                    <option value=''>בחר...</option>
                                                    {choisesStatus.map(item => { if (true) {
                                                            /* currentState={this.state} */
                                                            return < option value ={item.data.name } key ={ item.data.name }> 
                                                                        { item.data.name } 
                                                                    </ option >
                                                        } else return false}) }
                                                </FormControl>
                                            </InputGroup>
                                        </div>

                                        <div className=' col-md-4 divBoostrap col-sm-12 ' >

                                            <InputGroup className="customInput">
                                                <InputGroup.Prepend className='inputPrependLabel'>
                                                <InputGroup.Text id="basic-addon3">אזור גיאוגרפי</InputGroup.Text>
                                                </InputGroup.Prepend>
                                                <FormControl  as="select" 
                                                    className='inputPrepend selectPrepend'
                                                    onChange={this.editHandlerSelect} 
                                                    name='countryAreasSearch' 
                                                    value={this.state.countryAreasSearch}
                                                >
                                                    <option value="">בחר...</option>
                                                    {countryAreas.map(item => { if (true) {
                                                            /* currentState={this.state} */
                                                            return < option value ={item.data.id } key ={ item.data.name }> 
                                                                        { item.data.name } 
                                                                    </ option >
                                                        } else return false}) }
                                                </FormControl>
                                            </InputGroup>
                                        </div>

                                        <div className=' col-md-4 divBoostrap col-sm-12 ' >

                                            <InputGroup className="customInput">
                                                <InputGroup.Prepend className='inputPrependLabel'>
                                                <InputGroup.Text id="basic-addon3">תאריך סיום ויזה</InputGroup.Text>
                                                </InputGroup.Prepend>
                                                <FormControl  as="select" 
                                                    className='inputPrepend selectPrepend'
                                                    onChange={this.editHandlerSelect} 
                                                    name='licenseSearch' 
                                                    value={this.state.licenseSearch}
                                                >
                                                    <option >בחר...</option>
                                                    {licenseOptions.map(item => { if (true) {
                                                            /* currentState={this.state} */
                                                            return < option value ={item.data.name } key ={ item.data.name }> 
                                                                        { item.data.name } 
                                                                    </ option >
                                                        } else return false}) }
                                                </FormControl>
                                            </InputGroup>
                                        </div>
                                    </div>


                                </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>


                        
                    </div>

                </div>

                {this.state.workersList.length > 0 ? <div className="sendSmsPanel animate__animated animate__fadeIn">
                    <div className="inputsGroup">
                        <div className="line">
                            <input type="checkbox" id="selectAllRows" value='1' onChange={this.selectAllRows} />
                            <label htmlFor="selectAllRows">בחר הכל</label>
                        </div>
                        {this.state.rowsSelected.length > 0 ? <div className="line animate__animated animate__fadeIn">
                            <Button className="sms" onClick={this.sendSms} variant="" size="">

                                <img src={addSmsPic} alt="SMS" />
                                <span>דיוור ב-SMS</span>
                                
                            </Button>
                        </div>: false }
                    </div>
                </div> : false }

                <div className="tableDisplay">

                    <div className="trTable titlesTr">
                        <div>#</div>
                        <div>שם מלא</div>
                        <div>טלפון א'</div>
                        <div>טלפון ב'</div>
                        <div>סיום ויזה</div>
                        <div>סטטוס</div>
                    </div>
                    <div className="trTable contentTds" style={{background : 'none'}}>
                        <div className="vSpace"></div>
                    </div>
                    
                        {this.state.workersList.map(item => { if (true) {
                            /* currentState={this.state} */
                            //console.log(item.data);
                            return <div className="trTable contentTds" key={item.id} >
                                    <div>
                                        <input disabled={item.phoneSMS ? false : true} type="checkbox" checked={ this.state.rowsSelected.includes(item.id) ? true : false} value={item.id} onChange={()=>this.selectRow(item.id)} />
                                    </div>
                                    <div>
                                        <button className="enterBtn" onClick ={()=>this.editHandler(item.id)}>{item.name + ' ' + item.surname + ''}<i className="fas fa-arrow-circle-left" /></button>
                                    </div>
                                    <div>{item.phone1}</div>
                                    <div>{item.phone2}</div>
                                    <div>{convertDate(item.visa_validity)}</div>
                                    <div>{item.workerStatus}</div>
                                    </div>

                        } else return false}) }
                </div>


                {/* https://react-bootstrap.netlify.app/components/modal/ */}
                <Modal
                    className='animate__animated animate__fadeInDown animate__faster newTaskModal'
                    animation={false}
                    onHide={() => this.showHideModal(false)}
                    /* size="sm" // בגודל קטן!!! */
                    /* size="lg" // גדול!! */
                    // backdrop="static" // חייב ללחוץ על כפתור!
                    /* centered // VERTICA CENTER */
                    show={this.state.modalShow}
                    dialogClassName="modal-90w"
                    aria-labelledby="example-custom-modal-styling-title"
                >
                    <Modal.Header>
                        <Modal.Title id="example-custom-modal-styling-title">
                            <span>דיוור ב-SMS</span>
                        </Modal.Title>
                        <Button onClick={() => this.showHideModal(false)} variant="secondary" >X</Button>
                    </Modal.Header>
                    <Modal.Body>
                        <SendSMSModalBody rowsSelected = {this.state.rowsSelected} sendPage="workers" />
                    </Modal.Body>
                </Modal>

                

            </div>
        )
    }
}
