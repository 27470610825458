import React, { Component } from 'react'

//import {useHistory} from "react-router-dom";

import { Container } from 'react-bootstrap';
import {logout} from "./../../logout"
import { Button } from 'react-bootstrap';
import SearchAutocompleteAndDbFocus from './../../DinamicForm/SearchAutocompleteAndDbFocus'

//import { Redirect } from "react-router-dom";

/* function ChangeRouter() {
    const history = useHistory();
    history.push("/home")
}
 */

export default class NavInner extends Component {

 
    handleSearchSelected = (item) => {

        localStorage.setItem('searchInner',JSON.stringify(item));
        let url = item.searchType === 'clients' ? '/לקוחות' : '/עובדים';
        window.location.href = url;
        
    }
    

    render() {


        //console.log(this.props);

        return (
            <div className='NavInner'>

                {/* {this.state.redirect ? <Redirect to={this.state.redirect} /> : false } */}

                <Container>
                    
                    <div className="row">

                        <div className="col-5">
                            <div className="SearchAutocomplete" style={{ width: '100%'}} >
                                <SearchAutocompleteAndDbFocus keys={["name"]} placeholder="חיפוש לקוחות/עובדים" submit={this.handleSearchSelected} DBItems='SiteDataItems?searchDataMenuItems=1' />
                            </div>
                        </div>
                        
                        
                        <div className="col-7 userLogout">
                            <div className="userCont">
                                <i className="fas fa-user-circle userCircle"></i>
                                <span className='userName'>שלום, {this.props.user.name}</span>
                                {localStorage.getItem('user-info').length > 0 ? <Button className="logOutBtn" onClick={logout} variant="secondary" size="sm"><i className="fas fa-sign-out-alt" /></Button> : '' }
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        )
    }
}
