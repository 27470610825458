import React, { Component, } from 'react'
import { InputGroup, FormControl } from 'react-bootstrap';
import getDataFromApi from "./../../../DinamicForm/getDataFromApi";
//import convertDate from './../../../convertDateToDisplay'
import convertDateToDb from './../../../convertDateToDb'

import InputMet from './../Finance/InputMet';

export default class FiltersVisitReport extends Component {

    constructor(props) {
        super(props)
    
        let date = new Date();

        let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

        this.state = {
            visitType : 'ביקור אחרון',
            clientStatus : '',
            socialWorker : '',

            date1 : new Date(firstDay.toString().replace(/-/g, '/')),
            date2 : new Date(lastDay.toString().replace(/-/g, '/')),

            visitReportData : false
        }
    }

    
    sendtoAdmin = (url,controller,objectToSend = {}, stateNameResponse = false) => {

        let setStateName;

        if(stateNameResponse) {
            setStateName = stateNameResponse;
        } else {
            setStateName = 'data';
        };

        let getData = getDataFromApi(url,objectToSend,controller);
        getData.then(function(response) {return response;}).then(getData =>this.setState({ [setStateName] : getData,  loading : false }, function(){

            //console.log(getData);
            /* if(url === 'reportBuilder') {
                
            } */

        }));
    }


  /*   loadData = () => {
        this.sendtoAdmin('getReportHistory','Reports',{ seniorId : this.props.clientId },'reportsHistory');
    } */

    componentDidMount() {
        this.sendtoAdmin('getDataVisitReport','ReportsExcel',{ },'visitReportData');
    }
    


    handleSelect = (event) => {

        
        this.setState({ [event.target.name]: event.target.value},function(){
            //console.log(this.state)
            this.submitToProps();
        });

    }


    
    submit = (event) => {

        //console.log(event);

        this.setState(event,
            function(){
                this.submitToProps();
            }
        );
    }


    submitToProps = () => {

        let newState = {

            visitType : this.state.visitType,
            clientStatus : this.state.clientStatus,
            socialWorker : this.state.socialWorker,

            date1 : convertDateToDb(this.state.date1),
            date2 : convertDateToDb(this.state.date2)

        }
        

        this.props.submit(newState);

    }
    

    

    render() {

        let dataDB = this.state.visitReportData ? this.state.visitReportData : false;

        return (
            <div>

                { dataDB ? <div className="row">

                <div className="col-md-2 divBoostrap col-sm-12">
                    <InputMet text='מתאריך' as={'dateInput'} value={this.state.date1} placeholder='' name='date1' submit={this.submit} />
                </div>

                <div className="col-md-2 divBoostrap col-sm-12">
                    <InputMet text='לתאריך' as={'dateInput'} value={this.state.date2} placeholder='' name='date2' submit={this.submit} />
                </div>

                <div className="col-md-4 divBoostrap col-sm-12">

                    <InputGroup className="customInput">
                        <InputGroup.Prepend className='inputPrependLabel '>
                            <InputGroup.Text>סוג ביקור</InputGroup.Text>
                        </InputGroup.Prepend>
                        
                        <FormControl
                            as='select'
                            /* 'inputPrepend customSelect' */
                            className='inputPrepend customSelect'
                            onChange={this.handleSelect} 
                            value={this.state.visitType}
                            name="visitType"
                        >
                        
                        <option value="">ביקור אחרון</option>

                        {dataDB.visitTypes.map(item => { if (true) {
                        /* currentState={this.state} */
                        return  <option value={item} key={item}>{item}</option>
                        } else return false}) }
                        

                        </FormControl>

                    </InputGroup>

                </div>

                <div className="col-md-4 divBoostrap col-sm-12">
                    <InputGroup className="customInput">
                        <InputGroup.Prepend className='inputPrependLabel '>
                            <InputGroup.Text>עובדים סוציאלים</InputGroup.Text>
                        </InputGroup.Prepend>
                        
                        <FormControl
                            as='select'
                            /* 'inputPrepend customSelect' */
                            className='inputPrepend customSelect'
                            onChange={this.handleSelect} 
                            value={this.state.socialWorker}
                            name="socialWorker"
                        >
                        
                        <option value="">בחר...</option>

                        {dataDB.socialWorkers.map(item => { if (true) {
                            /* currentState={this.state} */
                            return  <option value={item.id} key={item.id}>{item.name}</option>
                        } else return false}) }
                        

                        </FormControl>

                    </InputGroup>
                </div>

                {false ? <div className="col-md-2 divBoostrap col-sm-12">

                    <InputGroup className="customInput">
                        <InputGroup.Prepend className='inputPrependLabel '>
                            <InputGroup.Text>סטטוס</InputGroup.Text>
                        </InputGroup.Prepend>
                        
                        <FormControl
                            as='select'
                            /* 'inputPrepend customSelect' */
                            className='inputPrepend customSelect'
                            onChange={this.handleSelect} 
                            value={this.state.clientStatus}
                            name="clientStatus"
                        >
                        
                        <option value="">בחר...</option>

                        {dataDB.clientStatus.map(item => { if (true) {
                            /* currentState={this.state} */
                            return  <option value={item} key={item}>{item}</option>
                        } else return false}) }
                        

                        </FormControl>

                    </InputGroup>
                </div> : false }


                </div> : false }
            </div>
        )
    }
}
