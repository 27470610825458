import React, { Component } from 'react'
import { RestUrls } from "../../../Urls";
import InputFormAdmin from "../../../DinamicForm/InputFormAdmin";
//import ReportIt from "./ReportIt" 
//import Contacts from "./Contacts" 
import { Button } from 'react-bootstrap';


export default class WorkerScreenNewWorker extends Component {

    constructor(props) {

        super(props);
        this.state = {            
            dataPage : [],
            isLoaded: false
        }

    }

    async componentDidMount() {

        localStorage.setItem('searchInner','');
        localStorage.setItem('currentPathSearch','');

        const API =  RestUrls.baseApiUrl + 'foreignWorkers/?noIDNewWorker=1';

        //console.log(API);

        let user = this.props.user;
        

        let userPass = { 
                userId : user.id,
                userName : user.username,
                userCredential : user.userCredential,
                token : user.token,
                siteCode :  RestUrls.Code
            };

        const res = await fetch(API, {
            method: 'POST',
            headers: {
              "Content-Type" : 'application/json',
              "Accept" : 'application/json'
            },
            body: JSON.stringify(userPass)
          });


        const data = await res.json();

        //console.log(data);
       
        if(data === 'unauthorized') {
            localStorage.setItem('user-info','');
            window.location.reload();
        } else {
            this.setState(
                {
                  dataPage: data,
                  isLoaded: true
                }
            )
        }
  
        //console.log(data);
    }


    async insertWorkerToDb() {

        let urlAction = 'foreignWorkers?insertWorker=1';

        

        //console.log('IN');
        let userJ = JSON.parse(localStorage.getItem('user-info'));
        let user = userJ.data;

        let item = {
        userId : user.id,
        userName : user.username,
        userCredential : user.userCredential,
        token : user.token,
        siteCode :  RestUrls.Code
        };

        let items = {...item, ...this.state };
        //console.log(items);
    
        let fletchUrl = RestUrls.baseApiUrl + urlAction;

        let result = await fetch(fletchUrl, {
            method: 'POST',
            headers: {
                "Content-Type" : 'application/json',
                "Accept" : 'application/json'
            },
            body: JSON.stringify(items)
            });
    
            let data = await result.json();
            //console.log(data);
    
            if(data === 'unauthorized') {
                localStorage.setItem('user-info','');
                window.location.reload();
            } else {
                //deleteMe(data.insertId,urlDelleteTwice);
                this.editHandler(data.insertId);

            }
    }


    editHandler = (selectedClientId) => {
        this.props.cancelId(selectedClientId);
    }

     //GET INPUT VALUES FROM ADMINiNPUTS
    submit = (state) => {
        this.setState(state);
        //console.log(state);
    }

    insertWorker = () => {
        this.insertWorkerToDb();
    }

    render() {
        if (!this.state.isLoaded) {
            return <div>טוען...</div>
        }

        else {

   
            //let WorkerData = this.state.dataPage.dataParam;

            return (
                <div className="clientScreen workerScreen">

                    <button className='goBackCustomBtn bounce' onClick ={()=>this.editHandler(false)} >&laquo; חזור</button> <h2 className="titStyle firstH2">הוספת עובד</h2>

                    <div className="row formContainerAdmin">
                        {this.state.dataPage.form.map(item => { if (true) {
                            /* currentState={this.state} */
                            return <InputFormAdmin
                                        platform={this.props.platform}
                                        Prepend={true}
                                        user={this.props.user}
                                        methodName='foreignWorkers'
                                        updateGetAction='?update=1'
                                        data={item}
                                        rowsOption4Select = {this.state.dataPage.rowsOption4Select}
                                        dataParam={false}
                                        submit={this.submit}
                                        key={item.name} />
                        } else return false}) }
                    </div>
                    <Button style={{margin: '-30px 0px 0 -13px'}} className="addBtn" onClick={this.insertWorker} variant="secondary" size="sm"><i className="fas fa-download" />שמור ולהמשיך</Button>
                </div>
            )
        }
    }
}
