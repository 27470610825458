import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify' //npm i react-toastify

import { Row, Button } from 'react-bootstrap';
import InputShowData from './InputShowData';

import getDataFromApi, { sendtoAdmin } from '../../../DinamicForm/newApi/getDataFromApi';
import loader from "../../../../img/preLoader.gif"
import convertDate from './../../../convertDateToDisplay'

import ReportHome1SaveData from './ReportHome1SaveData'

export default function ReportHome1(props) {


    const { clientId, savedData, setNewVisitStep2Data, visitType, chosensocialWorker, visitDate } = props;

    const [loading, setLoading] = useState(false);
    const [responseData, setResponseData] = useState(savedData ? savedData : false);
    const [idFormData, setIdFormData] = useState(savedData?.idFormData ? savedData.idFormData : false);


    useEffect(() => {

        const send = {
            clientId: clientId
        };

        if (!savedData) {
            sendtoAdmin('newReportData', 'HomeVisits', send, setResponseData, setLoading);
        }



    }, [clientId, savedData]);


    const stepSaveData = () => {

        if (!loading) {

            const send = {
                created_at: visitDate,
                chosenSocialWorker: chosensocialWorker,
                visitType: visitType,
                seniorId: clientId,

                comments: '',
                sign: '',


            }

            sendtoApi('addVisitWithForm', 'HomeVisits', send, setLoading, setIdFormData, setNewVisitStep2Data, props, responseData);
        }

    }


    return <div className='ReportHomeVisit animate__animated animate__fadeIn'>

        <img src={loader} alt="loader" className={!loading ? "loader" : "loader active animate__animated animate__fadeIn"} />
        {/* <h2>בוקור בית - לאחר השמה/שותף</h2> */}

        {/* NO SAVE FIELDS */}
        {!idFormData && <div className='formCont'>

            {responseData && <section>

                <Row>
                    <div className="col-md-6 divBoostrap col-sm-6">
                        <InputShowData name='שם הלשכה' value={responseData.firmData.firmName} />
                    </div>
                    <div className="col-md-6 divBoostrap col-sm-6">
                        <InputShowData name='רחוב' value={responseData.firmData.address} />
                    </div>
                </Row>

                <Row>
                    {savedData && <div className="col-md-4 divBoostrap col-sm-12">
                        <InputShowData name='תאריך הביקור' value={convertDate(visitDate)} />
                    </div>}
                    <div className={`${!savedData ? 'col-md-6' : 'col-md-4'} divBoostrap col-sm-12`}>
                        <InputShowData name='טלפון קווי' value={responseData.firmData.phone} />
                    </div>
                    <div className={`${!savedData ? 'col-md-6' : 'col-md-4'} divBoostrap col-sm-12`}>
                        <InputShowData name='טלפון נייד' value={responseData.firmData.phone1} />
                    </div>
                </Row>

                {savedData && <Row>
                    <div className="col-md-12 divBoostrap col-sm-12">
                        <InputShowData name='שם עובד סוציאלי/בעל תפקיד בכיר' value={responseData.chosensocialWorkerName} />
                    </div>
                </Row>}

            </section>}

            {responseData && <section>

                <h3>פרטים אישיים של המעסיק</h3>

                <Row>
                    <div className="col-md-4 divBoostrap col-sm-12">
                        <InputShowData name='מספר זהות' value={responseData.clientData.tz} />
                    </div>
                    <div className="col-md-4 divBoostrap col-sm-12">
                        <InputShowData name='שם משפחה' value={responseData.clientData.surname} />
                    </div>
                    <div className="col-md-4 divBoostrap col-sm-12">
                        <InputShowData name='שם פרטי' value={responseData.clientData.name} />
                    </div>

                </Row>

            </section>}

            {responseData && <section>

                <h3>פרטי איש קשר</h3>

                <Row>
                    <div className="col-md-4 divBoostrap col-sm-12">
                        <InputShowData name='קירבה' value={responseData.contactsSeniors.proximity} />
                    </div>
                    <div className="col-md-4 divBoostrap col-sm-12">
                        <InputShowData name='שם' value={responseData.contactsSeniors.name} />
                    </div>
                    <div className="col-md-4 divBoostrap col-sm-12">
                        <InputShowData name='טלפון' value={responseData.contactsSeniors.phone} />
                    </div>

                </Row>

            </section>}

            {responseData && <section>

                <h3>כתובת המעסיק</h3>

                <Row>
                    <div className="col-md-8 divBoostrap col-sm-12">
                        <InputShowData name='כבותב' value={responseData.clientData.address} />
                    </div>

                    <div className="col-md-4 divBoostrap col-sm-12">
                        <InputShowData name='ישוב' value={responseData.clientData.city} />
                    </div>
                </Row>

                <Row>

                    <div className="col-md-4 divBoostrap col-sm-12">
                        <InputShowData name='טלפון קווי' value={responseData.clientData.phone} />
                    </div>

                    <div className="col-md-4 divBoostrap col-sm-12">
                        <InputShowData name='טלפון נייד' value={responseData.clientData.phoneSMS} />
                    </div>

                    <div className="col-md-4 divBoostrap col-sm-12">
                        <InputShowData name='דואר' value={responseData.clientData.email} />
                    </div>

                </Row>

            </section>}

            {responseData && <section>

                <h3>פרטי העובד הזר</h3>

                <Row>
                    <div className="col-md-4 divBoostrap col-sm-12">
                        <InputShowData name='שם משפחה' value={responseData.foreignWorker.surname} />
                    </div>

                    <div className="col-md-4 divBoostrap col-sm-12">
                        <InputShowData name='שם' value={responseData.foreignWorker.name} />
                    </div>

                    <div className="col-md-4 divBoostrap col-sm-12">
                        <InputShowData name='ארץ מוצא' value={responseData.foreignWorker.CountryofBirth} />
                    </div>
                </Row>

                <Row>
                    <div className="col-md-6 divBoostrap col-sm-12">
                        <InputShowData name='תאריך תחילת העסקה' value={responseData.foreignWorker.dateStart} />
                    </div>

                    <div className="col-md-6 divBoostrap col-sm-12">
                        <InputShowData name='טלפון נייד' value={responseData.foreignWorker.phone1} />
                    </div>
                </Row>

                <Row>
                    <div className="col-md-6 divBoostrap col-sm-12">
                        <InputShowData name='טלפון נייד' value={responseData.foreignWorker.phone1} />
                    </div>

                    <div className="col-md-6 divBoostrap col-sm-12">
                        <InputShowData name='תאריך תחילת העסקה' value={responseData.foreignWorker.dateStart} />
                    </div>

                </Row>

                <Row>
                    <div className="col-md-12 divBoostrap col-sm-12">
                        <InputShowData name='פרטי לשכה מייבאת' value={responseData.foreignWorker.company + ' ' + responseData.foreignWorker.branch} />
                    </div>

                </Row>

                <Row>
                    <div className="col-md-12 divBoostrap col-sm-12">
                        <InputShowData name='כתובת מגורים ביום החופשה' value={responseData.foreignWorker.address} />
                    </div>
                </Row>

                <Row>

                    <div className="col-md-4 divBoostrap col-sm-12">
                        <InputShowData name='יום החופשה' value={responseData.foreignWorker.restDay} />
                    </div>

                </Row>



            </section>}


            {responseData && <section>

                <h3>תאריכי ביקור אחרונים</h3>

                {responseData.homeVisitsLog[0] && <Row>
                    <div className="col-md-6 divBoostrap col-sm-12">
                        <InputShowData name={responseData.homeVisitsLog[0]?.name} value={convertDate(responseData.homeVisitsLog[0]?.created)} />
                    </div>

                    <div className="col-md-6 divBoostrap col-sm-12">
                        <InputShowData name='שם העו"ס' value={responseData.homeVisitsLog[0]?.socialWorker_name} />
                    </div>
                </Row>}

                {responseData.homeVisitsLog[1] && <Row>
                    <div className="col-md-6 divBoostrap col-sm-12">
                        <InputShowData name={responseData.homeVisitsLog[1]?.name} value={convertDate(responseData.homeVisitsLog[1]?.created)} />
                    </div>

                    <div className="col-md-6 divBoostrap col-sm-12">
                        <InputShowData name='שם העו"ס' value={responseData.homeVisitsLog[1]?.socialWorker_name} />
                    </div>
                </Row>}

                {responseData.homeVisitsLog[2] && <Row>
                    <div className="col-md-6 divBoostrap col-sm-12">
                        <InputShowData name={responseData.homeVisitsLog[2]?.name} value={convertDate(responseData.homeVisitsLog[2]?.created)} />
                    </div>

                    <div className="col-md-6 divBoostrap col-sm-12">
                        <InputShowData name='שם העו"ס' value={responseData.homeVisitsLog[2]?.socialWorker_name} />
                    </div>
                </Row>}

            </section>}

            {!savedData && <div className='clear' style={{ textAlign: 'left' }}>
                <Button className="sendForm" onClick={stepSaveData} size="" variant="success" >אישור והמשך &raquo;</Button>
            </div>}
        </div>}

        {/* SAVED FIELDS */}
        {idFormData && <div className='formCont saveData'>

            <ReportHome1SaveData {...props} responseData={responseData} idFormData={idFormData} formSavedData={false} />


        </div>}

    </div>;
}



function sendtoApi(url, controller, objectToSend, setLoading, setIdFormData, setNewVisitStep2Data, props, responseData) {

    setLoading(true)
    const getData = getDataFromApi(url, objectToSend, controller, 'all')

    getData.then((getData) => {

        if (getData.insertId) {

            toast.success('הביקור נפתח בהצלחה');

            setIdFormData(getData.insertId);

            setNewVisitStep2Data({
                visitType: props.visitType,
                visitDate: props.visitDate,
                fullNameClient: responseData.clientData.name + ' ' + responseData.clientData.surname
            });


        } else {
            toast.error('שגיאה בפתיחת הביקור');
        }

    })

    getData.catch((error) => {
        console.log(error)
    })

    getData.finally(() => {
        setLoading(false)
    })
}