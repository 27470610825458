import React, { Component } from 'react'

import getDataFromApi from "./../../../DinamicForm/getDataFromApi";
//import { InputGroup, FormControl } from 'react-bootstrap';

import SearchAutocompleteAndDbFocusClients from './SearchAutocompleteAndDbFocusClients'
import convertDate from './../../../convertDateToDisplay'
import { getAllUrlParams } from "./../../../UrlParameters"

import { animateScroll as scroll, } from 'react-scroll' /* https://www.npmjs.com/package/react-scroll */
import { NavLink } from 'react-router-dom';

import HomeVisitsEditRow from './HomeVisitsEditRow'
//import md5 from 'md5';

import { Button } from 'react-bootstrap';

export default class HomeVisits extends Component {

    constructor(props) {

        super(props);

        let today = new Date();

        this.state = {
            data: '',
            dataClient: false,
            visitType: '',
            comments: '',
            created_at: new Date(today.toString().replace(/-/g, '/')),
            trimmedDataURL: null,
            responseVisit: false,
            selectedClientId: '',
            chosenSocialWorker: '',

            editRowId: false,
            showHistory: false
        }

    }

    loadData = (url, controller, objectToSend = {}, stateNameResponse = false) => {

        //console.log('IN');

        //let objectToSend = {hello : '1'}

        let setStateName;

        if (stateNameResponse) {
            setStateName = stateNameResponse;
        } else {
            setStateName = 'data';
        };

        let getData = getDataFromApi(url, objectToSend, controller);
        getData.then(function (response) { return response; }).then(getData => this.setState({ [setStateName]: getData }));
    }

    componentDidMount() {
        let urlParams = localStorage.getItem('currentPathSearch') ? localStorage.getItem('currentPathSearch') : false;
        let urlQuery = urlParams ? getAllUrlParams(urlParams) : false;

        //console.log(urlQuery);

        if (urlQuery.id) {

            this.setState({ selectedClientId: urlQuery.id }, function () {
                this.loadData('getclientData', 'homeVisits', { clientId: urlQuery.id }, 'dataClient');
            });

        }

        //this.loadData('getclients','homeVisits');
        //
    }

    getHistoryData = () => {

        let send = {};

        if (!this.state.showHistory) {

            this.setState({ showHistory: true })

            send = {
                clientId: this.state.selectedClientId,
                history: true
            }

        } else {

            this.setState({ showHistory: false })

            send = {
                clientId: this.state.selectedClientId
            }

        }

        this.loadData('getclientData', 'homeVisits', send, 'dataClient');


    }



    handleSearchSelected = (item) => {

        this.setState({ selectedClientId: item.id }, function () {

            this.loadData('getclientData', 'homeVisits', { clientId: item.id }, 'dataClient');

        });

    }

    editRow = (item) => {

        //console.log(item.id);
        this.setState({ editRowId: item.id });

    };


    render() {

        let userJ = JSON.parse(localStorage.getItem('user-info'));
        let user = userJ.data;

        const md5 = require('md5');

        return (

            <div className="homeVisitsPage">
                <h2 className="titStyle">ביקורי בית</h2>

                {/* Edit row */}
                {!this.state.editRowId ? <>
                    <div className="searchClients">
                        {user.userCredential !== '1' ? <div>

                            {true ? <div className="row justify-content-center selectorContent">

                                <div className="col-lg-8 text-center selectClientsWorkers">

                                    <div className="row">

                                        <div className="SearchAutocomplete" style={{ width: '100%' }} >

                                            {user.userCredential !== 'socialWorker' || (user.userCredential === 'socialWorker' && parseInt(this.props.pageData.countClients) >= 1) ?
                                                <SearchAutocompleteAndDbFocusClients keys={["name"]} coordinatorId={user.id} placeholder="חיפוש לקוחות לצפייה ההיסטוריה ביקורי בית" submit={this.handleSearchSelected} DBItems={'homeVisits/getclients?searchData=1'} />
                                                : <h2 style={{ color: 'red' }}>אין לקוחות משוייכים</h2>}
                                        </div>

                                    </div>
                                </div>
                            </div> : false}

                        </div> : false}
                    </div>


                    {this.state.dataClient ? <section className="clientDataVisit">
                        <div className='headerCont'>
                            <h2 className="titStyle">לקוח - {this.state.dataClient.client.name + ' ' + this.state.dataClient.client.surname}
                                <a className="jumpTo" href={'לקוחות/?id=' + this.state.dataClient.client.id}>לפרטי לקוח &raquo;</a></h2>

                            <Button onClick={() => this.getHistoryData()} size="sm" variant="secondary" >{!this.state.showHistory ? <span>לכל ביקורי הבית &raquo;</span> : <span>&laquo; חזור</span>}</Button>
                        </div>


                        {this.state.dataClient.homeVisitsLog[0] ? <div className="tableComments SeniorContacts">
                            <div className="seniorsTableContact">
                                <div className="trTable titlesTr">
                                    <div className="tdTable">תאריך</div>
                                    <div className="tdTable">סוג ביקור</div>
                                    <div className="tdTable">שם עובדים סוציאלים</div>
                                    <div className="tdTable">הערות</div>
                                    <div className="tdTable">חתימה</div>
                                    <div className="tdTable">#</div>
                                </div>
                                <div className="trTable contentTds">
                                    <div className="vSpace"></div>
                                </div>

                                {this.state.dataClient.homeVisitsLog.map(item => {
                                    if (true) {
                                        /* currentState={this.state} */
                                        return <div key={item.id} className="trTable contentTds">
                                            <div className="col-md-2 divBoostrap col-sm-12 name"><p>{convertDate(item.created)}</p></div>
                                            <div className="col-md-2 divBoostrap col-sm-12 name"><p>{item.name}</p></div>
                                            <div className="col-md-2 divBoostrap col-sm-12 name"><p>{item.socialWorker_name}</p></div>
                                            <div className="col-md-2 divBoostrap col-sm-12 name"><p>{item.comments}</p></div>
                                            <div className="col-md-2 divBoostrap col-sm-12 name">{item.sign ? <img src={item.sign} alt='sign' /> : null}</div>
                                            {(item.name === 'ביקור חצי שנתי' || item.name === 'ביקור אחרי השמה (30 יום אחרי)') && <div className="col-md-2 divBoostrap col-sm-12 buttons">
                                                <button onClick={() => this.editRow(item)} >
                                                    <i className="fas fa-edit"></i>
                                                    <span>עריכה</span>
                                                </button>
                                                <a href={'/הוספת_ביקור_בית?print=' + md5(item.id)} target="_blank" rel="noopener noreferrer" >
                                                    <i className="fas fa-print"></i>
                                                    <span>הדפסה</span>
                                                </a>
                                            </div>}
                                        </div>
                                    } else return false
                                })}
                            </div>
                        </div> : false} {/* <h3 style={{fontSize : '18px'}}>אין הערות קודמות</h3> */}



                    </section> : false}

                    <div className="newVisitBtnCont clear">
                        {!this.state.selectedClientId ? <NavLink
                            onClick={() => scroll.scrollTo(0)}
                            to={`/הוספת_ביקור_בית?clientId=${this.state.selectedClientId}`} >
                            <span>הוספת ביקור חדש</span>
                        </NavLink> :

                            <a href={`/הוספת_ביקור_בית?clientId=${this.state.selectedClientId}`}>
                                <span>הוספת ביקור חדש</span>
                            </a>}
                    </div>
                </> : <div className='editRow'>

                    <button className="goBackCustomBtn bounce" onClick={() => this.setState({ editRowId: false })}>« חזור</button>
                    <HomeVisitsEditRow editRowId={this.state.editRowId} />

                </div>}

            </div>
        )
    }


}
