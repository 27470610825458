import React, { Component } from 'react'
import ClientScreen from './clientScreen';
import ClientScreenNewClient from './clientScreenNewClient';

import ClientsList from './ClientsList';
import {getAllUrlParams} from "./../../../UrlParameters"

import { RestUrls } from "./../../../Urls"


export default class Clients extends Component {

    constructor(props) {

        // ID IN SEARCH BAR
        let searchJ = localStorage.getItem('searchInner') ? JSON.parse(localStorage.getItem('searchInner')) : '';

        //ID IN URL
        let urlParams = localStorage.getItem('currentPathSearch') ? localStorage.getItem('currentPathSearch') : false;
        let urlQuery = urlParams ? getAllUrlParams(urlParams) : false;

        super(props);

        this.state = {      
            pageData : this.props.pageData,      
            newClient : false,
            showList : true,
            selectedClientId : searchJ.id ? searchJ.id : urlQuery.id ? urlQuery.id : false,
            listPageSate : false
        }

    }


    async refreshData () {

        let urlAction = 'clients';

        //console.log('IN');
        let userJ = JSON.parse(localStorage.getItem('user-info'));
        let user = userJ.data;

        let item = {
        userId : user.id,
        userName : user.username,
        userCredential : user.userCredential,
        token : user.token,
        siteCode :  RestUrls.Code
        };
    
        let fletchUrl = RestUrls.baseApiUrl + urlAction;

        let result = await fetch(fletchUrl, {
            method: 'POST',
            headers: {
                "Content-Type" : 'application/json',
                "Accept" : 'application/json'
            },
            body: JSON.stringify(item)
            });
    
            let data = await result.json();
            //console.log(data);
    
            if(data === 'unauthorized') {
                localStorage.setItem('user-info','');
                window.location.reload();
            } else {
                //deleteMe(data.insertId,urlDelleteTwice);
                this.setState( {pageData: data, showList : true} );

            }
    }





    // selectedId SELECTED
    handleChange = (selectedClientId,currentState = false) => {

        //console.log('hello: ' + selectedClientId);
        this.setState({
            selectedClientId: selectedClientId,
            newClient : false,
            showList : false,
            listPageSate : currentState
            }, function() {this.refreshData();});

        if (currentState) {
            this.setState({
                listPageSate : currentState
            });
        }

    }


    insertClient = () => {
        window.history.replaceState({}, document.title, "/לקוחות");
        this.setState({ newClient : true});
        //console.log('insertClients');
    }
    

    render() {

        let pageData = this.state.pageData;
        /* console.log(this.props); */

        //console.log(localStorage.getItem('currentPath'));
        //console.log(localStorage.getItem('currentPathSearch'));
        
        return (
            <div className="ClientsPage animate__animated animate__fadeIn">
                
                {this.state.selectedClientId ? <section className="clientScreenSec">
                    <ClientScreen platform ={this.props.infoSite.media} user = {this.props.infoSite.user.data} clientId={this.state.selectedClientId} pageData={pageData} cancelId={(selectedClientId) => this.handleChange(selectedClientId,this.state.listPageSate) } />
                    </section> :

                    this.state.newClient ? <section className="clientScreenSec">
                    <ClientScreenNewClient platform ={this.props.infoSite.media} user = {this.props.infoSite.user.data} clientId={this.state.selectedClientId} pageData={pageData} cancelId={(selectedClientId) => this.handleChange(selectedClientId) } />
                    </section> :

                    <section className="clientScreenSec">
                        {this.state.showList ? 
                            <ClientsList previousSearch={this.state.listPageSate} pageData={pageData} insertClient={this.insertClient} submit={(selectedClientId,currentState) => this.handleChange(selectedClientId,currentState) } />
                            : false }
                    </section> }
                
            </div>
        )
    }
}
