import React, { Component } from 'react';

import { InputGroup, FormControl, Form, Button, Modal } from 'react-bootstrap';
import "react-datepicker/dist/react-datepicker.css";

import SignatureCanvas from 'react-signature-canvas';
import getDataFromApi from "./../../../DinamicForm/getDataFromApi";

function convertDate2(dateValue) {

    let current_datetime = new Date(dateValue.toString().replace(/-/g, '/')); // IPHONE FIX

    /* 2021-03-25 */

    let formatted_date = current_datetime.getFullYear() + '-' +
        (current_datetime.getMonth() + 1) + "-" +
        current_datetime.getDate();

    return formatted_date;
}

export default class ReportHomeClali extends Component {

    constructor(props) {

        super(props);

        let today = new Date();

        this.state = {
            data: '',
            dataClient: false,
            visitType: this.props.visitType,
            comments: '',
            created_at: new Date(today.toString().replace(/-/g, '/')),
            trimmedDataURL: null,
            responseVisit: false,
            selectedClientId: this.props.clientId,
            chosenSocialWorker: this.props.chosensocialWorker
        }

    }


    submitVisit = () => {

        let userJ = JSON.parse(localStorage.getItem('user-info'));
        let user = userJ.data;

        if (!this.state.visitType || !this.state.trimmedDataURL || (user.userCredential !== 'socialWorker' && !this.state.chosenSocialWorker)) {

            this.modalErrors(true);

        } else {


            let sendData = {
                created_at: this.state.created_at,
                seniorId: this.state.selectedClientId,
                visitType: this.state.visitType,
                chosenSocialWorker: this.state.chosenSocialWorker,
                comments: this.state.comments,
                sign: this.state.trimmedDataURL
            }

            //console.log(sendData);

            this.loadData('addVisit', 'homeVisits', sendData, 'responseVisit');

            let today = new Date();

            this.setState({

                created_at: new Date(today.toString().replace(/-/g, '/')),
                visitType: '',
                comments: '',
                trimmedDataURL: null

            }, function () {
                this.sigPad.clear();
                setTimeout(() => this.loadData('getclientData', 'homeVisits', { clientId: this.state.selectedClientId }, 'dataClient'), 100);
            });


        }





    }



    handleSearchSelected = (item) => {

        this.setState({ selectedClientId: item.id }, function () {

            this.loadData('getclientData', 'homeVisits', { clientId: item.id }, 'dataClient');

        });

    }

    //For datePicker
    handleChangeDate = (date, nameInput) => {

        let newDate = convertDate2(date);
        //console.log(newDate);


        this.setState({ [nameInput]: newDate }, function () {
            //console.log(this.state);
        });

    }

    // INPUT
    handleChange = (event) => {


        this.setState({ [event.target.name]: event.target.value },
            function () {
                //console.log(this.state);
                //this.props.submit( {[event.target.name] : this.state.inputValue});
            });


    }



    endSign = () => {
        this.setState({
            trimmedDataURL: this.sigPad.getTrimmedCanvas()
                .toDataURL('image/png')
        }, function () {
            //console.log(this.state.trimmedDataURL);
        })
        //console.log('end');

    }

    onBegin = () => {
        //console.log('onBegin');
    }

    //sigPad = {}
    sigPad = {

    }

    clear = () => {
        this.setState({ trimmedDataURL: null }, function () { this.sigPad.clear() })
    }

    trim = () => {
        this.setState({
            trimmedDataURL: this.sigPad.getTrimmedCanvas()
                .toDataURL('image/png')
        }, function () {
            //console.log(this.state.trimmedDataURL);
        })
    }

    modalErrors = (value) => {
        this.setState({ modalShow: value })
    }


    loadData = (url, controller, objectToSend = {}, stateNameResponse = false) => {

        //console.log('IN');

        //let objectToSend = {hello : '1'}

        let setStateName;

        if (stateNameResponse) {
            setStateName = stateNameResponse;
        } else {
            setStateName = 'data';
        };

        let getData = getDataFromApi(url, objectToSend, controller);
        getData.then(function (response) { return response; }).then(getData => {

            window.open('/ביקורי_בית?id=' + this.state.selectedClientId, '_self');
            this.setState({ [setStateName]: getData });

        });
    }



    render() {


        let userJ = JSON.parse(localStorage.getItem('user-info'));
        let user = userJ.data;

        return <div>

            <div className="insertNewVisit">


                <div className="row justify-content-center selectorContent">

                    <div className="col-lg-6 text-center selectClientsWorkers">

                        <h2 className="titStyle" style={{ border: 'none', margin: '20px 0 30px 0' }}>הוספת ביקור בית חדש</h2>

                        <div className="row formContainer">

                            <div className="col-md-12 divBoostrap col-sm-12" style={{ margin: '20px 0 0 0' }}>

                                <InputGroup className="customInput textArea">

                                    <Form.Label>הערות:</Form.Label>

                                    <FormControl
                                        as="textarea" rows={3}
                                        className='inputPrepend'
                                        onChange={this.handleChange}
                                        name='comments'
                                        value={this.state.comments}
                                    >

                                    </FormControl>
                                </InputGroup>
                            </div>

                            <div className="col-md-12 divBoostrap col-sm-12">
                                <div className="signContainer">

                                    <button className="form-control eraseBtn" onClick={this.clear}>
                                        <i className="far fa-trash-alt" />
                                    </button>

                                    <h3>חתימה</h3>
                                    <SignatureCanvas
                                        ref={(ref) => { this.sigPad = ref }}
                                        // Pre fill sign:
                                        //ref = {(ref) => {ref.fromDataURL(this.state.imgfromDb)}}
                                        onEnd={this.endSign}
                                        onBegin={this.onBegin}
                                        backgroundColor='white'
                                        penColor='black'
                                        canvasProps={{ width: 320, height: 200, className: 'sigCanvas' }} />
                                    <div>
                                        {/*  <button onClick={this.trim}>
                                    Trim
                                    </button> */}
                                    </div>
                                </div>
                            </div>


                            <div className="clear">
                                <Button className="sendForm" onClick={() => this.submitVisit()} size="lg" variant="success" >הוספה</Button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            {/* https://react-bootstrap.netlify.app/components/modal/ */}
            <Modal
                className='animate__animated animate__fadeInDown animate__faster modalErrors'
                animation={false}
                onHide={() => this.modalErrors(false)}
                /* size="sm" // בגודל קטן!!! */
                /* size="lg" // גדול!! */
                // backdrop="static" // חייב ללחוץ על כפתור!
                /* centered // VERTICA CENTER */
                show={this.state.modalShow}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header>
                    <Modal.Title id="example-custom-modal-styling-title">
                        <span>שגיאה. נא למלא את השדות חובה</span>
                    </Modal.Title>
                    <Button onClick={() => this.modalErrors(false)} variant="secondary" >X</Button>
                </Modal.Header>
                <Modal.Body>
                    <div className="errorCheck">
                        {!this.state.visitType ? <p>סוג ביקור</p> : false}
                        {!this.state.trimmedDataURL ? <p>חתימה</p> : false}
                        {user.userCredential !== 'socialWorker' && !this.state.chosenSocialWorker ? <p>יש לבחור עובד סוציאלי</p> : false}

                    </div>
                </Modal.Body>
            </Modal>


        </div>;


    }



}
