import { RestUrls } from "./../Urls"

export default async function getDataFromApi (url,objectToSend, controller = 'first') {


    

    const API =  RestUrls.baseApiUrl1 + controller + '/' + url;

    let userJ = JSON.parse(localStorage.getItem('user-info'));
    let user = userJ.data;
    
    //console.log(user);

    let userPass = { 
            userId : user.id,
            userName : user.username,
            userCredential : user.userCredential,
            token : user.token,
            siteCode :  RestUrls.Code
        };

    let items = {...userPass, ...objectToSend}

    //console.log(items);

    const res = await fetch(API, {
        method: 'POST',
        headers: {
          "Content-Type" : 'application/json',
          "Accept" : 'application/json'
        },
        body: JSON.stringify(items)
      });


    const data = await res.json();

    if(data === 'unauthorized') {
        localStorage.setItem('user-info','');
        window.location.reload();
    } 
    
    else if(data) {
        //console.log(data);
        return data;
    }

    else {
        console.log('error API');
    }

}

