import React, { Component } from 'react'
import { InputGroup, FormControl } from 'react-bootstrap';
import Wsign from './../Wsign/Wsign';
import WsignStatus from './../Wsign/WsignStatus';
import { animateScroll as scroll, } from 'react-scroll' /* https://www.npmjs.com/package/react-scroll */

export default class WsignIndex extends Component {


    constructor(props) {
        super(props)
    
        this.state = {
            selectedAction : '',
            activeId : false
        }
    }


    selectedAction = (event) => {

        this.setState({selectedAction :  event.target.value })
        scroll.scrollToBottom();
    }

    changeScreen = (screenName,activeId = false) => {
        
        this.setState({selectedAction :  screenName, activeId :activeId })
        scroll.scrollToBottom();

    }

    render() {

        let clientId = this.props.clientId ? this.props.clientId : '';
        let workerId = this.props.workerId ? this.props.workerId : '';



        return (
            <div className='WsignIndex'>

                <div className="contSelect">

                    {/* <h2 className='blueH2'>בחר</h2> */}
                    <InputGroup className="customInput" >

                        <InputGroup.Prepend className='inputPrependLabel '>
                            <InputGroup.Text>בחרו פעולה</InputGroup.Text>
                        </InputGroup.Prepend>

                        <FormControl
                            as='select'
                            /* 'inputPrepend customSelect' */
                            className='inputPrepend customSelect'
                            onChange={this.selectedAction} 
                            value={this.state.selectedAction}
                            name="selectedAction"
                        >

                            <option value="">בחר...</option>

                            <option value="חתימה חדשה">חתימה חדשה</option>
                            <option value="סטטוס מסמכים">סטטוס מסמכים</option>

                        
                        </FormControl>

                    </InputGroup>
                </div>

                {this.state.selectedAction === 'חתימה חדשה' ? <Wsign changeScreen={(screenName,activeId) => this.changeScreen(screenName,activeId) } clientId={clientId} workerId={workerId} /> : false }
                {this.state.selectedAction === 'סטטוס מסמכים' ? <WsignStatus activeId={this.state.activeId} clientId={clientId} workerId={workerId} /> : false }
                
            </div>
        )
    }
}
