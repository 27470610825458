import React, { Component } from 'react'
import { Button, Dropdown } from 'react-bootstrap';
import { ConstantsNames } from "./../../../Urls";

import ModalSendMail from "./ModalSendMail";

export default class BtnDropdownDownload extends Component {

    constructor(props) {
        super(props)
    
        this.state = {
            modalShow : false,
            closeDropdown : false,
            closeDropdownA : false,
            typeInvoice : this.props.type,
            invoiceId : this.props.item.id
        }
    }
    

    modalShow = (value) => {
        this.setState({ modalShow : value })
    }

    render() {


        let item = this.props.item;
        let pdfDownload;
        let pdfDownloadReceipt;

        var md5 = require('md5');

        if(item.pdfTranzilaFile) {
            pdfDownload = ConstantsNames.base_url + '/pays/downloadInvoices?file=' + md5(item.pdfTranzilaFile);
        }

        if(item.pdfTranzilaFileReceipt) {
            pdfDownloadReceipt = ConstantsNames.base_url + '/pays/downloadInvoices?file=' + md5(item.pdfTranzilaFileReceipt) + '&receipt=1';
        }

        if(this.props.type === 'pdfInvoice') {
            return (
                <React.Fragment>

                    <Dropdown className='DropdownButtonsDownload' show={this.state.closeDropdown} >
                        {/* id="dropdown-basic" */}
                        <Dropdown.Toggle onClick={()=>this.setState({closeDropdown : !this.state.closeDropdown, closeDropdownA : false })} variant="secondary" size="sm"  id="dropdown-autoclose-true">
                            חשבונית
                        </Dropdown.Toggle>

                        <Dropdown.Menu >
                            <Button onClick={()=>this.setState({closeDropdown : !this.state.closeDropdown})} href={ pdfDownload + '&view=1' } className="" target="_blank" rel="noopener noreferrer" size="sm" variant="light" >צפייה בחשבונית</Button>
                            <Button onClick={()=>this.setState({closeDropdown : !this.state.closeDropdown})} href={  pdfDownload } className="" target="_blank" rel="noopener noreferrer" size="sm" variant="light" >הורדת חשבונית</Button>
                            <Button onClick={()=>this.setState({closeDropdown : !this.state.closeDropdown},function (){this.modalShow(true)})} size="sm" variant="light" >שליחת חשבונית במייל</Button>
                        </Dropdown.Menu>
                    </Dropdown>

                    <ModalSendMail currentState={this.state} modalShow={(value)=> this.modalShow(value)}/>

                </React.Fragment>
            )
        }

        else if(this.props.type === 'pdfReceipt') {
            return (
                <React.Fragment>

                    <Dropdown className='DropdownButtonsDownload'  show={this.state.closeDropdownA}>
                        <Dropdown.Toggle onClick={()=>this.setState({closeDropdownA : !this.state.closeDropdownA, closeDropdown : false })} variant="secondary" size="sm" id="dropdown-autoclose-true">
                            קבלה
                        </Dropdown.Toggle>

                        <Dropdown.Menu >
                            <Button onClick={()=>this.setState({closeDropdownA : !this.state.closeDropdownA})} href={ pdfDownloadReceipt + '&view=1' } className="" target="_blank" rel="noopener noreferrer" size="sm" variant="light" >צפייה בקבלה</Button>
                            <Button onClick={()=>this.setState({closeDropdownA : !this.state.closeDropdownA})} href={ pdfDownloadReceipt } className="" target="_blank" rel="noopener noreferrer" size="sm" variant="light" >הורדת קבלה</Button>
                            <Button onClick={()=>this.setState({closeDropdownA : !this.state.closeDropdownA},function (){this.modalShow(true)})} className="" size="sm" variant="light" >שליחת קבלה במייל</Button>
                        </Dropdown.Menu>
                    </Dropdown>


                    <ModalSendMail currentState={this.state} modalShow={(value)=> this.modalShow(value)}/>

                </React.Fragment>
            )
        }


        else {
            return false;
        }
        
    }
}
