import React, { Component } from 'react'
import { RestUrls } from "./../../../Urls"

import { Button } from 'react-bootstrap';

export default class LinkClientsToCoordinator extends Component {


    constructor(props) {
        super(props)
    
        this.state = {
            dataApi : false,
            addedClients : false
        }
    }
    

    componentDidMount() {

        this.loadData();
        
    }


    async loadData (action = false, idRemove = '') {

        let url = 'linkClientsToCoordinators';

        
        if(action === 'addClients') {
            url = url + '?addClients=1';
        } else if (action === 'removeClient') {
            url = url + '?removeClient=1';
        }

        let idsToLink = this.props.rowsSelected.join();
        const API =  RestUrls.baseApiUrl + url;

        let userJ = JSON.parse(localStorage.getItem('user-info'));
        let user = userJ.data;
        
        let userPass = { 
                userId : user.id,
                userName : user.username,
                userCredential : user.userCredential,
                token : user.token,
                siteCode :  RestUrls.Code,
                coordinatorId : this.props.coordinatorId,
                idsToLink : idsToLink,
                idRemove : idRemove
            };


        const res = await fetch(API, {
            method: 'POST',
            headers: {
              "Content-Type" : 'application/json',
              "Accept" : 'application/json'
            },
            body: JSON.stringify(userPass)
          });


        const data = await res.json();

        if(data === 'unauthorized') {
            localStorage.setItem('user-info','');
            window.location.reload();
        }
  

        if(data) {
            if(action === 'addClients') {

                this.setState(
                    {
                      addedClients: 'הלקוחות הוספו בהצלחה'
                    },function() {
                        this.loadData();
                        //console.log(this.state.dataApi)
                    }
                )

            } else if( action === 'removeClient') {

                this.setState(
                    {
                      addedClients: 'הלקוח הוסר בהצלחה'
                    },function() {
                        this.loadData();
                        //console.log(this.state.dataApi)
                    }
                )

            }

            else {
                this.setState(
                    {
                      dataApi: data
                    },function() {
                        //console.log(this.state.dataApi)
                    }
                )
            }
            
        }
    }


    linkMe = () => {

        this.loadData('addClients');

    }
    

    removeClient = (id) => {
        this.loadData('removeClient',id);
    }


    render() {


        return (
            <div className="CoordinatorPopup" >

                {this.state.dataApi ? <div>

                    <header>
                        <h2 className="titStyle">
                            <span>שיוך לקוחות - </span>
                            <span>{this.state.dataApi.coordinator.name}</span>
                        </h2>
                    </header>

                    <div className="row containerModal">

                        {this.props.screen !== 'user' ? <div className="col-6">

                            <div className="selectedclients">


                            {!this.state.addedClients ? <div>
                                <h3>לקוחות חדשים לשיוך</h3>


                            {this.state.dataApi.newClients[0] ? <div><ul>
                                {this.state.dataApi.newClients.map(item => { if (true) {
                                        
                                        return <li key={item.id} >
                                                    <div><span>{item.name}</span> <span>{item.surname}</span></div>
                                                </li>

                                    } else return false}) }
                            </ul>
                            
                            <div className="buttons">
                                <Button variant='primary' onClick={this.linkMe} >אישור</Button>
                                <Button style={{margin : '0 10px 0 0'}} variant='danger' onClick={()=>this.props.closeModal()} >ביטול</Button>
                            </div>

                            </div> : <div>אין לקוחות חדשים לשיוך</div>} </div> : <div>
                                <h3>לקוחות חדשים לשיוך</h3>
                                <h4  className='animate__animated animate__bounce done'>{this.state.addedClients}</h4>
                            </div> }

                            </div>
                            
                        </div> : false }

                        <div className="col-6">

                            <h3>לקוחות קיימים</h3>

                            
                            {this.state.dataApi.currentLinkedClients[0] ? <ul>
                                {this.state.dataApi.currentLinkedClients.map(item => { if (true) {
                                        
                                        return <li key={item.id} >
                                                    <div>

                                                        <button onClick={()=>this.removeClient(item.id)}>
                                                            <i className ="far fa-minus-square"></i>
                                                        </button>
                                                        
                                                        <span>{item.name}</span> <span>{item.surname}</span>
                                                    </div>
                                                </li>

                                    } else return false}) }
                            </ul> : <div>אין נתונים</div> }
                            

                        </div>

                    </div>

                </div> : <div>טוען...</div> }
                
            </div> 
        )
    }
}