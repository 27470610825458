import React, { useState, useEffect } from 'react'
import { RestUrls } from "./Urls"
import { isMobile } from "react-device-detect"; //npm install react-device-detect //https://www.npmjs.com/package/react-device-detect
import {useHistory} from "react-router-dom";
import { Button, Alert, InputGroup, FormControl } from 'react-bootstrap';
import { ConstantsNames } from "./Urls"



function Login() {


    const [username, setUsername] = useState();
    const [password, setPassword] = useState();
    const [error, setError] = useState();

    const history = useHistory();

    useEffect(() => {

          /* && localStorage.getItem('user-info').length > 0 */
          let user = (localStorage.getItem('user-info') && localStorage.getItem('user-info').length > 0) ?  true : false;

          if(user) {
            history.push("/") /* ראשי */
          } else {
            history.push("/login")
          }
          
          // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    

    async function login() {

      let siteCode = RestUrls.Code;

      let item = {username,password,siteCode};

      //console.log(item);

      let result = await fetch(RestUrls.UserValidation, {
            method: 'POST',
            headers: {
              "Content-Type" : 'application/json',
              "Accept" : 'application/json'
            },
            body: JSON.stringify(item)
          });

          result = await result.json();
          /* console.log(result.data); */

          if(result.data) {
              localStorage.setItem('user-info',JSON.stringify(result));
              history.push("/"); /* ראשי */
              window.location.reload();
              
          } else if (result.error) {
                setError(result.error)
                history.push("/login")
                localStorage.setItem('user-info','');
          } else {
            history.push("/login")
            localStorage.setItem('user-info','');
          }
    }


    return (
        <div className={isMobile ? 'loginPage mobile' : 'loginPage desktop'}>

              <header>
                <img className="logo" src={ConstantsNames.pic2 + '/default/logoPositive.png'} alt=""/>
              </header>

              <div className="container container-fluid animate__animated animate__fadeInDown">
              

                <div className="row justify-content-center">
                  <div className="col-lg-4 text-center">
                      <h1 className="text-align-center">פרטי התחברות</h1>

                          <InputGroup className="mb-3">
                              <InputGroup.Prepend>
                                  <InputGroup.Text id="basic-addon1"><i className="fas fa-user boostrapFaIcons"></i></InputGroup.Text>
                              </InputGroup.Prepend>
                              <FormControl
                              className='boostrapInvertcornersLeft' //Sebas
                              placeholder="שם משתמש"
                              aria-label="שם משתמש"
                              onChange={(e)=>setUsername(e.target.value)}
                              aria-describedby=""
                              />
                          </InputGroup>  

                          <InputGroup className="mb-3">
                              <InputGroup.Prepend>
                                  <InputGroup.Text id="basic-addon1"><i className="fas fa-key boostrapFaIcons"></i></InputGroup.Text>
                              </InputGroup.Prepend>
                              <FormControl
                              type="password"
                              className='boostrapInvertcornersLeft' //Sebas
                              placeholder="סיסמה"
                              aria-label="סיסמה"
                              onChange={(e)=>setPassword(e.target.value)}
                              aria-describedby=""
                              />
                          </InputGroup> 
                          
                          {error ? 

                          <Alert variant="danger" onClose={() => setError(false)} >
                            <p style={{fontSize: '16px', margin: '0'}}>{error}</p>
                          </Alert> : false }

                          <Button variant={error ? 'danger' : 'success' } size="lg" onClick={login} >התחברות</Button>
                          
                  </div>
                  
                </div>
              </div>

            <div className="credit">
                <a href="https://www.waveproject.co.il/" target="_blank" rel="noopener noreferrer">
                    <img src="https://leads.wdev.co.il/commonAssetsWave/credit/wp-b-hd.png" alt="WaveProject" />
                </a>
            </div>
          
        </div>
    )
}
export default Login;