import React, { Component } from 'react'
import { InputGroup, FormControl, ProgressBar } from 'react-bootstrap';

import { FormValidator } from "../../../forms/FormValidator";
import { ValidationMessage } from "../../../forms/ValidationMessage";
import { ValidateForm } from "../../../forms/wholeFormValidation";
import { RestUrls , ConstantsNames } from "../../../Urls";

import getDataFromApi from "./../../../DinamicForm/getDataFromApi";

import axios from 'axios';


export default class UploadSignPics extends Component {

    constructor(props) {

        super(props);

        this.state = {
            allSigns : [],
            show : true,
            sentForm : false, // show / hide form
            inputFile: '',
            fileCategory: '',
            uploadError: '',
            name : '',   //File Upload
            uploadProgress : null, //File Upload
            selectedFile: null,  //File Upload
        };

        this.rules = {
            inputFile: { required: true },
            fileCategory: { required: true }
        }
        
    }

    //File Upload
    fileSelectedHandler = event => {
        if(event.target.files[0] && event.target.files[0].name && event.target.files[0] ) {
            this.setState({
                selectedFile : event.target.files[0],
                inputFile : event.target.files[0].name
            })
        }
        
    }
    

    //File Upload
    fileUploadHandler = () => {

        const fd = new FormData();
        fd.append('siteCode',  RestUrls.Code);

        fd.append('category',  this.state.fileCategory);
        fd.append('filename', this.state.selectedFile, this.state.selectedFile.name);

        console.log(fd);

        axios.post( RestUrls.baseApiUrl1 + 'ReportsExcel/uploadFiles', fd, {
            onUploadProgress: progressEvent => {
                this.setState({ uploadProgress : Math.round(progressEvent.loaded / progressEvent.total * 100 ) });
                //console.log('Upload Progress: ' + Math.round(progressEvent.loaded / progressEvent.total * 100 ) + '%')
            }
        })
            .then( res => {

                //console.log(res);
                //console.log(res.data);


                if(res.data === 'unauthorized') {
                    localStorage.setItem('user-info','');
                    window.location.reload();

                } else {

                    this.setState({
                        sentForm : true, //SENT,
                        uploadError : res.data.uploadError ? res.data.uploadError : '',
                        inputFile: '',
                        fileCategory: '',
                        uploadProgress : null, //File Upload
                        selectedFile: null,  //File Uploadת
                    });

                    this.loadData();
                }
            });
    }

    handleMoreFiles = () => {
        this.setState({
            sentForm : false, //SENT,
            uploadError : '',
            inputFile: '',
            uploadProgress : null,
            name : '',   //File Upload
            selectedFile: null,  //File Uploadת
        });
    }
    

    editHandlerSelect = (event) => {
        this.setState({ clientId : '',   workerId :'', [event.target.name] : event.target.value}, function () {
            this.loadData();
        });
        
        //console.log(event.target.value);
        //this.uptateInputField (event.target.name, event.target.value);
    }


    // FOR FILE CATEGORIES
    editHandlerSelectDefault = (event) => {
        this.setState({[event.target.name] : event.target.value}, function () {
            //console.log(this.state);
        });
    }

    
    loadData = () => {
        //console.log('loadData');
        this.sendtoAdmin('getSigns','ReportsExcel',{},'allSigns');
    }


    sendtoAdmin = (url,controller,objectToSend = {}, stateNameResponse = false) => {

        let setStateName;
    
        if(stateNameResponse) {
            setStateName = stateNameResponse;
        } else {
            setStateName = 'data';
        };
    
        let getData = getDataFromApi(url,objectToSend,controller);
        getData.then(function(response) {return response;}).then(getData =>this.setState({ [setStateName] : getData,  loading : false }, function(){
    
            /* console.log(getData); */
            /* if(url === 'reportBuilder') {
                
            } */
    
        }));
    }

    componentDidMount() {
        this.loadData();
    }
    
    
    

    render() {

        let start = ConstantsNames.files + 'signs4Excell/';

        let bg1 = this.state.allSigns.signs && this.state.allSigns.signs.director ? "url('" + start + this.state.allSigns.signs.director + "')" : '';
        let bg2 = this.state.allSigns.signs && this.state.allSigns.signs.socialWorker ? "url('" + start + this.state.allSigns.signs.socialWorker + "')" : '';

        return (
            <div className="AllFilesPage animate__animated animate__fadeIn UploadSignPics">

                <section className="FilesWrapper animate__animated animate__fadeIn">

                        {this.state.allSigns.signs && this.state.allSigns.signs ? <div className="signs">

                            <h3>חתימות קיימות: </h3>

                            <ul className="allSigns">

                                {this.state.allSigns.signs.director ? <li>
                                    <figure style={{backgroundImage : bg1 }} />
                                    <p>מנכ“ל הלשכה</p>
                                </li> : false }

                                {this.state.allSigns.signs.socialWorker ? <li>
                                    <figure style={{backgroundImage : bg2 }} />
                                    <p>עובד סוציאלי אחראי</p>
                                </li> : false }
                                
                            </ul>

                        </div> : false }

                        { this.state.show ?
                            <div>
                            <div className="filesWorker" style={{marginTop : '40px'}}>
                                <div className="formFileSection">
                                {/* !this.state.sentForm */}
                                { !this.state.sentForm || this.state.uploadError ? <div>
                                    
                                    <h3>העלאת קובץ חדש:</h3>
                                
                                <p className="fileTypesText">קבצים מסוג: jpg / png עד 1 מגה.</p>
            
                                    <FormValidator data={ this.state } rules={ this.rules } btnText='שלחו' buttonSendAction={this.fileUploadHandler} validateForm={ ValidateForm }>
                                    
                                    <ValidationMessage field="form" />
            
                                    <div className="row">

                                        <div className="col-8 informDiv">
                                            <InputGroup >
                                                <InputGroup.Prepend>
                                                    <InputGroup.Text id="basic-addon1"><i  style={{fontSize : '14px'}} className="fas fa-search boostrapFaIcons"></i></InputGroup.Text>
                                                </InputGroup.Prepend>
                                                <FormControl
                                                name="fileCategory"
                                                as="select"
                                                value={this.state.files_category}
                                                className='boostrapInvertcornersLeft' //Sebas
                                                onChange={this.editHandlerSelectDefault}
                                                >
                                                    <option>בחר קטגוריה...</option>
                                                    <option value = "מנכ“ל הלשכה" >מנכ“ל הלשכה</ option >
                                                    <option value = 'עובד סוציאלי אחראי' >עובד סוציאלי אחראי</ option >
                                                    

                                                </FormControl>
                                            </InputGroup>

                                            <input
                                                style={{display: 'none'}}
                                                type="file"
                                                onChange={this.fileSelectedHandler}
                                                ref={fileInput => this.fileInput = fileInput} //Para que no se Vea el Input y usar un boton.
                                                />

                                            <p className='validatorInputError'><ValidationMessage field="fileCategory" /></p>
                                        </div>
            
                                        <div className="col-4 informDiv">
                                            <input
                                                style={{display: 'none'}}
                                                type="file"
                                                name='inputFile' //For Validation
                                                onChange={this.fileSelectedHandler}
                                                ref={fileInput => this.fileInput = fileInput} //Para que no se Vea el Input y usar un boton.
                                                />
                                            <button className='btn btn-primary filePickUpBtn' style={{width : '100%', margin: '0px 0 0px 0' }} onClick={() => this.fileInput.click()} >בחר קובץ</button>
                                            <p className='validatorInputError'><ValidationMessage field="inputFile" /></p>
                                        </div>
                                    </div>
                                    </FormValidator></div> : <div>
                                                                <p className="okUpload animate__animated animate__bounce">נשלח בהצלחה.</p>
                                                                <button className="btn btn-primary" onClick={this.handleMoreFiles}>להעלות קובץ נוסף &raquo;</button>
                                                            </div>}
            
                                    { this.state.inputFile ? this.state.inputFile : false }
                                    {this.state.uploadError ? <p className="errorUpload"> {this.state.uploadError} </p>: ''}
                                    
                                    { this.state.uploadProgress ? <ProgressBar now={this.state.uploadProgress} /> : false }
            
                                </div>
                            </div>
                            
                        </div> : false }

                </section>


            </div>
        )
    }
}
