
import { RestUrls } from "./../Urls"

export async function sendToAdmin(urlAction) {

    //console.log('IN');

    //console.log(urlAction);
    

    let userJ = JSON.parse(localStorage.getItem('user-info'));
    let user = userJ.data;


    let item = {
      userId : user.id,
      userName : user.username,
      userCredential : user.userCredential,
      token : user.token,
      siteCode :  RestUrls.Code
    };
  
    let fletchUrl = RestUrls.baseApiUrl + urlAction;

    //console.log('fletchUrl: ' + fletchUrl);

    let result = await fetch(fletchUrl, {
          method: 'POST',
          headers: {
            "Content-Type" : 'application/json',
            "Accept" : 'application/json'
          },
          body: JSON.stringify(item)
        });
  
        let data = await result.json();
        //console.log(data);
  
        if(data === 'unauthorized') {
            localStorage.setItem('user-info','');
            window.location.reload();
        } else {

          //console.log('okInsert');

        }
  }
