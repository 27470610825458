import React, { Component } from 'react'
import { RestUrls } from "../../../../Urls";

import { Table } from 'react-bootstrap';
import TableTd from './TableTd';
import { checkClassName } from './CheckClassName';


export default class TasksIndex extends Component {


    constructor(props) {

        super(props);
        this.state = {            
            dataPage : [],
            sortField: 'doneTask'
        }

    }

    async componentDidMount() {

        let API =  RestUrls.baseApiUrl + 'firstTasks';

        if(this.props.noLimit) {
            API = API + '?nolimit=1';
        }

        let user = this.props.user;
        //console.log(user);

        let userPass = { 
                userId : user.id,
                userName : user.username,
                userCredential : user.userCredential,
                token : user.token,
                siteCode :  RestUrls.Code
            };

        const res = await fetch(API, {
            method: 'POST',
            headers: {
              "Content-Type" : 'application/json',
              "Accept" : 'application/json'
            },
            body: JSON.stringify(userPass)
          });


        const data = await res.json();

        if(data === 'unauthorized') {
            localStorage.setItem('user-info','');
            window.location.reload();
        }
  
        //console.log(data);
  
        this.setState(
            {
              dataPage: data,
              isReady: true
            }
        )

       
    }




    async loadData (action) {

        let API =  RestUrls.baseApiUrl + 'firstTasks?orderBy=' + action;

        if(this.props.noLimit) {
            API = API + '&nolimit=1';
        }

        let user = this.props.user;
        //console.log(user);

        let userPass = { 
                userId : user.id,
                userName : user.username,
                userCredential : user.userCredential,
                token : user.token,
                siteCode :  RestUrls.Code
            };

        const res = await fetch(API, {
            method: 'POST',
            headers: {
              "Content-Type" : 'application/json',
              "Accept" : 'application/json'
            },
            body: JSON.stringify(userPass)
          });


        const data = await res.json();
        

        if(data === 'unauthorized') {
            localStorage.setItem('user-info','');
            window.location.reload();
        }
  
        //console.log(data);
  
        this.setState(
            {
              dataPage: data,
              isReady: true
            }
        )
        
    }

    
    dataSortHandler = (action) => {

        //console.log( this.state.dataPage);
        
        if(this.state.sortField === action) {

            let reverseArray = this.state.dataPage.data.reverse();

            let tasksStatus = this.state.dataPage.tasksStatus;

            this.setState(
                {
                    dataPage:  {data : reverseArray, tasksStatus : tasksStatus }
                }
            );

        } else {
            this.loadData(action);
            this.setState({sortField: action});
        }

    }
    

    render() {

        let sortField = this.state.sortField;

        return (
            <div>
                {this.state.savedMessage ? <div className="savedWindow animate__animated animate__fadeIn animate__slow">השינוים נשמרו בהצלחה</div> : false }
                <h2 className="titStyle ">משימות</h2>
                {/* striped */}
                {this.props.media !== 'mobile' ? <Table bordered hover>
                    <thead>
                        <tr>
                            <th>העלאת משימה <button className={sortField === 'upTask' ? 'active' : ''} onClick={()=>this.dataSortHandler('upTask')}>
                                <i className="fas fa-chevron-down boostrapFaIcons"></i></button>
                            </th>

                            <th>תאריך לטיפול  <button className={sortField === 'doneTask' ? 'active' : ''} onClick={()=>this.dataSortHandler('doneTask')}>
                                <i className="fas fa-chevron-down boostrapFaIcons"></i></button>
                            </th>

                            <th>אוטומטי/ידני <button className={sortField === 'typeTask' ? 'active' : ''} onClick={()=>this.dataSortHandler('typeTask')}>
                                <i className="fas fa-chevron-down boostrapFaIcons"></i></button>
                            </th>

                            <th>שם עובד <button className={sortField === 'userId' ? 'active' : ''} onClick={()=>this.dataSortHandler('workerId')}>
                                <i className="fas fa-chevron-down boostrapFaIcons"></i></button>
                            </th>

                            <th>שם קשיש <button className={sortField === 'seniorId' ? 'active' : ''} onClick={()=>this.dataSortHandler('seniorId')}>
                                <i className="fas fa-chevron-down boostrapFaIcons"></i></button>
                            </th>

                            <th>הערות <button className={sortField === 'comments' ? 'active' : ''} onClick={()=>this.dataSortHandler('comments')}>
                                <i className="fas fa-chevron-down boostrapFaIcons"></i></button>
                            </th>

                            <th>סטטוס <button className={sortField === 'taskStatus' ? 'active' : ''} onClick={()=>this.dataSortHandler('taskStatus')}>
                                <i className="fas fa-chevron-down boostrapFaIcons"></i></button>
                            </th>
                            <th></th>
                        </tr>
                    </thead>

                    <tbody>
                        {this.state.dataPage.data && this.state.sortField ? <React.Fragment >
                            {this.state.dataPage.data.map(item => { if (true) {
                                /* currentState={this.state} */
                                return <tr className={checkClassName(item.taskStatusName)}  key={item.id}><TableTd  updateRecords={()=>this.loadData(this.state.sortField)} user={this.props.user} tasksStatus={this.state.dataPage.tasksStatus} item={item} className='item' /></tr>
                            } else return false}) }
                        </React.Fragment> : false}
                    </tbody>
                </Table> : 
                
                <div className="MobileView">
                    
                    {this.state.dataPage.data && this.state.sortField ? <div>
                        
                        {this.state.dataPage.data.map(item => { if (true) {
                                /* currentState={this.state} */
                                return <div className={'carouselItem ' +  checkClassName(item.taskStatusName)}  key={item.id}><TableTd sortField={sortField}  dataSortHandler={this.dataSortHandler} format="div" updateRecords={()=>this.loadData(this.state.sortField)} user={this.props.user} tasksStatus={this.state.dataPage.tasksStatus} item={item} className='item' /></div>
                            } else return false}) }

                    </div> : false }

                </div> }
            </div>
        )
    }
}
