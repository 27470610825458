import React, { Component } from 'react'
import { RestUrls } from "../../../../Urls";
import { checkClassName } from './CheckClassName';
import { Button } from 'react-bootstrap';

export default class TableTd extends Component {


    constructor(props) {

        super(props);
        this.state = {
            comments : this.props.item.comments
        };
        
        //this.handleChange = this.handleChange.bind(this);
        
    }


    async uptateTask (id,statusName) {

        
        const API =  RestUrls.baseApiUrl + 'firstTasks?update=1';
        let user = this.props.user;
        //console.log(user);

        let userPass = { 
            userId : user.id,
            userName : user.username,
            userCredential : user.userCredential,
            token : user.token,
            siteCode :  RestUrls.Code,
            updateStatusId : id,
            updateStatusName : statusName
        };

        const res = await fetch(API, {
            method: 'POST',
            headers: {
              "Content-Type" : 'application/json',
              "Accept" : 'application/json'
            },
            body: JSON.stringify(userPass)
          });


        const data = await res.json();

        if(data === 'unauthorized') {
            localStorage.setItem('user-info','');
            window.location.reload();
        }
        
        this.props.updateRecords();
  
    }



    async uptateTaskComments (id,newComment) {

        
        const API =  RestUrls.baseApiUrl + 'firstTasks?update=2';
        let user = this.props.user;
        //console.log(user);

        //console.log(id + ' | ' + newComment);

        let userPass = { 
            userId : user.id,
            userName : user.username,
            userCredential : user.userCredential,
            token : user.token,
            siteCode :  RestUrls.Code,
            updateCommentId : id,
            updateCommentText : newComment
        };

        const res = await fetch(API, {
            method: 'POST',
            headers: {
              "Content-Type" : 'application/json',
              "Accept" : 'application/json'
            },
            body: JSON.stringify(userPass)
          });


        const data = await res.json();

        //console.log(data);

        if(data === 'unauthorized') {
            localStorage.setItem('user-info','');
            window.location.reload();
        }
        
        //this.props.updateRecords();
  
    }



    async deletteTask (id) {

        
        const API =  RestUrls.baseApiUrl + 'firstTasks?delette=1';
        let user = this.props.user;
        //console.log(user);

        //console.log(id);

        let userPass = { 
            userId : user.id,
            userName : user.username,
            userCredential : user.userCredential,
            token : user.token,
            siteCode :  RestUrls.Code,
            rowId : id            
        };

        const res = await fetch(API, {
            method: 'POST',
            headers: {
              "Content-Type" : 'application/json',
              "Accept" : 'application/json'
            },
            body: JSON.stringify(userPass)
          });


        const data = await res.json();

        //console.log(data);

        if(data === 'unauthorized') {
            localStorage.setItem('user-info','');
            window.location.reload();
        } else {
            this.props.updateRecords();
        }
        
        //this.props.updateRecords();
  
    }



    //SELECT
    editHandler = (event) => {
        this.uptateTask (event.target.name, event.target.value);
    }

    // INPUT
    handleChange = (event) => {
        this.setState({ comments: event.target.value});
        this.uptateTaskComments ([event.target.name],event.target.value);
    }

    handledeletteTask = (data) => {
        this.deletteTask(data);
        //console.log(data);
    }

    render() {

        let itemData = this.props.item;

        //console.log(this.props.tasksStatus);
        if (this.props.format && this.props.format === 'div') {
            return (
            <ul>
                <li className="statusLI">
                    
                    <select className ={"form-control " + checkClassName(itemData.taskStatusName)}  
                        name ={itemData.id}
                        value ={ itemData.taskStatusName }
                        onChange ={ this.editHandler }  >
                        <option value={ itemData.taskStatusName }>סטטוס: { itemData.taskStatusName }</option>
                        
                        {this.props.tasksStatus.map(item => { if (item.data.name !== itemData.taskStatusName) {
                                /* currentState={this.state} */
                                return < option value ={item.data.name } key ={ item.data.id }> 
                                            סטטוס: { item.data.name } 
                                        </ option >
                            } else return false}) }

                    </select >
                    
                    <button className={this.props.sortField === 'taskStatus' ? 'active' : ''} onClick={()=>this.props.dataSortHandler('taskStatus')}>
                                <i className="fas fa-chevron-down boostrapFaIcons"></i></button>
                </li>

                <li>
                    <h3>שם עובד <button className={this.props.sortField === 'workerId' ? 'active' : ''} onClick={()=>this.props.dataSortHandler('workerId')}>
                                <i className="fas fa-chevron-down boostrapFaIcons"></i></button></h3>
                    <p>{itemData.workerIdName}</p>
                </li>
                <li>
                    <h3>שם קשיש <button className={this.props.sortField === 'seniorId' ? 'active' : ''} onClick={()=>this.props.dataSortHandler('seniorId')}>
                                <i className="fas fa-chevron-down boostrapFaIcons"></i></button></h3>
                    <p>{itemData.seniorIdName}</p>
                </li>
                
                <li className="commentsTd">
                    <h3>הערות <button className={this.props.sortField === 'comments' ? 'active' : ''} onClick={()=>this.props.dataSortHandler('comments')}>
                                <i className="fas fa-chevron-down boostrapFaIcons"></i></button></h3>
                    <input className="form-control" name={itemData.id} onChange={this.handleChange} value={this.state.comments} />
                </li>

                
                <li>
                    <h3>העלאת משימה <button className={this.props.sortField === 'upTask' ? 'active' : ''} onClick={()=>this.props.dataSortHandler('upTask')}>
                                <i className="fas fa-chevron-down boostrapFaIcons"></i></button></h3>
                    <p>{itemData.upTask}</p>
                </li>
                <li>
                    <h3>תאריך לטיפול <button className={this.props.sortField === 'doneTask' ? 'active' : ''} onClick={()=>this.props.dataSortHandler('doneTask')}>
                                <i className="fas fa-chevron-down boostrapFaIcons"></i></button></h3>
                    <p>{itemData.doneTask}</p>
                </li>
                <li>
                    <h3>אוטומטי/ידני <button className={this.props.sortField === 'typeTask' ? 'active' : ''} onClick={()=>this.props.dataSortHandler('typeTask')}>
                                <i className="fas fa-chevron-down boostrapFaIcons"></i></button></h3>
                    <p>{itemData.typeTaskName}</p>
                </li>
              
                
                

            </ul>
            );

        }
        else {
            return (
                <React.Fragment>
                    <td>{itemData.upTask}</td>
                    <td>{itemData.doneTask}</td>
                    <td>{itemData.typeTaskName}</td>
                    <td>{itemData.workerIdName}</td>
                    <td>{itemData.seniorIdName}</td>
                    <td className="commentsTd">
                        <input className="form-control" name={itemData.id} onChange={this.handleChange} value={this.state.comments} />
                    </td>
                    
                    <td className="selectTd">
                        <select className ={"form-control " + checkClassName(itemData.taskStatusName)}  
                            name ={itemData.id}
                            value ={ itemData.taskStatusName }
                            onChange ={ this.editHandler }  >
                            <option value={ itemData.taskStatusName }>{ itemData.taskStatusName }</option>
                            
                            {this.props.tasksStatus.map(item => { if (item.data.name !== itemData.taskStatusName) {
                                    /* currentState={this.state} */
                                    return < option value ={item.data.name } key ={ item.data.id }> 
                                                { item.data.name } 
                                            </ option >
                                } else return false}) }

                        </select >
                    </td>

                    <td style={{padding : '0'}}>
                        <Button style={{padding : '5px 10px', margin: '0 0 0 0px'}} className="addBtn deletteBtn" onClick={() => this.deletteTask(itemData.id)} variant="secondary" size="sm"><i className="far fa-trash-alt" /></Button>
                    </td>
                </React.Fragment>
            )
        }
    }
}
