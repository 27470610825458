import React, { Component } from 'react'
import { RestUrls } from "../../../Urls";
import InputFormAdmin from "../../../DinamicForm/InputFormAdmin";
import { Button, Modal } from 'react-bootstrap';

export default class clientScreenNewClient extends Component {

    constructor(props) {

        super(props);
        this.state = {            
            dataPage : [],
            isLoaded: false
        }

    }


    async insertWorkerDb() {

        let urlAction = 'foreignWorkers?insertWorker=1';

        //console.log('IN');
        let userJ = JSON.parse(localStorage.getItem('user-info'));
        let user = userJ.data;

        let item = {
        userId : user.id,
        userName : user.username,
        userCredential : user.userCredential,
        token : user.token,
        siteCode :  RestUrls.Code
        };
    
        let fletchUrl = RestUrls.baseApiUrl + urlAction;

        let result = await fetch(fletchUrl, {
            method: 'POST',
            headers: {
                "Content-Type" : 'application/json',
                "Accept" : 'application/json'
            },
            body: JSON.stringify(item)
            });
    
            let data = await result.json();
            //console.log(data);
    
            if(data === 'unauthorized') {
                localStorage.setItem('user-info','');
                window.location.reload();
            } else {
                this.editHandler(data.insertId);
            }
    }


    async componentDidMount() {

        localStorage.setItem('searchInner','');
        localStorage.setItem('currentPathSearch','');
        
        const API =  RestUrls.baseApiUrl + 'clients/?noIDNewclient=1';

        let user = this.props.user;
        //console.log(user);

        let userPass = { 
                userId : user.id,
                userName : user.username,
                userCredential : user.userCredential,
                token : user.token,
                siteCode :  RestUrls.Code
            };

        const res = await fetch(API, {
            method: 'POST',
            headers: {
              "Content-Type" : 'application/json',
              "Accept" : 'application/json'
            },
            body: JSON.stringify(userPass)
          });


        const data = await res.json();

        //console.log(data);
       
        if(data === 'unauthorized') {
            localStorage.setItem('user-info','');
            window.location.reload();
        } else {
            this.setState(
                {
                  dataPage: data,
                  isLoaded: true
                }
            )
        }
  
        //console.log(data);
  
        
    }


    editHandler = (selectedClientId) => {
        this.props.cancelId(selectedClientId);
    }

    //GET INPUT VALUES FROM ADMINiNPUTS
    submit = (state) => {
        this.setState(state);
    }

    modalErrors = (value) => {
        this.setState({ modalShow : value })
    }

    insertClient = () => {

        if(
            this.state.surname && this.state.name
            && this.state.tz && this.state.phone) {
                //console.log('ok');
                this.insertClientToDb();
            }

        else {
            //console.log(this.state);
            this.modalErrors(true);
        }
        
    }


    async insertClientToDb() {

        let urlAction = 'clients?insertClient=1';

        //console.log('IN');
        let userJ = JSON.parse(localStorage.getItem('user-info'));
        let user = userJ.data;

        let item = {
        userId : user.id,
        userName : user.username,
        userCredential : user.userCredential,
        token : user.token,
        siteCode :  RestUrls.Code
        };

        let items = {...item, ...this.state };
        //console.log(items);
    
        let fletchUrl = RestUrls.baseApiUrl + urlAction;

        let result = await fetch(fletchUrl, {
            method: 'POST',
            headers: {
                "Content-Type" : 'application/json',
                "Accept" : 'application/json'
            },
            body: JSON.stringify(items)
            });
    
            let data = await result.json();
            //console.log(data);
    
            if(data === 'unauthorized') {
                localStorage.setItem('user-info','');
                window.location.reload();
            } else {
                //deleteMe(data.insertId,urlDelleteTwice);
                if(data.insertId) {
                    this.editHandler(data.insertId);
                } else {
                    alert('error');
                }
                

            }
    }

    

    render() {
        if (!this.state.isLoaded) {
            return <div>טוען...</div>
        }

        else {



            return (
                <div className="clientScreen">

                    <button className='goBackCustomBtn bounce' onClick ={()=>this.editHandler(false)} >&laquo; חזור</button> <h2 className="titStyle ">הוסף לקוח חדש </h2>
                    
                    <div className="row formContainerAdmin">
                        {this.state.dataPage.form.map(item => { if (true) {
                            /* currentState={this.state} */
                            return <InputFormAdmin
                                        platform={this.props.platform}
                                        Prepend={true}
                                        user={this.props.user}
                                        methodName='clients'
                                        updateGetAction='?update=1'
                                        data={item}
                                        rowsOption4Select = {this.state.dataPage.rowsOption4Select}
                                        dataParam={false}
                                        submit={this.submit }
                                        key={item.name} />
                        } else return false}) }
                    </div>
                    <Button className="addBtn" onClick={this.insertClient} variant="secondary" size="sm"><i className="fas fa-download" />שמור ולהמשיך</Button>
                    
                    {/* https://react-bootstrap.netlify.app/components/modal/ */}
                    <Modal
                        className='animate__animated animate__fadeInDown animate__faster modalErrors'
                        animation={false}
                        onHide={() => this.modalErrors(false)}
                        /* size="sm" // בגודל קטן!!! */
                        /* size="lg" // גדול!! */
                        // backdrop="static" // חייב ללחוץ על כפתור!
                        /* centered // VERTICA CENTER */
                        show={this.state.modalShow}
                        dialogClassName="modal-90w"
                        aria-labelledby="example-custom-modal-styling-title"
                    >
                        <Modal.Header>
                            <Modal.Title id="example-custom-modal-styling-title">
                                <span>שגיאה. נא למלא את השדות חובה</span>
                            </Modal.Title>
                            <Button onClick={() => this.modalErrors(false)} variant="secondary" >X</Button>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="errorCheck">
                                {!this.state.surname ? <p>שם משפחה</p> : false }
                                {!this.state.name ? <p>שם פרטי</p> : false }
                                {!this.state.tz ? <p>תעודת זהות</p> : false }
                                {!this.state.phone ? <p>טלפון</p> : false }
                            </div>
                        </Modal.Body>
                    </Modal>

                </div>
            )
        }
    }
}
