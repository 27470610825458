import React, { Component } from 'react'
import getDataFromApi from "./../../../DinamicForm/getDataFromApi";
import convertDate from './../../../convertDateToDisplay'
import ReportChangeMana from './ReportChangeMana'
import { Button } from 'react-bootstrap';
import { ConstantsNames } from "./../../../Urls";

export default class ReportHistory extends Component {

    constructor(props) {
        super(props)
    
        this.state = {
            reportsHistory : false,
            savedMessage : false
        }
    }

    sendtoAdmin = (url,controller,objectToSend = {}, stateNameResponse = false) => {

        let setStateName;

        if(stateNameResponse) {
            setStateName = stateNameResponse;
        } else {
            setStateName = 'data';
        };

        let getData = getDataFromApi(url,objectToSend,controller);
        getData.then(function(response) {return response;}).then(getData =>this.setState({ [setStateName] : getData,  loading : false }, function(){

            //console.log(getData);
            /* if(url === 'reportBuilder') {
                
            } */

        }));
    }


    loadData = () => {
        this.sendtoAdmin('getReportHistory','Reports',{ seniorId : this.props.clientId },'reportsHistory');
    }
    

    componentDidMount() {
        
        if(!this.state.reportsHistory) {
            this.loadData();
        }

    }
    
    componentDidUpdate() {

        if(this.props.reloadHistory) {
            this.loadData();
            this.props.changed();
        }

    }

    onChangeAdmin = () => {
        //console.log('changed');
        
        setTimeout(() => this.setState({savedMessage: true}), 50);
        setTimeout(() => this.setState({savedMessage:false}), 6000);
    }


    render() {
        return (
            <div>

                {this.state.savedMessage ? <div className="savedWindow animate__animated animate__bounceIn animate__slow">השינוים נשמרו בהצלחה</div> : false }

                {this.state.reportsHistory && this.state.reportsHistory.reportHistory[0] ? <div className="tableDefault">

                

                    <div className="trTable titlesTr">
                        <div className="tdTable">מס' דרכון</div>
                        <div className="tdTable">שם מלא</div>
                        <div className="tdTable">תיאור דיווח</div>
                        <div className="tdTable">תאריך דיווח</div>
                        <div className="tdTable">סטטוס</div>
                        <div className="tdTable">קובץ</div>
                        <div className="tdTable">מספר מנה</div>
                    </div>
                    <div className="trTable contentTds spaceTr" >
                        <div className="vSpace"></div>
                    </div>

                    {this.state.reportsHistory.reportHistory.map(item => { if (true) {

                            var md5 = require('md5');
                            let txtDownload = ConstantsNames.base_url + '/Reports/downloadTXT?file=' + md5(item.fileMatash);

                            return <div className="trTable contentTds" key={item.id}>
                                    <div><span>{item.workerPassport}</span></div>
                                    <div><span>{item.workerName}</span></div>
                                    <div><span>{item.subjectReport}</span></div>
                                    <div><span>{convertDate(item.dateReport)}</span></div>
                                    {/* + ' - ' + item.report.length */}
                                    <div>
                                        
                                        <ReportChangeMana display='select' disabled = { item.manaCode ? true : false } savedMessage = {()=>this.onChangeAdmin()} item={item} />
                                        
                                        {/* item.statusCode */}
                                        
                                        </div>
                                    <div>
                                        <span>
                                            <Button style={{padding : '0', fontSize : '20px'}} variant='' size="sm" href={txtDownload} target="_blank" rel="noopener noreferrer" ><i className="fas fa-file-download"></i></Button>
                                        </span></div>
                                    <div>

                                        <ReportChangeMana display='input' disabled = { item.manaCode ? true : false } savedMessage = {()=>this.onChangeAdmin()} item={item} />
                                    
                                    </div>
                                </div>
                        } else return false}) }
                    
                </div> : false }
            </div>
        )
    }
}
