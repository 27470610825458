import React, { Component } from 'react'

import { FormValidator } from "../../../forms/FormValidator";
import { ValidationMessage } from "../../../forms/ValidationMessage";
import { ValidateForm } from "../../../forms/wholeFormValidation";

import { InputGroup, FormControl,ProgressBar } from 'react-bootstrap';
import { RestUrls,ConstantsNames } from "../../../Urls";
import axios from 'axios';

export default class FilesWorker extends Component {


    constructor(props) {

        super(props);

        this.state = {    
            sentForm : false, // show / hide form
            inputFile: '',
            uploadError: '',
            name : '',   //File Upload
            uploadProgress : null, //File Upload
            selectedFile: null,  //File Upload
            allFilesWorker: this.props.allFilesWorker
        }

        this.rules = {
            name: { required: true, minlength: 3 },
            inputFile: { required: true },
        }

    }

    //Input Text 
    updateFormValue = (event) => {
        this.setState({ [event.target.name]: event.target.value });
        //console.log(event.target.value);
    }

    //File Upload
    fileSelectedHandler = event => {
        if(event.target.files[0] && event.target.files[0].name && event.target.files[0] ) {
            this.setState({
                selectedFile : event.target.files[0],
                inputFile : event.target.files[0].name
            })
        }
        
    }
    

    //File Upload
    fileUploadHandler = () => {

        // let userJ = JSON.parse(localStorage.getItem('user-info'));
        // let user = userJ.data;

        const fd = new FormData();

        // fd.append('userId',  user.id);
        // fd.append('userCredential',  user.userCredential);
        // fd.append('token',  user.token);
        fd.append('siteCode',  RestUrls.Code);

        fd.append('workerId',  this.props.workerId);
        fd.append('seniorId',  this.props.seniorId);

        fd.append('name',  this.state.name);
        fd.append('filename', this.state.selectedFile, this.state.selectedFile.name);

        console.log(fd);

        axios.post( RestUrls.filesMethod, fd, {
            onUploadProgress: progressEvent => {
                this.setState({ uploadProgress : Math.round(progressEvent.loaded / progressEvent.total * 100 ) });
                //console.log('Upload Progress: ' + Math.round(progressEvent.loaded / progressEvent.total * 100 ) + '%')
            }
        })
            .then( res => {

                //console.log(res);
                //console.log(res.data);


                if(res.data === 'unauthorized') {
                    localStorage.setItem('user-info','');
                    window.location.reload();

                } else {

                    this.setState({
                        sentForm : true, //SENT,
                        uploadError : res.data.uploadError ? res.data.uploadError : '',
                        inputFile: '',
                        name : '',   //File Upload
                        uploadProgress : null, //File Upload
                        selectedFile: null,  //File Uploadת
                        allFilesWorker: []
                    });

                    this.loadData(this.props);
                }
            });
    }

    handleMoreFiles = () => {
        this.setState({
            sentForm : false, //SENT,
            uploadError : '',
            inputFile: '',
            uploadProgress : null,
            name : '',   //File Upload
            selectedFile: null,  //File Uploadת
        });
    }
    
    //new files
    async loadData (props) {

        const API =  RestUrls.baseApiUrl + 'uploadFiles?getFiles=1';
        let user = props.user;
        //console.log(user);

        let userPass = { 
                userId : user.id,
                userName : user.username,
                userCredential : user.userCredential,
                token : user.token,
                siteCode :  RestUrls.Code,
                workerId : props.workerId,
                seniorId : props.seniorId,
                category : '4'
            };

        const res = await fetch(API, {
            method: 'POST',
            headers: {
              "Content-Type" : 'application/json',
              "Accept" : 'application/json'
            },
            body: JSON.stringify(userPass)
          });


        const data = await res.json();

        if(data === 'unauthorized') {
            localStorage.setItem('user-info','');
            window.location.reload();
        }
  
        //console.log(data);
        
        this.setState(
            {
              allFilesWorker: data.allFilesWorker
            }
        )
    }


    render() {

        return (
            <div className="formFileSection">

                
                {/* !this.state.sentForm */}
                { !this.state.sentForm || this.state.uploadError ? <div>
                    
                    <h3>העלאת קובץ חדש:</h3>
                <p className="fileTypesText">קבצים מסוג: jpg / pdf / png / tif עד 5 מגה.</p>

                    <FormValidator data={ this.state } rules={ this.rules } btnText='שלחו' buttonSendAction={this.fileUploadHandler} validateForm={ ValidateForm }>
                    
                    <ValidationMessage field="form" />

                    <div className="row">
                        <div className="col-lg-6 informDiv">

                            <input
                                style={{display: 'none'}}
                                type="file"
                                onChange={this.fileSelectedHandler}
                                ref={fileInput => this.fileInput = fileInput} //Para que no se Vea el Input y usar un boton.
                                />
                            <InputGroup>

                                    <InputGroup.Prepend>
                                        <InputGroup.Text id="basic-addon1"><i style={{fontSize : '14px'}} className="fas fa-file-signature boostrapFaIcons"/></InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <FormControl
                                    className='boostrapInvertcornersLeft' //Sebas
                                    placeholder="שם"
                                    name="name" // FOR VALIDATOR
                                    aria-label="שם"
                                    onChange={(e)=>this.updateFormValue(e)}
                                    aria-describedby=""
                                    />
                            </InputGroup>
                            <p className='validatorInputError'><ValidationMessage style={{margin: '0'}} field="name" /></p>
                        </div>

                        <div className="col-lg-6 informDiv">
                            <input
                                style={{display: 'none'}}
                                type="file"
                                name='inputFile' //For Validation
                                onChange={this.fileSelectedHandler}
                                ref={fileInput => this.fileInput = fileInput} //Para que no se Vea el Input y usar un boton.
                                />
                            <button className='btn btn-primary filePickUpBtn' style={{width : '100%', margin: '0px 0 0px 0' }} onClick={() => this.fileInput.click()} >בחר קובץ</button>
                            <p className='validatorInputError'><ValidationMessage field="inputFile" /></p>
                        </div>
                    </div>
                    </FormValidator></div> : <div>
                                                <p className="okUpload animate__animated animate__bounce">נשלח בהצלחה.</p>
                                                <button className="btn btn-primary" onClick={this.handleMoreFiles}>להעלות קובץ נוסף &raquo;</button>
                                            </div>}

                    { this.state.inputFile ? this.state.inputFile : false }
                    {this.state.uploadError ? <p className="errorUpload"> {this.state.uploadError} </p>: ''}
                    
                    { this.state.uploadProgress ? <ProgressBar now={this.state.uploadProgress} /> : false }

                    
                    {this.state.allFilesWorker ? <div><h3>קבצים בארכיון: </h3> <ul className='showData'>
                        {this.state.allFilesWorker.map(item => { if (true) {
                            return <li key={item.data.id} >
                                <a className="fileA" href={ConstantsNames.files + item.data.fileName} rel="noreferrer" target='_blank' >
                                    <i className="far fa-file"></i>
                                    <span>{item.data.documentName}</span>
                                </a>
                            </li>
                        } else return false}) }
                    </ul></div> : <h4>ללא קבצים בארכיון</h4> }
            </div>
        )
    }
}
